/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  IconButton,
  Typography,
  colors,
  Paper,
  useTheme,
  Dialog,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Ip } from '../../../data/ip';
import ChatRoom from '../../ChatRoom/ChatRoom';
import { DataGridStyles } from '../../../data/DataGridStyles';

export default function DirectRequestsTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [accept, setAccept] = useState(true);
  const [acceptId, setAcceptId] = useState([]);
  const [consumerId, setConsumerId] = useState();
  const [consumerName, setConsumerName] = useState();
  const [chat, setChat] = useState(true);
  const [openChatWindow, setOpenChatWindow] = useState(false);

  function status() {
    props.load();
    const body = {
      servicerequestofferid: acceptId[1],
      status: 'Accepted',
      servicerequest: {
        servicerequestid: acceptId[0],
      },
    };
    console.log(JSON.stringify(body, null, 2));
    fetch(`${Ip}/servicerequestoffer`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          props.refresh();
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  function CustomToolbar() {
    return (
      <Grid
        container
        justify="flex-end"
        alignContent="center"
        style={{
          height: '50px',
          borderBottom: 'solid 2px #cfd9e2',
        }}
      >
        <Box mr={1}>
          <Button
            style={{ textTransform: 'none' }}
            disabled={chat}
            // href={`/chatroom/${providerId}`}
            onClick={() => {
              console.log(consumerId);
              setOpenChatWindow(true);
              localStorage.setItem('chatWith', consumerId + '');
              localStorage.setItem('chatWithName', consumerName);
            }}
          >
            <Typography>Chat with Consumer</Typography>
          </Button>
          <Button
            disabled={accept}
            style={{ textTransform: 'none' }}
            onClick={status}
          >
            <Typography>Accept</Typography>
          </Button>
        </Box>
      </Grid>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      hide: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 500,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 500,
      sortable: false,
    },
    {
      field: 'servicetype',
      headerName: 'Service type',
      width: 130,
      sortable: false,
    },

    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      sortable: false,
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: [
            con.servicerequestid,
            con.servicerequestofferid,
            con.servicerequeststatus,
            con.createdbyuserid,
            `${con.createdbyfname} ${
              con.createdbymname != '' ? con.createdbymname + ' ' : ''
            }${con.createdbylname}`,
          ],
          title: con.servicerequesttitle,
          description: con.servicerequestdescription,
          servicetype: con.servicename,
          status: con.servicerequeststatus,
        },
      ]);
    });
  }, []);

  return (
    <Grid
      container
      style={{ height: props.content.length * 52 + 116, width: '100%' }}
      className={classes.root}
    >
      <DataGrid
        autoHeight
        components={{
          header: CustomToolbar,
        }}
        showToolbar
        rows={rows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        hideFooter
        onSelectionChange={(param) => {
          var req =
            param.rowIds.length > 0 &&
            param.rowIds[0].split(',').map((step) => step);
          console.log(param);
          param.rowIds.length === 1
            ? (setChat(false), setConsumerId(req[3]), setConsumerName(req[4]))
            : setChat(true);
          param.rowIds.length === 1 && req[2] !== 'BidAccepted'
            ? (setAccept(false), setAcceptId([req[0], req[1]]))
            : setAccept(true);
        }}
      />
      {openChatWindow && (
        <ChatRoom onClose={() => setOpenChatWindow(!openChatWindow)} />
      )}
    </Grid>
  );
}
