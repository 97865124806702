/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import DoneIcon from '@material-ui/icons/Done';
import { DataGridStyles } from '../../../data/DataGridStyles';

export default function OfferedBidsTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [accept, setAccept] = useState(true);

  function CustomToolbar() {
    return (
      <Grid
        container
        justify="space-between"
        alignContent="center"
        style={{
          height: '50px',
          borderBottom: 'solid 2px #cfd9e2',
        }}
      >
        {props.filter !== 'null' ? (
          <Box mt={1} ml={1}>
            <Typography>
              <span style={{ fontWeight: 'bold' }}>Filtered by</span>{' '}
              {props.title}
            </Typography>
          </Box>
        ) : (
          <Typography />
        )}
        <Button
          style={{ textTransform: 'none' }}
          onClick={props.clicked}
          disabled={accept}
        >
          <Typography>Accept</Typography>
          <DoneIcon />
        </Button>
      </Grid>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      sortDirection: 'desc',
      hide: true,
    },

    {
      field: 'title',
      headerName: 'Title',
      width: 285,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 285,
      sortable: false,
    },
    {
      field: 'bidType',
      headerName: 'Bid Type',
      width: 130,
      sortable: false,
    },
    {
      field: 'bidPrice',
      headerName: 'Bid Price',
      width: 130,
    },
    {
      field: 'serviceProvider',
      headerName: 'Service Provider',
      width: 180,
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 130,
    },
    {
      field: 'status',
      headerName: 'Bid Status',
      width: 130,
      sortable: false,
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: [
            con.servicerequestofferid,
            con.servicerequestid,
            con.offerstatus,
          ],
          title: con.servicerequesttitle,
          description: con.servicerequestdescription,
          bidType: con.offertype,
          bidPrice: `$ ${con.offerprice}`,
          serviceProvider: `${con.fname} ${con.mname ? con.mname : ''} ${
            con.lname
          }`,
          date: new Date(con.created_at).toLocaleDateString('en-US'),
          status: con.offerstatus,
        },
      ]);
    });
  }, []);

  return (
    <Grid
      container
      style={{ height: props.content.length * 52 + 116, width: '100%' }}
      className={classes.root}
    >
      <DataGrid
        autoHeight
        components={{
          header: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        hideFooter
        showToolbar
        onSelectionChange={(param) => {
          var req =
            param.rowIds.length > 0 &&
            param.rowIds[0].split(',').map((step) => step);
          console.log(param);
          param.rowIds.length === 1 && req[2] === 'Offered'
            ? (setAccept(false), props.accept(req))
            : setAccept(true);
        }}
      />
    </Grid>
  );
}
