import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  Card,
  Button,
  Select,
  Input,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { Ip } from '../../data/ip';
import axios from 'axios';
import { useParams } from 'react-router';
import ContractsTable from './ContractsTable';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';

export default function Contracts() {
  let { filter } = useParams();
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalElements, setTotalElements] = useState();
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    setData([]);
    axios
      .get(
        `${Ip}/contract?pageSize=${pageSize}&pageNumber=${pageNumber}&filterby=${filter}&sortField=created_at&sortDirection=DESC`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.totalelements === 0) {
            setCreate(true);
          }
          console.log(response.data);
          setData(response.data.contracts);
          setTotalElements(response.data.totalelements);
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }, [pageSize, pageNumber, refresh]);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      key={data.length}
    >
      <CircularProgressIndicator open={data.length === 0 && !create} />
      {data.length > 0 ? (
        <Typography align="center" style={{ marginTop: 20, fontSize: 40 }}>
          Contracts
        </Typography>
      ) : (
        create && (
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Typography variant="h3">
              {`You don't have any Contracts at the moment!`}
            </Typography>
          </Grid>
        )
      )}
      {data.length > 0 && (
        <ContractsTable
          content={data}
          refresh={() => setRefresh(!refresh)}
          filter={filter}
        />
      )}

      {data.length > 0 && (
        <Grid container directoin="row" justify="flex-end" alignItems="center">
          <Typography>rows per page&nbsp;&nbsp;</Typography>
          <Select
            name="RowsPerPage"
            id="rowsperpage"
            input={<Input disableUnderline />}
            value={pageSize}
            onChange={(event) => {
              setPageSize(event.target.value);
              setPageNumber(0);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem
              value={10}
              disabled={(pageNumber + 1) * 5 >= totalElements}
            >
              10
            </MenuItem>
            <MenuItem
              value={15}
              disabled={(pageNumber + 1) * 10 >= totalElements}
            >
              15
            </MenuItem>
            <MenuItem
              value={20}
              disabled={(pageNumber + 1) * 15 >= totalElements}
            >
              20
            </MenuItem>
          </Select>
          <IconButton
            disabled={pageNumber === 0}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            disabled={(pageNumber + 1) * pageSize >= totalElements}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
