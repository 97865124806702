export const DataGridStyles = {
  root: {
    '& .MuiDataGrid-root': {
      border: 'solid 2px #cfd9e2',
      borderRadius: '0px',
      backgroundColor: '#ffffff',
      '& .Mui-selected': {
        backgroundColor: '#DFEEFB',
        '&:hover': {
          backgroundColor: '#DFEEFB',
        },
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-viewport': {
      width: '100%',
      '& .MuiIconButton-label': {
        color: '#728192',
      },
    },

    '& .MuiDataGrid-row': {
      borderBottom: 'solid 2px #cfd9e2',
      display: 'flex',
      '&:hover': {
        backgroundColor: '#DFEEFB',
      },
    },

    '& .MuiDataGrid-columnsContainer': {
      borderBottom: 'solid 2px #cfd9e2',
      '& .MuiDataGrid-colCellWrapper': {
        color: '#728192',
        '& .MuiIconButton-label': {
          color: '#728192',
        },
      },
    },
  },
};
