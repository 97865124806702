import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import currentLocLogo from '../../assets/Images/currentLocation.png';

function ResultsMap(props) {
  const [marker, setMarker] = useState({
    mark: {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    },
  });

  function onMarkerClick(props, marker) {
    setMarker({
      mark: {
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
      },
    });
  }

  function onClose() {
    if (marker.mark.showingInfoWindow) {
      setMarker({
        mark: {
          showingInfoWindow: false,
          activeMarker: null,
          selectedPlace: {},
        },
      });
    }
  }

  return (
    console.log(props.selectedLoc),
    (
      <Grid container style={{ width: '100%' }}>
        <Map
          google={props.google}
          zoom={12}
          containerStyle={{
            maxWidth: '43.5%',
            height: '80%',
          }}
          initialCenter={{
            lat: props.currentLoc.cords.lat,
            lng: props.currentLoc.cords.lon,
          }}
          center={{
            lat: props.selectedLoc.latitude
              ? props.selectedLoc.latitude
              : props.currentLoc.cords.lat,
            lng: props.selectedLoc.longitude
              ? props.selectedLoc.longitude
              : props.currentLoc.cords.lon,
          }}
        >
          <Marker
            icon={{
              url: currentLocLogo,
              scaledSize: new props.google.maps.Size(27, 35),
            }}
            onClick={onMarkerClick}
            name={props.currentLoc.name}
            position={{
              lat: props.currentLoc.cords.lat,
              lng: props.currentLoc.cords.lon,
            }}
            title={props.currentLoc.name}
          />
          {props.selectedLoc.name && (
            <Marker
              icon={{
                url:
                  'https://aux4.iconspalace.com/uploads/996985368574614663.png',
                scaledSize: new props.google.maps.Size(30, 35),
              }}
              onClick={onMarkerClick}
              name={props.selectedLoc.name}
              position={{
                lat: props.selectedLoc.latitude,
                lng: props.selectedLoc.longitude,
              }}
            ></Marker>
          )}
          {props.locs.map((loc, i) =>
            props.selectedLoc.latitude === loc.latitude ? null : (
              <Marker
                key={i}
                icon={{
                  url:
                    'https://www.freepnglogos.com/uploads/pin-png/location-pin-connectsafely-37.png',
                  scaledSize: new props.google.maps.Size(20, 25),
                }}
                onClick={onMarkerClick}
                name={loc.addressline1}
                position={{
                  lat: loc.latitude,
                  lng: loc.longitude,
                }}
              ></Marker>
            )
          )}
          <InfoWindow
            marker={marker.mark.activeMarker}
            visible={marker.mark.showingInfoWindow}
            onClose={onClose}
          >
            <Typography>{marker.mark.selectedPlace.name}</Typography>
          </InfoWindow>
        </Map>
      </Grid>
    )
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDWXB7pLY_ubdVDvG1vvD9oNshbUQ9qn80',
})(ResultsMap);

// class ResultsMap extends Component {
//   constructor(props) {
//     super(props);
//     // Receive the locations to display on the map as props
//     this.state = {
//       selectedMarker: false,
//     };
//   }

//   onMarkerClick() {
//     alert('click');
//   }

// render() {
//   return (
//     <Grid item xs={6}>
//       <Box m={1} width="100%">
//         <Map
//           google={this.props.google}
//           zoom={12}
//           containerStyle={{
//             maxWidth: '49%',
//             height: '80%',
//           }}
//           initialCenter={{
//             lat: this.props.currentLoc.cords.lat,
//             lng: this.props.currentLoc.cords.lon,
//           }}
//           // center={{
//           //   lat: this.props.currentLoc.cords.lat,
//           //   lng: this.props.currentLoc.cords.lon,
//           // }}
//         >
//           <Marker
//             icon={{
//               url: currentLocLogo,
//               scaledSize: new this.props.google.maps.Size(27, 35),
//             }}
//             onClick={this.onMarkerClick}
//             name={this.props.currentLoc.name}
//             position={{
//               lat: this.props.currentLoc.cords.lat,
//               lng: this.props.currentLoc.cords.lon,
//             }}
//             title={this.props.currentLoc.name}
//           />
//           {this.props.locs.map((loc, i) => (
//             <Marker
//               key={i}
//               icon={{
//                 url:
//                   'https://www.freepnglogos.com/uploads/pin-png/location-pin-connectsafely-37.png',
//                 scaledSize: new this.props.google.maps.Size(20, 25),
//               }}
//               position={{
//                 lat: loc.latitude,
//                 lng: loc.longitude,
//               }}
//               onClick={() => {
//                 console.log(
//                   `Setting selectedMarker: ${JSON.stringify(loc, null, 2)}`
//                 );
//                 this.setState({ ...this.state, selectedMarker: i });
//               }}
//               title={loc.addressline1}
//               name={'Something'}
//             >
//               {this.state.selectedMarker === i && (
//                 <InfoWindow
//                   onClose={() => {
//                     this.setState({ ...this.state, selectedMarker: false });
//                   }}
//                 >
//                   <Typography>
//                     {this.state.selectedMarker.addressline1}
//                   </Typography>
//                 </InfoWindow>
//               )}
//             </Marker>
//           ))}
//         </Map>
//       </Box>
//     </Grid>
//   );
// }
// }
