import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  Dialog,
} from '@material-ui/core';
import { Ip } from '../../../data/ip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';
import validator from 'validator';

const StyledTableCell = withStyles((theme) => ({
  root: {
    color: '#728192',
    borderBottom: 'solid 2px #cfd9e2',
    backgroundColor: '#ffffff',
    padding: '0px 0px 0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 56,
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 800,
    borderTop: 'solid 2px #cfd9e2',
    borderLeft: 'solid 2px #cfd9e2',
    borderRight: 'solid 2px #cfd9e2',
  },
});

export default function CreateMilestones(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([0]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [paymentPercentage, setPaymentPercentage] = useState('');
  const [editTitle, setEditTitle] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editPaymentPercentage, setEditPaymentPercentage] = useState('');
  const [milestones, setMilestones] = useState([]);
  const [callCreateMs, setCallcreateMs] = useState(false);
  const [percentageIndicator, setPercentageIndicator] = useState(0);
  const [percentageCondition, setPercentageCondition] = useState(false);
  const [prevMilestones, setPrevMilestones] = useState(props.milestones || '');
  const [editIndex, setEditIndex] = useState('');
  const [prevEditIndex, setPrevEditIndex] = useState('');
  const [disableEdit, setDisableEdit] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [MsIdtoDelete, setMsIdtoDelete] = useState('');
  const [deleteIndex, setDeleteIndex] = useState('');
  const [updatePreviousMs, setUpdatePreviousMs] = useState(false);

  function createMilestone() {
    const body = milestones;
    console.log(`payload: ${JSON.stringify(body, null, 2)}`);
    fetch(`${Ip}/milestone`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          props.refresh();
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  function updateMilestone() {
    const body = prevMilestones;
    console.log(`payload: ${JSON.stringify(body, null, 2)}`);
    fetch(`${Ip}/milestone`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log('put', response);
        if (response.status === 200) {
          props.refresh();
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  useEffect(() => {
    var i = 0;
    milestones.map((ms) => {
      i = i + Number(ms.paymentPercentage);
    });
    prevMilestones &&
      prevMilestones.map((prevMS) => {
        i = i + Number(prevMS.paymentPercentage);
      });
    setPercentageIndicator(+i.toFixed(1));
    console.log('percentage Indicator in ms', +i);
  }, [milestones, prevMilestones]);

  useEffect(() => {
    var i = 0;
    editIndex !== '' || prevEditIndex !== ''
      ? ((i = percentageIndicator - Number(editPaymentPercentage)),
        setPercentageIndicator(+i.toFixed(1)),
        console.log('percentage Indicator in edIndex', +i))
      : null;
  }, [editIndex, prevEditIndex]);

  useEffect(() => {
    editPaymentPercentage && percentageIndicator !== 100
      ? (setPercentageCondition(
          Number(editPaymentPercentage) > 100 - percentageIndicator
        ),
        console.log('edp', editPaymentPercentage),
        console.log('pi', 100 - percentageIndicator),
        console.log(Number(editPaymentPercentage) > 100 - percentageIndicator))
      : setPercentageCondition(
          Number(paymentPercentage) > 100 - percentageIndicator
        );
  }, [paymentPercentage, editPaymentPercentage]);

  useEffect(() => {
    milestones.length > 0 && createMilestone();
    updatePreviousMs && updateMilestone();
  }, [callCreateMs]);

  function handleDelete() {
    console.log(`${Ip}/milestone?milestoneids=${MsIdtoDelete}`);
    axios
      .delete(`${Ip}/milestone?milestoneids=${MsIdtoDelete}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          console.log('deleted');
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  return (
    <Grid item xs={12} style={{ width: '100%' }}>
      <Box m={1}>
        <TableContainer className={classes.container}>
          {
            <Table stickyHeader className={classes.table}>
              <TableHead style={{ height: 60 }}>
                <TableRow>
                  <StyledTableCell>Title</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Payment Percentage</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Add more milestones</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prevMilestones &&
                  prevMilestones.map((i, j) => (
                    <StyledTableRow key={j}>
                      <StyledTableCell component="th" scope="row">
                        <TextField
                          disabled={prevEditIndex !== j}
                          multiline={true}
                          name="Title"
                          id="Title"
                          type="text"
                          placeholder="Enter Title"
                          InputProps={{ disableUnderline: true }}
                          value={prevEditIndex === j ? editTitle : i.title}
                          onChange={(event) => {
                            setEditTitle(event.target.value);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          disabled={prevEditIndex !== j}
                          multiline={true}
                          name="Description"
                          id="Description"
                          type="text"
                          placeholder="Enter Description"
                          InputProps={{ disableUnderline: true }}
                          value={
                            prevEditIndex === j
                              ? editDescription
                              : i.description
                          }
                          onChange={(event) => {
                            setEditDescription(event.target.value);
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <TextField
                          disabled={prevEditIndex !== j}
                          name="PaymentPercentage"
                          id="PaymentPercentage"
                          type="text"
                          placeholder="Enter Payment Percentage"
                          InputProps={{ disableUnderline: true }}
                          value={
                            prevEditIndex === j
                              ? editPaymentPercentage
                              : i.paymentPercentage
                          }
                          onChange={(event) => {
                            if (
                              validator.isNumeric(event.target.value) ||
                              validator.isEmpty(event.target.value)
                            )
                              setEditPaymentPercentage(event.target.value);
                          }}
                          error={
                            prevEditIndex !== j
                              ? false
                              : editIndex || editIndex === 0
                              ? false
                              : (+editPaymentPercentage < 10 &&
                                  editPaymentPercentage !== '') ||
                                percentageCondition
                            //+paymentPercentage means Number(paymentPercentage)
                          }
                          helperText={
                            prevEditIndex !== j
                              ? false
                              : editIndex || editIndex === 0
                              ? false
                              : +editPaymentPercentage < 10 &&
                                editPaymentPercentage !== ''
                              ? 'value should not be less than 10'
                              : percentageCondition
                              ? `value should not exceed ${(
                                  100 - percentageIndicator
                                ).toFixed(1)}`
                              : null
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography>
                          $
                          {prevEditIndex !== j
                            ? (i.paymentPercentage * props.amount) / 100
                            : (editPaymentPercentage * props.amount) / 100}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {prevEditIndex === j ? (
                          <Grid container direction="row" justify="flex-start">
                            <Button
                              disabled={editPaymentPercentage < 10}
                              onClick={() => {
                                var ms = [...prevMilestones];
                                (editTitle !== i.title ||
                                  editDescription !== i.description ||
                                  editPaymentPercentage !==
                                    i.paymentPercentage) &&
                                  editTitle &&
                                  editDescription &&
                                  editPaymentPercentage &&
                                  ms.splice(j, 1, {
                                    contractid: i.contractid,
                                    title: editTitle,
                                    description: editDescription,
                                    completionstatus: 'NotStarted',
                                    paymentPercentage: editPaymentPercentage,
                                    paymentstatus: 'NotPaid',
                                    milestoneid: i.milestoneid,
                                  });
                                setEditTitle('');
                                setEditDescription('');
                                setEditPaymentPercentage('');
                                setPrevMilestones(ms);
                                setPrevEditIndex('');
                                setDisableEdit(false);
                                setUpdatePreviousMs(true);
                              }}
                            >
                              <CheckIcon />
                            </Button>

                            <Button
                              onClick={() => {
                                setDisableEdit(false);
                                setPrevEditIndex('');
                                setEditTitle('');
                                setEditDescription('');
                                setEditPaymentPercentage('');
                                setPrevMilestones([...prevMilestones]);
                              }}
                            >
                              <ClearIcon />
                            </Button>
                          </Grid>
                        ) : (
                          <Grid container direction="row" justify="flex-start">
                            <Button
                              disabled={disableEdit}
                              onClick={() => {
                                setDisableEdit(true);
                                setPrevEditIndex(j);
                                setEditTitle(i.title);
                                setEditDescription(i.description);
                                setEditPaymentPercentage(
                                  String(i.paymentPercentage)
                                );
                              }}
                            >
                              <EditIcon />
                            </Button>

                            <Button
                              onClick={() => {
                                setConfirmDelete(true);
                                setMsIdtoDelete(i.milestoneid);
                                setDeleteIndex(j);
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </Button>
                          </Grid>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                {rows.map((i, j) => (
                  <StyledTableRow key={j}>
                    <StyledTableCell component="th" scope="row">
                      <TextField
                        disabled={j < rows.length - 1 && editIndex !== j}
                        multiline={true}
                        name="Title"
                        id="Title"
                        type="text"
                        placeholder="Enter Title"
                        InputProps={{ disableUnderline: true }}
                        value={
                          editIndex === j
                            ? editTitle
                            : milestones[j]
                            ? milestones[j].title
                            : title
                        }
                        onChange={(event) => {
                          editIndex === j
                            ? setEditTitle(event.target.value)
                            : setTitle(event.target.value);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        disabled={j < rows.length - 1 && editIndex !== j}
                        multiline={true}
                        name="Description"
                        id="Description"
                        type="text"
                        placeholder="Enter Description"
                        InputProps={{ disableUnderline: true }}
                        value={
                          editIndex === j
                            ? editDescription
                            : milestones[j]
                            ? milestones[j].description
                            : description
                        }
                        onChange={(event) => {
                          editIndex === j
                            ? setEditDescription(event.target.value)
                            : setDescription(event.target.value);
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TextField
                        disabled={j < rows.length - 1 && editIndex !== j}
                        name="PaymentPercentage"
                        id="PaymentPercentage"
                        type="text"
                        placeholder="Enter Payment Percentage"
                        InputProps={{ disableUnderline: true }}
                        value={
                          editIndex === j
                            ? editPaymentPercentage
                            : milestones[j]
                            ? milestones[j].paymentPercentage
                            : paymentPercentage
                        }
                        onChange={(event) => {
                          if (
                            validator.isNumeric(event.target.value) ||
                            validator.isEmpty(event.target.value)
                          )
                            editIndex === j
                              ? setEditPaymentPercentage(event.target.value)
                              : setPaymentPercentage(event.target.value);
                        }}
                        error={
                          (editIndex === j &&
                            +editPaymentPercentage < 10 &&
                            editPaymentPercentage !== '') ||
                          (editIndex === j && percentageCondition)
                            ? true
                            : j < rows.length - 1
                            ? false
                            : prevEditIndex || prevEditIndex === 0
                            ? false
                            : (+paymentPercentage < 10 &&
                                paymentPercentage !== '') ||
                              (percentageCondition && editIndex === '')
                          //+paymentPercentage means Number(paymentPercentage)
                        }
                        helperText={
                          editIndex === j &&
                          +editPaymentPercentage < 10 &&
                          editPaymentPercentage !== ''
                            ? 'value should not be less than 10'
                            : editIndex === j && percentageCondition
                            ? `value should not exceed ${(
                                100 - percentageIndicator
                              ).toFixed(1)}`
                            : j < rows.length - 1
                            ? null
                            : prevEditIndex || prevEditIndex === 0
                            ? null
                            : +paymentPercentage < 10 &&
                              paymentPercentage !== ''
                            ? 'value should not be less than 10'
                            : percentageCondition && editIndex === ''
                            ? `value should not exceed ${(
                                100 - percentageIndicator
                              ).toFixed(1)}`
                            : null
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography>
                        $
                        {editIndex === j
                          ? (editPaymentPercentage * props.amount) / 100
                          : j === rows.length - 1
                          ? (paymentPercentage * props.amount) / 100
                          : (Number(milestones[i].paymentPercentage) *
                              Number(props.amount)) /
                            100}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      {j === rows.length - 1 ? (
                        <Button
                          justify="center"
                          style={{ width: '50%' }}
                          disabled={
                            !title ||
                            !description ||
                            !paymentPercentage ||
                            editIndex !== '' ||
                            prevEditIndex !== '' ||
                            +paymentPercentage >= 100 - percentageIndicator ||
                            +paymentPercentage < 10
                          }
                          onClick={() => {
                            setRows([...rows, rows[rows.length - 1] + 1]);
                            setMilestones([
                              ...milestones,
                              {
                                contractid: props.contractid,
                                title: title,
                                description: description,
                                completionstatus: 'NotStarted',
                                paymentPercentage: paymentPercentage,
                                paymentstatus: 'NotPaid',
                              },
                            ]);
                            setTitle('');
                            setDescription('');
                            setPaymentPercentage('');
                          }}
                        >
                          <AddCircleOutlineIcon />
                        </Button>
                      ) : editIndex === j ? (
                        <Grid container direction="row" justify="flex-start">
                          <Button
                            disabled={editPaymentPercentage < 10}
                            onClick={() => {
                              var ms = [...milestones];
                              (editTitle === milestones[j].title ||
                                editDescription === milestones[j].description ||
                                editPaymentPercentage ===
                                  milestones[j].paymentPercentage) &&
                                editTitle &&
                                editDescription &&
                                editPaymentPercentage &&
                                ms.splice(j, 1, {
                                  contractid: `${props.contractid}`,
                                  title: editTitle,
                                  description: editDescription,
                                  completionstatus: 'NotStarted',
                                  paymentPercentage: editPaymentPercentage,
                                  paymentstatus: 'NotPaid',
                                });
                              setEditTitle('');
                              setEditDescription('');
                              setEditPaymentPercentage('');
                              setMilestones(ms);
                              setEditIndex('');
                              setDisableEdit(false);
                            }}
                          >
                            <CheckIcon />
                          </Button>

                          <Button
                            onClick={() => {
                              setDisableEdit(false);
                              setEditIndex('');
                              setEditTitle('');
                              setEditDescription('');
                              setEditPaymentPercentage('');
                              setMilestones([...milestones]);
                            }}
                          >
                            <ClearIcon />
                          </Button>
                        </Grid>
                      ) : (
                        <Grid container direction="row" justify="flex-start">
                          <Button
                            disabled={disableEdit}
                            onClick={() => {
                              setDisableEdit(true);
                              setEditIndex(j);
                              setEditTitle(milestones[j].title);
                              setEditDescription(milestones[j].description);
                              setEditPaymentPercentage(
                                milestones[j].paymentPercentage
                              );
                            }}
                          >
                            <EditIcon />
                          </Button>
                          <Button
                            onClick={() => {
                              setConfirmDelete(true);
                              setDeleteIndex(j);
                            }}
                          >
                            <RemoveCircleOutlineIcon />
                          </Button>
                        </Grid>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          }
        </TableContainer>
        {Number(paymentPercentage) + percentageIndicator === 100 ? (
          <Box m={1} style={{ maxWidth: '100%' }}>
            <Typography variant="caption">
              Note: If you want to add more Milestones kindly edit the created
              milestones and decrease the payment percentage.
            </Typography>
          </Box>
        ) : (
          <Box m={1} style={{ maxWidth: '100%' }}>
            <Typography variant="caption">
              Note: Maximum number of milestones allowed is 10 and you cannot
              create/update milestones until the sum of payment percentage in
              all the milestones equals to 100.
            </Typography>
          </Box>
        )}
        <Grid container direction="row" justify="space-around">
          <Box m={2}>
            <Button
              style={{
                textTransform: 'none',
                color: '#ffffff',
              }}
              variant="contained"
              color="primary"
              disabled={
                updatePreviousMs
                  ? Number(paymentPercentage) + percentageIndicator !== 100 ||
                    !updatePreviousMs
                  : ((!title || !description || !paymentPercentage) &&
                      milestones.length === 0) ||
                    Number(paymentPercentage) + percentageIndicator !== 100
              }
              onClick={() => {
                title &&
                  description &&
                  paymentPercentage &&
                  setMilestones([
                    ...milestones,
                    {
                      contractid: `${props.contractid}`,
                      title: title,
                      description: description,
                      completionstatus: 'NotStarted',
                      paymentPercentage: paymentPercentage,
                      paymentstatus: 'NotPaid',
                    },
                  ]);
                setTitle('');
                setDescription('');
                setPaymentPercentage('');
                setCallcreateMs(true);
              }}
            >
              {props.milestones ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Box>

      {confirmDelete && (
        <Dialog
          open={confirmDelete}
          onClose={() => {
            setConfirmDelete(false);
          }}
        >
          <Box p={1}>
            <Grid container direction="column" justify="center">
              <Typography>Do you want to delete this milestone?</Typography>
              <Box pt={1}>
                <Grid container direction="row" justify="space-around">
                  <Button
                    onClick={() => {
                      setConfirmDelete(false);
                      setMsIdtoDelete('');
                      setDeleteIndex('');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={
                      MsIdtoDelete !== ''
                        ? () => {
                            handleDelete();
                            const deletePrevMsEl = [...prevMilestones];
                            deletePrevMsEl.splice(deleteIndex, 1);
                            setPrevMilestones([...deletePrevMsEl]);
                            setConfirmDelete(false);
                            setMsIdtoDelete('');
                            setDeleteIndex('');
                          }
                        : () => {
                            const deleteRowEl = [...rows];
                            deleteRowEl.splice(deleteIndex, 1);
                            setRows([...deleteRowEl]);
                            const deleteMsEl = [...milestones];
                            deleteMsEl.splice(deleteIndex, 1);
                            setMilestones([...deleteMsEl]);
                            setConfirmDelete(false);
                            setDeleteIndex('');
                          }
                    }
                  >
                    Continue
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Dialog>
      )}
    </Grid>
  );
}
