import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import CreateServiceRequest from './CreateServiceRequest';
import { useParams } from 'react-router';

export default function RequestProvider() {
  let { ids } = useParams();
  let Ids = ids.split(',').map((step) => step);
  let serviceId = Ids[0];
  let providerId = Ids[1];
  console.log('provider id:', providerId, 'serviceId:', serviceId);

  return localStorage.getItem('userId') === providerId ? (
    <Grid
      container
      item
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Typography variant="h3">{`You can't assign work for yourself`}</Typography>
      <Button
        href="/"
        onClick={() =>
          localStorage.setItem('createServiceRequestBySearchingProviders', '')
        }
      >
        Continue
      </Button>
    </Grid>
  ) : (
    <CreateServiceRequest serviceid={serviceId} providerid={providerId} />
  );
}
