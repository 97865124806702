import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Button, Card } from '@material-ui/core';
import axios from 'axios';
import { Ip } from '../../data/ip';
import SubscriptionModel from './SubscriptionModel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';

export default function Subscription() {
  const [showModel, setShowModel] = useState(false);
  const [showProgressIndicator, setShowProgresIndicator] = useState(true);
  const [subscription, setSubscription] = useState('');
  useEffect(() => {
    axios
      .get(`${Ip}/subscription`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          response.data === '' && setShowModel(true);
          setShowProgresIndicator(false);
          setSubscription(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Grid container direction="column" alignItems="center">
      {showModel ? (
        <SubscriptionModel />
      ) : showProgressIndicator ? (
        <Grid container justify="center">
          <CircularProgressIndicator open={showProgressIndicator} />
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          justify="center"
          alignContent="center"
        >
          <Box m={2}>
            <Card elevation={2}>
              <Box m={2}>
                {subscription && (
                  <Typography>
                    Subscription: active
                    <br />
                    Plan: {subscription.status} <br />
                    expires on:
                    {new Date(subscription.enddate).toLocaleDateString('en-US')}
                  </Typography>
                )}
              </Box>
            </Card>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
