import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Typography, Grid, Box, Button, Card } from '@material-ui/core';
import { Ip } from '../../data/ip';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';

export default function Payment() {
  const [subsriptionDetails] = useState(
    JSON.parse(localStorage.getItem('SubscriptionDetails'))
  );
  const [load, setLoad] = useState(false);
  const subscriptionPlanId = subsriptionDetails.subscriptionplanid;
  const name = subsriptionDetails.name;
  const price = subsriptionDetails.price;
  const period = subsriptionDetails.period;

  const createCheckoutSession = async () => {
    setLoad(true);
    let r;
    let body = {
      paymentType: 'subscription',
      subscriptionPlanId: subscriptionPlanId,
      successUrl: `${window.location.origin}/paymentsuccess/null`,
      cancelUrl: `${window.location.origin}/paymentcancelled`,
    };
    try {
      r = await axios.post(`${Ip}/payments/checkoutsession`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
      });
    } catch (e) {
      console.log({ error: e });
    }
    setLoad(false);
    console.log({ sessionCreation: r.data });
    await stripe.redirectToCheckout({
      sessionId: r.data,
    });
  };

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ width: '100vw', height: '80vh' }}
    >
      <CircularProgressIndicator open={load} />
      <Typography align="center" variant="h4">
        You have choosen the plan{' '}
        <span style={{ color: 'orange' }}>
          <b>{name}</b>
        </span>{' '}
        for 1 {period}
      </Typography>

      <Button
        onClick={createCheckoutSession}
        style={{
          marginTop: '3%',
          backgroundColor: '#f5802c',
          color: 'white',
        }}
      >
        proceed to pay {price}
      </Button>
    </Grid>
  );
}
