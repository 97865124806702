import React, { Component, useEffect } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Button,
  CardMedia,
  Box,
  Paper,
  Link,
  withStyles,
  InputAdornment,
  MenuItem,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { Ip } from '../../../data/ip';
import validator from 'validator';
import Info from '../Info';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import AddServices from './AddServices';
import ServicesTable from './ServicesTable';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';
import GoHome from '../../../components/GoHome';

class Register3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobtitle: this.props.jobTitle || '',
      preferredpaymentreceiptmethod: '',
      userServices: '',
      servicesDetailstoDisplay: '',
      openAddServicesDialog: false,
      showTable: false,
      progressIndicator: false,
    };

    this.jobtitle = this.jobtitle.bind(this);
    this.preferredpaymentreceiptmethod = this.preferredpaymentreceiptmethod.bind(
      this
    );
    this.register = this.register.bind(this);
  }

  jobtitle(event) {
    this.setState({ jobtitle: event.target.value });
  }

  preferredpaymentreceiptmethod(event) {
    this.setState({
      preferredpaymentreceiptmethod: event.target.value,
    });
  }

  register() {
    this.setState({ progressIndicator: true });
    const body = {
      jobTitle: this.state.jobtitle,
      userServices: this.state.userServices,
      paymentReceiptMethodId: this.state.preferredpaymentreceiptmethod || '1',
    };
    console.log(`payload: ${JSON.stringify(body, null, 2)}`);
    fetch(`${Ip}/user/service`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('JobTitle', this.state.jobtitle);
          this.setState({ progressIndicator: false });
          if (localStorage.getItem('UserType') !== 'SERVICE_PROVIDER') {
            this.setState({ progressIndicator: true });
            fetch(`${Ip}/user`, {
              method: 'PATCH',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${
                  localStorage.getItem('ResetToken') || ''
                }`,
              },
              body: JSON.stringify({
                usertype: 'SERVICE_PROVIDER',
              }),
            })
              .then((response) => {
                this.setState({ progressIndicator: false });
                localStorage.setItem('UserType', 'SERVICE_PROVIDER');
                console.log('userType', response);
              })
              .catch((err) => {
                alert(`ERR: ${err}`);
                console.log(err);
              });
          }
          localStorage.getItem('createServiceRequestBySearchingProviders')
            ? this.props.history.push(
                `/requestprovider/${localStorage.getItem(
                  'createServiceRequestBySearchingProviders'
                )}`
              )
            : !this.props.add
            ? this.props.history.push('/registrationcompleted')
            : this.props.update();
        } else if (response.status === 500) {
          this.setState({ progressIndicator: false });
          alert(
            'All (or) a few services added by you are already registered with us, Please remove the repeated services and proceed'
          );
        }
        console.log(response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }
  render() {
    console.log('user services are', this.state.servicesDetailstoDisplay);
    const { classes } = this.props;
    return localStorage.getItem('JobTitle') ? (
      <GoHome message="Registration is completed" />
    ) : (
      <Paper>
        <CircularProgressIndicator open={this.state.progressIndicator} />
        <Info />
        <Grid
          container
          direction="column"
          style={{
            position: 'absolute',
            height: '100%',
            width: '70%',
            transform: 'translateX(+42%)',
          }}
        >
          {this.state.showTable ? (
            <Grid
              key={this.state.userServices}
              container
              direction="column"
              justify="center"
              alignContent="center"
              alignItems="flex-end"
              style={{
                marginTop: '100px',
              }}
            >
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{
                  width: '90.9%',
                  marginBottom: '10px',
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  style={{ width: '70%' }}
                >
                  <Grid
                    container
                    direction="column"
                    style={{ width: '49%' }}
                    justify="space-around"
                  >
                    <Typography variant="body1" style={{ fontSize: 12 }}>
                      Add Job Title*
                    </Typography>
                    <TextField
                      type="text"
                      placeholder="Job Title"
                      variant="outlined"
                      size="small"
                      value={this.state.jobtitle}
                      onChange={this.jobtitle}
                    ></TextField>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    style={{ width: '49%' }}
                    justify="space-around"
                  >
                    <Typography variant="body1" style={{ fontSize: 12 }}>
                      Preffered Payment Receipt Method*
                    </Typography>
                    <TextField
                      type="text"
                      label={
                        this.state.preferredpaymentreceiptmethod === ''
                          ? 'Preffered Payment Receipt Method'
                          : ''
                      }
                      InputLabelProps={{ shrink: false }}
                      variant="outlined"
                      size="small"
                      value={this.state.preferredpaymentreceiptmethod}
                      onChange={this.preferredpaymentreceiptmethod}
                      select
                    >
                      <MenuItem value="1">Bank Account</MenuItem>
                      <MenuItem value="2">Paypal</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
                <Button
                  onClick={() => {
                    this.setState({ openAddServicesDialog: true });
                  }}
                >
                  <Typography
                    style={{
                      color: '#f5802c',
                      fontSize: '14px',
                      fontWeight: 500,
                    }}
                  >
                    <AddIcon fontSize="small" /> Add more services
                  </Typography>
                </Button>
              </Grid>
              <Grid style={{ width: '90.9%' }}>
                <ServicesTable content={this.state.servicesDetailstoDisplay} />
              </Grid>
              <Button
                variant="contained"
                size="large"
                style={{
                  textTransform: 'none',
                  backgroundColor: '#f5802c',
                  color: '#FFFFFF',
                  width: '20%',
                }}
                disabled={
                  !this.state.jobtitle ||
                  !this.state.preferredpaymentreceiptmethod ||
                  !this.state.userServices
                }
                onClick={this.register}
              >
                <Typography>Finish</Typography>
              </Button>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{
                position: 'fixed',
                top: 100,
                width: '100%',
                height: '75%',
              }}
            >
              <Box
                style={{
                  width: '50%',
                  height: '265px',
                  margin: '30px 19px 0 0',
                  flexGrow: 0,
                  boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
                }}
              >
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  style={{
                    height: '100%',
                  }}
                >
                  <Typography
                    align="center"
                    style={{ fontWeight: 500, color: '#8692a6' }}
                  >
                    No serives found.
                    <br />
                    Please add services to see results.
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      textTransform: 'none',
                      position: 'relative',
                      top: 15,
                      backgroundColor: '#f5802c',
                      color: '#FFFFFF',
                      width: '40%',
                    }}
                    onClick={() => {
                      this.setState({ openAddServicesDialog: true });
                    }}
                  >
                    <AddIcon />
                    <Typography> Add Services</Typography>
                  </Button>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
        <AddServices
          open={this.state.openAddServicesDialog}
          close={() => this.setState({ openAddServicesDialog: false })}
          addedServices={this.state.servicesDetailstoDisplay}
          add={(addedServices) => {
            this.setState((prevState) => ({
              userServices: [...prevState.userServices, addedServices],
            }));
          }}
          servicesDetailstoDisplay={(serviceDetails) => {
            this.setState((prevState) => ({
              servicesDetailstoDisplay: [
                ...prevState.servicesDetailstoDisplay,
                serviceDetails,
              ],
            }));
          }}
          showtable={() => {
            this.setState({ showTable: true });
          }}
        />
      </Paper>
    );
  }
}
export default Register3;

// import React, { Component } from 'react';
// import {
//   TextField,
//   Grid,
//   Button,
//   Typography,
//   MenuItem,
//   Box,
// } from '@material-ui/core';
// import { Ip } from '../../data/ip';
// import validator from 'validator';
// import RegistrationTable from './RegisterationTable';

// class Register extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       jobtitle: this.props.jobTitle || '',
//       preferredpaymentreceiptmethod: '',
//       userServices: '',
//     };

//     this.jobtitle = this.jobtitle.bind(this);
//     this.preferredpaymentreceiptmethod = this.preferredpaymentreceiptmethod.bind(
//       this
//     );
//     this.register = this.register.bind(this);
//   }

//   jobtitle(event) {
//     this.setState({ jobtitle: event.target.value });
//   }

//   preferredpaymentreceiptmethod(event) {
//     this.setState({
//       preferredpaymentreceiptmethod: event.target.value,
//     });
//   }

//   register(event) {
//     const body = {
//       jobTitle: this.state.jobtitle,
//       userServices: this.state.userServices,
//       paymentReceiptMethodId: this.state.preferredpaymentreceiptmethod || '1',
//     };
//     console.log(`payload: ${JSON.stringify(body, null, 2)}`);
//     fetch(`${Ip}/user/service`, {
//       method: 'post',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
//       },
//       body: JSON.stringify(body),
//     })
//       .then((response) => {
//         if (response.status === 200) {
//           localStorage.getItem('UserType') !== 'SERVICE_PROVIDER'
//             ? fetch(`${Ip}/user`, {
//                 method: 'PATCH',
//                 headers: {
//                   Accept: 'application/json',
//                   'Content-Type': 'application/json',
//                   Authorization: `Bearer ${
//                     localStorage.getItem('ResetToken') || ''
//                   }`,
//                 },
//                 body: JSON.stringify({
//                   usertype: 'SERVICE_PROVIDER',
//                 }),
//               })
//                 .then((response) => {
//                   localStorage.setItem('UserType', 'SERVICE_PROVIDER');
//                   console.log('userType', response);
//                 })
//                 .catch((err) => {
//                   alert(`ERR: ${err}`);
//                   console.log(err);
//                 })
//             : null;
//           localStorage.getItem('createServiceRequestBySearchingProviders')
//             ? this.props.history.push(
//                 `/requestprovider/${localStorage.getItem(
//                   'createServiceRequestBySearchingProviders'
//                 )}`
//               )
//             : !this.props.add
//             ? this.props.history.push('/')
//             : this.props.update();
//         } else if (response.status === 500) {
//           alert(
//             'All (or) a few services added by you are already registered with us, Please remove the repeated services and proceed'
//           );
//         }
//         console.log(response);
//       })
//       .catch((err) => {
//         alert(`ERR: ${err}`);
//         console.log(err);
//       });
//   }

//   render() {
//     console.log('parent', this.state.userServices);

//     return (
//       <div>
//         <form name="MyForm2" autoComplete="on">
//           <Grid
//             container
//             justify="center"
//             wrap="wrap"
//             direction="column"
//             alignItems="center"
//           >
//             <Box mb={1}>
//               {!this.props.add ? (
//                 <Grid
//                   container
//                   item
//                   justify="center"
//                   alignItems="center"
//                   xs={12}
//                 >
//                   <Typography variant="h4" align="center">
//                     USER REGISTRATION
//                   </Typography>
//                 </Grid>
//               ) : null}
//             </Box>
//             <Grid container item justify="center" alignItems="center" xs={12}>
//               <Box
//                 borderColor="primary.main"
//                 borderRadius={16}
//                 alignItems="center"
//                 justifyContent="center"
//                 m={1}
//                 border={!this.props.add ? 1 : 0}
//                 style={{
//                   width: '900px',
//                 }}
//               >
//                 <RegistrationTable
//                   clicked={(services) =>
//                     this.setState({ userServices: services })
//                   }
//                 />
//                 {!this.props.add ? (
//                   <Box
//                     display="flex"
//                     alignItems="center"
//                     justifyContent="center"
//                     p={1}
//                     m={1}
//                     css={{ height: 100 }}
//                   >
//                     <Box p={1}>
//                       <TextField
//                         required
//                         name="jobtitle"
//                         id="jobtitle"
//                         type="text"
//                         variant="outlined"
//                         margin="dense"
//                         label="Jobtitle"
//                         onChange={this.jobtitle}
//                       />
//                     </Box>
//                     <Box p={1}>
//                       <TextField
//                         name="paymentreceiptmethod"
//                         id="paymentreceiptmethod"
//                         type="value"
//                         variant="outlined"
//                         margin="dense"
//                         label="Preferred payment receipt method"
//                         onChange={this.preferredpaymentreceiptmethod}
//                         style={{ width: '350px' }}
//                         select
//                       >
//                         <MenuItem value="1">Bank Account</MenuItem>
//                         <MenuItem value="2">Paypal</MenuItem>
//                       </TextField>
//                     </Box>
//                   </Box>
//                 ) : null}
//                 <Box
//                   display="flex"
//                   alignItems="center"
//                   justifyContent="center"
//                   p={1}
//                 >
//                   <Button
//                     onClick={this.register}
//                     variant="contained"
//                     color="primary"
//                     disabled={!this.state.jobtitle || !this.state.userServices}
//                   >
//                     Save
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//           </Grid>
//         </form>
//       </div>
//     );
//   }
// }
// export default Register;
