import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Link,
  Box,
  TextField,
  Button,
  Select,
  Input,
  MenuItem,
  IconButton,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import axios from 'axios';
import { Ip } from '../../data/ip';
import { useParams } from 'react-router';
import ResultsTable from './ResultsTable';
import ResultsMap from './ResultsMap';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SearchResults(props) {
  let { search } = useParams();
  const useStyles = makeStyles({
    root: {
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },
    },
  });
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [searchId, setSearch] = useState(search);
  const [find, setFind] = useState(searchId);
  const [noResults, setNoResults] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalElements, setTotalElements] = useState();
  const [currentLoc, setCurrentLoc] = useState({
    cords: {
      lat: 20.5937,
      lon: 78.9629,
    },
    name: 'India',
  });
  const [selectedLoc, setSelectedLoc] = useState({
    name: '',
    latitude: '',
    longitude: '',
  });

  useEffect(() => {
    setData([]);
    setNoResults(false);
    axios
      .get(
        `${Ip}/serviceprovider${
          localStorage.getItem('ResetToken') ? '/authenticated' : ''
        }?filter=${find}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortColumn=fname&sortOrder=ASC`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.serviceproviders.content.length > 0)
            setData(response.data.serviceproviders.content);
          if (response.data.totalelements === 0) setNoResults(true);
          setTotalElements(response.data.totalelements);
        }
        console.log(response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }, [find, pageNumber, pageSize]);

  useEffect(() => {
    const registeredLocation = JSON.parse(localStorage.getItem('location'));
    registeredLocation &&
      setCurrentLoc({
        cords: {
          lat: registeredLocation.latitude,
          lon: registeredLocation.longitude,
        },
        name: registeredLocation.name,
      });

    navigator.geolocation.getCurrentPosition(
      function (position) {
        setCurrentLoc({
          cords: {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          },
          name: 'My Location',
        });
      },
      function (error) {
        console.error('Error Code = ' + error.code + ' - ' + error.message);
      }
    );
  }, []);

  return (
    <Grid container style={{ marginTop: '10px' }}>
      <TextField
        className={classes.root}
        fullWidth
        size="small"
        variant="outlined"
        placeholder="Search"
        defaultValue={searchId}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        onKeyPress={(event) => {
          var code;
          if (event.key !== undefined) {
            code = event.key;
          } else if (event.keyIdentifier !== undefined) {
            code = event.keyIdentifier;
          } else if (event.keyCode !== undefined) {
            code = event.keyCode;
          }
          if (code === 13 || code === 'Enter') {
            setFind(event.target.value);
          }
        }}
        style={{
          backgroundColor: '#ffffff',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment style={{ whiteSpace: 'pre' }}>
              <Typography>I need : </Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment style={{ whiteSpace: 'pre', height: '100%' }}>
              <Button
                disableElevation
                onClick={() => {
                  setFind(searchId);
                }}
                variant="contained"
                color="primary"
                style={{
                  textTransform: 'none',
                  color: '#ffffff',
                  height: '40px',
                  borderTopLeftRadius: '0px',
                  borderBottomLeftRadius: '0px',
                }}
              >
                Find
              </Button>
            </InputAdornment>
          ),
        }}
      />

      <Grid container direction="row" alignItems="center">
        {!localStorage.getItem('ResetToken') ? (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="baseline"
            style={{ marginTop: 10 }}
          >
            <Link
              href="/login"
              underline="always"
              style={{ marginRight: '1em' }}
            >
              <Typography variant="h6">Sign In</Typography>
            </Link>
            <Link href="/register1" underline="always">
              <Typography variant="h6">Sign Up</Typography>
            </Link>
          </Grid>
        ) : null}
      </Grid>
      {data.length > 0 ? (
        <Grid
          container
          direction="row"
          justify="space-between"
          style={{ width: '100%', marginTop: '20px' }}
        >
          <Grid
            container
            direction="column"
            style={{ width: '49.5%', overflow: 'hidden', position: 'relative' }}
          >
            <ResultsTable
              content={data}
              clicked={(data) => {
                setSelectedLoc({
                  name: data.name,
                  latitude: data.latitude,
                  longitude: data.longitude,
                });
              }}
            />

            <Grid
              container
              directoin="row"
              justify="flex-end"
              alignItems="center"
            >
              <Typography>rows per page&nbsp;&nbsp;</Typography>
              <Select
                name="RowsPerPage"
                id="rowsperpage"
                input={<Input disableUnderline />}
                value={pageSize}
                onChange={(event) => {
                  setPageSize(event.target.value);
                  setPageNumber(0);
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem
                  value={10}
                  disabled={(pageNumber + 1) * 5 >= totalElements}
                >
                  10
                </MenuItem>
                <MenuItem
                  value={15}
                  disabled={(pageNumber + 1) * 10 >= totalElements}
                >
                  15
                </MenuItem>
                <MenuItem
                  value={20}
                  disabled={(pageNumber + 1) * 15 >= totalElements}
                >
                  20
                </MenuItem>
              </Select>
              <IconButton
                disabled={pageNumber === 0}
                onClick={() => setPageNumber(pageNumber - 1)}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                disabled={(pageNumber + 1) * pageSize >= totalElements}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                <NavigateNextIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container direction="column" style={{ width: '49.5%' }}>
            <ResultsMap
              locs={data}
              currentLoc={currentLoc}
              selectedLoc={selectedLoc}
            />{' '}
          </Grid>
        </Grid>
      ) : noResults ? (
        <Grid container justify="center">
          <Typography variant="h4">No results found for {find}</Typography>
        </Grid>
      ) : (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      )}
    </Grid>
  );
}
