/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  IconButton,
  Typography,
  colors,
  Paper,
  useTheme,
  Dialog,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGridStyles } from '../../../data/DataGridStyles';
import { Ip } from '../../../data/ip';
export default function ViewMilestonesTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [user] = useState(localStorage.getItem('userId'));
  const [provider] = useState(props.providerid + '');

  function UpdateContractStatus(status) {
    const body = {
      status: status,
      contractid: `${props.contractid}`,
    };
    fetch(`${Ip}/contract`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
        console.log(response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  function UpdateMilestoneStatus(status, msId) {
    const body = [
      {
        completionstatus: status,
        milestoneid: `${msId}`,
      },
    ];
    console.log(JSON.stringify(body, null, 2));
    fetch(`${Ip}/milestone`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          window.location.reload();
        }
        console.log(response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  function ReleaseFunds(msId) {
    console.log(msId);
    fetch(`${Ip}/milestone/${msId}/payout`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: '',
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.reload();
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  function CustomToolbar() {
    return (
      <Grid
        container
        justify="flex-end"
        alignContent="center"
        style={{
          height: '50px',
          borderBottom: 'solid 2px #cfd9e2',
        }}
      >
        {provider === user ? (
          <Grid>
            <Button
              style={{ textTransform: 'none' }}
              onClick={() => UpdateContractStatus('Agreed')}
            >
              <Typography>Accept</Typography>
            </Button>

            <Button
              style={{ textTransform: 'none' }}
              onClick={() => UpdateContractStatus('RejectedByProvider')}
            >
              <Typography>Reject</Typography>
            </Button>
          </Grid>
        ) : (
          <Grid>
            <Button
              style={{ textTransform: 'none' }}
              onClick={() => props.editMs()}
              disabled={props.contractStatus === 'ProviderApprovalPending'}
            >
              <Typography>Edit milestones</Typography>
            </Button>

            <Button
              style={{ textTransform: 'none' }}
              onClick={() => UpdateContractStatus('ProviderApprovalPending')}
              disabled={props.contractStatus === 'ProviderApprovalPending'}
            >
              <Typography>Proceed for Approval from Provider</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      hide: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 320,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 320,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      sortable: false,
    },
    {
      field: 'paymentstatus',
      headerName: 'Payment Status',
      width: 170,
      sortable: false,
      hide: provider !== user,
    },
    {
      field: 'paymentpercentage',
      headerName: 'Payment Percentage',
      width: 200,
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
      sortable: false,
    },
    {
      field: 'start',
      headerName: ' ',
      width: 100,
      sortable: false,
      align: 'left',
      hide: provider !== user,
      renderCell: (params) => (
        <Button
          style={{ textTransform: 'none' }}
          disabled={
            props.funded === 'true'
              ? params.rowIndex === 0
                ? params.row.status == 'NotStarted' ||
                  params.row.status == 'Started'
                  ? false
                  : true
                : props.content[params.rowIndex - 1].status == 'NotStarted' ||
                  props.content[params.rowIndex - 1].status == 'Started' ||
                  props.content[params.rowIndex].status == 'Completed'
              : true
          }
          onClick={() => {
            UpdateMilestoneStatus(
              `${params.row.status === 'NotStarted' ? 'Started' : 'Completed'}`,
              params.row.id
            );
          }}
        >
          {params.row.status == 'NotStarted'
            ? 'Start'
            : params.row.status == 'Started'
            ? 'Done'
            : 'Completed'}
        </Button>
      ),
    },

    {
      field: 'Pay',
      headerName: ' ',
      width: 100,
      sortable: false,
      align: 'left',
      hide: provider === user,
      renderCell: (params) => (
        <Button
          onClick={() => {
            ReleaseFunds(params.row.id);
          }}
          style={{ textTransform: 'none' }}
          disabled={
            params.row.paymentstatus === 'Paid' ||
            params.row.status !== 'Completed'
          }
        >
          {/* {params.row.status === 'Completed' &&
          params.row.paymentstatus === 'Notpaid'
            ? 'Pay' */}
          {params.row.paymentstatus === 'Paid' ? 'Paid' : 'Pay'}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: con.milestoneid,
          title: con.title,
          description: con.description,
          status: con.completionstatus,
          paymentstatus: con.paymentstatus,
          paymentpercentage: `${con.paymentpercentage}%`,
          amount: `$ ${
            (Number(con.paymentpercentage) * Number(props.amount)) / 100
          }`,
        },
      ]);
    });
  }, []);

  return (
    console.log('FUNDED is', props.funded),
    (
      <Grid
        container
        style={{
          height:
            props.content.length * 52 +
            (props.contractStatus === 'Agreed' ? 80 : 130),
          width: '100%',
        }}
        className={classes.root}
      >
        <DataGrid
          autoHeight
          components={{
            header: props.contractStatus === 'Agreed' ? null : CustomToolbar,
          }}
          rows={rows}
          columns={columns}
          disableSelectionOnClick
          hideFooter
          onSelectionChange={(param) => {
            //     var req =
            //       param.rowIds.length > 0 &&
            //       param.rowIds[0].split(',').map((step) => step);
            //     console.log(param);
            //     param.rowIds.length === 1
            //       ? (setChat(false), setConsumerId(req[3]))
            //       : setChat(true);
            //     param.rowIds.length === 1 && req[2] !== 'BidAccepted'
            //       ? (setAccept(false), setAcceptId([req[0], req[1]]))
            //       : setAccept(true);
          }}
        />
      </Grid>
    )
  );
}
