// import React, { useEffect, useState } from 'react';
// import { Typography, Button, Grid, Box } from '@material-ui/core';
// import { Ip } from '../../data/ip';
// import BasicDetails from './BasicDetails';
// import ContactDetails from './ContactDetails';
// import ServicesOffered from './ServicesOffered';
// import axios from 'axios';
// import Card from './AddPaymentDetails/AddCard';

// export default function Profile() {
//   const [basicDetails, setBasicDetails] = useState(true);
//   const [contactDetails, setContactDetails] = useState(false);
//   const [servicesOffered, setServicesOffered] = useState(false);
//   const [addPaymentDetails, setAddPaymentDetails] = useState(false);
//   const [userDetails, setUserDetails] = useState('');
//   const [userServices, setUserServices] = useState('');
//   const [updates, setUpdates] = useState(false);
//   const buttonStyle = {
//     height: '44px',
//     outline: 0,
//     textTransform: 'none',
//     borderBottomRightRadius: '50px',
//     borderTopRightRadius: '50px',
//     justifyContent: 'flex-start',
//   };

//   useEffect(() => {
//     axios
//       .get(`${Ip}/servexuser`, {
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
//         },
//       })
//       .then((response) => {
//         console.log('reposne:', response);

//         setUserDetails(response.data);
//       });

//     axios
//       .get(`${Ip}/user/service`, {
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
//         },
//       })
//       .then((response) => {
//         setUserServices(response.data);
//       });
//   }, [updates]);

//   return (
//     <Grid container item xs={12}>
//       <Grid container item xs={2} direction="column">
//         <Box mt={2} mr={1}>
//           <Button
//             fullWidth
//             style={{
//               ...buttonStyle,
//               backgroundColor: basicDetails ? '#E8F0FE' : null,
//               color: basicDetails ? '#1967d2' : null,
//             }}
//             onClick={() => {
//               setBasicDetails(true);
//               setContactDetails(false);
//               setServicesOffered(false);
//               setAddPaymentDetails(false);
//             }}
//           >
//             <Typography>&nbsp;&nbsp;Basic Details</Typography>
//           </Button>
//           <Button
//             fullWidth
//             style={{
//               ...buttonStyle,
//               backgroundColor: contactDetails ? '#E8F0FE' : null,
//               color: contactDetails ? '#1967d2' : null,
//             }}
//             onClick={() => {
//               setContactDetails(true);
//               setBasicDetails(false);
//               setServicesOffered(false);
//               setAddPaymentDetails(false);
//             }}
//           >
//             <Typography>&nbsp;&nbsp;Contact Details</Typography>
//           </Button>
//           <Button
//             fullWidth
//             style={{
//               ...buttonStyle,
//               backgroundColor: servicesOffered ? '#E8F0FE' : null,
//               color: servicesOffered ? '#1967d2' : null,
//             }}
//             onClick={() => {
//               setServicesOffered(true);
//               setBasicDetails(false);
//               setContactDetails(false);
//               setAddPaymentDetails(false);
//             }}
//           >
//             <Typography>&nbsp;&nbsp;Services Offered</Typography>
//           </Button>
//           <Button
//             fullWidth
//             style={{
//               ...buttonStyle,
//               backgroundColor: addPaymentDetails ? '#E8F0FE' : null,
//               color: addPaymentDetails ? '#1967d2' : null,
//             }}
//             onClick={() => {
//               setAddPaymentDetails(true);
//               setBasicDetails(false);
//               setContactDetails(false);
//               setServicesOffered(false);
//             }}
//           >
//             <Typography>&nbsp;&nbsp;Add Payment Details</Typography>
//           </Button>
//         </Box>
//       </Grid>
//       <Grid container item xs={10} directon="column">
//         <Box m={2} style={{ width: '100%' }}>
//           {basicDetails && userDetails ? (
//             <Grid
//               container
//               direction="column"
//               justify="center"
//               alignItems="center"
//             >
//               <Typography variant="h3">Basic Details</Typography>
//               <Box height="15px" />
//               <BasicDetails
//                 fname={userDetails[0].fname}
//                 mname={userDetails[0].mname}
//                 lname={userDetails[0].lname}
//                 email={userDetails[0].email}
//                 jobTitle={userDetails[0].jobtitle}
//                 profilepic={userDetails[0].profilepic}
//               />
//             </Grid>
//           ) : null}

//           {contactDetails && userDetails && userServices ? (
//             userDetails[0].addresses.length !== 0 ? (
//               <Grid
//                 container
//                 direction="column"
//                 justify="center"
//                 alignItems="center"
//               >
//                 <Typography variant="h3">Contact Details</Typography>
//                 <Box height="15px" />
//                 <ContactDetails
//                   addresses={userDetails[0].addresses.length}
//                   addresstype={userDetails[0].addresses[0].addresstype}
//                   addressline1={userDetails[0].addresses[0].addressline1}
//                   addressline2={userDetails[0].addresses[0].addressline2}
//                   city={userDetails[0].addresses[0].city}
//                   state={userDetails[0].addresses[0].state}
//                   country={userDetails[0].addresses[0].country}
//                   zip={userDetails[0].addresses[0].zip}
//                   phone={userDetails[0].phones[0].phonenumber}
//                   services={userServices.length}
//                   update={() => setUpdates(!updates)}
//                 />
//               </Grid>
//             ) : (
//               <Grid
//                 container
//                 direction="column"
//                 justify="center"
//                 alignItems="center"
//               >
//                 <Typography variant="h3">Contact Details</Typography>
//                 <ContactDetails
//                   addresses={0}
//                   update={() => setUpdates(!updates)}
//                 />
//               </Grid>
//             )
//           ) : null}

//           {servicesOffered && userServices && userDetails ? (
//             <Grid
//               container
//               direction="column"
//               justify="center"
//               alignItems="center"
//             >
//               <Typography variant="h3">Services Offered</Typography>
//               <Box height="15px" />
//               <ServicesOffered
//                 services={userServices}
//                 jobTitle={userDetails[0].jobtitle}
//                 update={() => setUpdates(!updates)}
//               />
//             </Grid>
//           ) : null}

//           {addPaymentDetails && userDetails ? (
//             <Grid
//               container
//               item
//               xs={12}
//               direction="column"
//               justify="center"
//               alignItems="center"
//             >
//               <Typography variant="h3">Add Payment Details</Typography>
//               <Box height="15px" />

//               <Card
//                 custId={userDetails[0].stripecustomerid}
//                 intent={userDetails[0].stripesetupintentid}
//                 fname={userDetails[0].fname}
//                 mname={userDetails[0].mname}
//                 lname={userDetails[0].lname}
//                 update={() => setUpdates(!updates)}
//               />
//             </Grid>
//           ) : null}
//         </Box>
//       </Grid>
//     </Grid>
//   );
// }

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Box,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { Ip } from '../../data/ip';
import axios from 'axios';
import defaultPic from '../../assets/Images/profile-cion.svg';
import editIcon from '../../assets/Images/editIcon.svg';
import { ReactComponent as profileIcon } from '../../assets/Images/profile-icon-3.svg';
import { ReactComponent as myServices } from '../../assets/Images/myservices.svg';
import { ReactComponent as bankAccount } from '../../assets/Images/transactions.svg';
import { ReactComponent as settings } from '../../assets/Images/settings.svg';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import ProfileDetails from './ProfileDetails';
import ServiceDetails from './ServiceDetails';
import BankAccountDetails from './BankAccountDetails';

export default function Profile() {
  const [changedProfilePic, setChangedProfilePic] = useState('');
  const [userDetails, setUserDetails] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ profilePage: true });
  const [services, setServices] = useState('');
  const [loadingServices, setLoadingServices] = useState(false);
  const [changeInAddress, setChangeInAddress] = useState(false);
  const [servicesChanged, setServicesChanged] = useState(false);

  useEffect(() => {
    (changeInAddress || !userDetails) &&
      axios
        .get(`${Ip}/servexuser`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        })
        .then((response) => {
          console.log('reposne:', response);
          setUserDetails(response.data);
          setLoading(false);
          setChangeInAddress(false);
        })
        .catch((error) => {
          console.error(error.message);
          alert(`ERR: ${error}`);
        });
  }, [changeInAddress]);

  useEffect(() => {
    !services &&
      page.myServicesPage &&
      axios
        .get(`${Ip}/user/service`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        })
        .then((response) => {
          setServices(response.data);
          setLoadingServices(false);
          setServicesChanged(false);
          console.log(response);
        })
        .catch((error) => {
          console.error(error.message);
          alert(`ERR: ${error}`);
        });
    services && setLoadingServices(false);
  }, [page.myServicesPage, services]);

  // useEffect(() => {
  //   axios
  //         .get(`${Ip}/user/service`, {
  //           headers: {
  //             Accept: 'application/json',
  //             'Content-Type': 'application/json',
  //             Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
  //           },
  //         })
  //         .then((response) => {
  //           setUserServices(response.data);
  //         });
  // }, [serviceUpdates])

  async function handleUpload(event) {
    const targetFile = event.target.files[0] || null;
    var str = targetFile
      ? await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(targetFile);
          reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
          reader.onerror = (error) => reject(error);
        })
      : null;
    targetFile
      ? (setProfilePic(str),
        setChangedProfilePic(URL.createObjectURL(targetFile)))
      : null;
  }

  function uploadProfilePic() {
    setUploading(true);
    const body = {
      profilepic: profilePic,
    };
    console.log(body);
    fetch(`${Ip}/user`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('ProfilePic', profilePic);
          setUploading(false);
          setProfilePic('');
        }
        console.log(response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  return (
    <Grid style={{ width: '100%' }} key={services}>
      <CircularProgressIndicator
        open={loading || uploading || loadingServices}
      />
      {!loading && (
        <Grid container direction="column">
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ margin: '25px 0 19px 0' }}
          >
            <Typography
              onClick={() => (window.location.href = '/')}
              style={{ fontSize: 18, fontWeight: 500, cursor: 'pointer' }}
            >
              Dashboard /
            </Typography>
            <Typography
              style={{
                fontSize: 18,
                fontWeight: 500,
                color: '#8692a6',
                whiteSpace: 'pre',
              }}
            >
              {' '}
              Profile
            </Typography>
          </Grid>
          <Grid container direction="row" justify="space-between">
            <Grid
              container
              justify="center"
              style={{
                width: '21.1%',
                backgroundColor: '#ffffff',
                height: '100%',
              }}
            >
              <IconButton
                component="label"
                style={{
                  position: 'absolute',
                  top: 230,
                  left: '17%',
                  backgroundColor: 'transparent',
                }}
              >
                <img src={editIcon} />
                <input
                  id="file"
                  type="file"
                  accept="image/x-png,image/png,image/jpeg"
                  onChange={handleUpload}
                  hidden
                />
              </IconButton>
              <Box
                style={{
                  borderRadius: '50%',
                  width: '150px',
                  height: '150px',
                  border: 'solid 2px #f5802c',
                }}
                m="20px 0px 15px 0"
              >
                <img
                  src={
                    changedProfilePic
                      ? changedProfilePic
                      : userDetails[0].profilepic
                      ? `data:;base64,${userDetails[0].profilepic}`
                      : defaultPic
                  }
                  style={{
                    margin: '6%',
                    width: '88%',
                    height: '88%',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              {profilePic && (
                <Grid container direction="row" justify="center">
                  <Button
                    style={{ textTransform: 'none', color: '#1565d8' }}
                    onClick={() => {
                      setChangedProfilePic(''), setProfilePic('');
                    }}
                  >
                    cancel
                  </Button>
                  <Button
                    style={{ textTransform: 'none', color: '#1565d8' }}
                    onClick={uploadProfilePic}
                  >
                    Done
                  </Button>
                </Grid>
              )}
              <Typography
                align="center"
                style={{
                  fontSize: 18,
                  fontWeight: 500,
                  textTransform: 'capitalize',
                  marginBottom: 20,
                  width: '100%',
                }}
              >{`${userDetails[0].fname} ${userDetails[0].mname} ${userDetails[0].lname}`}</Typography>
              <Buttons
                src={profileIcon}
                name="Profile"
                focus={page.profilePage}
                clicked={() => {
                  setPage({
                    profilePage: true,
                  });
                  servicesChanged && setServices('');
                }}
              />

              <Buttons
                src={myServices}
                name="My Services"
                focus={page.myServicesPage}
                clicked={() => {
                  setPage({ myServicesPage: true });
                  setLoadingServices(true);
                }}
              />
              {localStorage.getItem('UserType') === 'SERVICE_PROVIDER' && (
                <Buttons
                  src={bankAccount}
                  name="BankAccount"
                  focus={page.bankAccountPage}
                  clicked={() => {
                    setPage({ bankAccountPage: true });
                    servicesChanged && setServices('');
                  }}
                />
              )}
              <Buttons
                src={settings}
                name="Settings"
                focus={page.settingsPage}
                clicked={() => {
                  setPage({ settingsPage: true });
                  servicesChanged && setServices('');
                }}
              />
            </Grid>

            <Grid container direction="row" style={{ width: '77%' }}>
              {page.profilePage &&
                (userDetails[0].addresses.length === 0 ? (
                  <ProfileDetails
                    fname={userDetails[0].fname}
                    mname={userDetails[0].mname}
                    lname={userDetails[0].lname}
                    email={userDetails[0].email}
                    phoneId={userDetails[0].phones[0].phoneid}
                    verifyPhone={userDetails[0].phones[0].verificationstatus}
                    mobileNumber={userDetails[0].phones[0].phonenumber}
                    addressLength={userDetails[0].addresses.length}
                    refresh={() => (setLoading(true), setChangeInAddress(true))}
                  />
                ) : (
                  <ProfileDetails
                    fname={userDetails[0].fname}
                    mname={userDetails[0].mname}
                    lname={userDetails[0].lname}
                    email={userDetails[0].email}
                    phoneId={userDetails[0].phones[0].phoneid}
                    isPhoneVerified={
                      userDetails[0].phones[0].verificationstatus === 'Verified'
                    }
                    mobileNumber={userDetails[0].phones[0].phonenumber}
                    addressLength={userDetails[0].addresses.length}
                    addresstype={userDetails[0].addresses[0].addresstype}
                    addressline1={userDetails[0].addresses[0].addressline1}
                    addressline2={userDetails[0].addresses[0].addressline2}
                    city={userDetails[0].addresses[0].city}
                    state={userDetails[0].addresses[0].state}
                    country={userDetails[0].addresses[0].country}
                    zip={userDetails[0].addresses[0].zip}
                    refresh={() => setChangeInAddress(true)}
                    load={() => setLoading(true)}
                  />
                ))}
              {page.myServicesPage && (
                <ServiceDetails
                  services={services}
                  servicesChanged={() => setServicesChanged(true)}
                  update={() => {
                    setServicesChanged(true),
                      setServices(''),
                      setLoadingServices(true);
                  }}
                />
              )}

              {page.bankAccountPage && <BankAccountDetails />}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

function Buttons(props) {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Button
        fullWidth
        style={{
          textTransform: 'none',
          justifyContent: 'flex-start',
          height: 58,
          backgroundColor: props.focus ? 'rgba(245, 128, 44, 0.1)' : '#ffffff',
          borderBottom: props.focus ? '1px solid #f5802c' : '1px solid #cfd9e2',
          borderRadius: 0,
        }}
        onClick={props.clicked}
      >
        <props.src
          stroke={props.focus ? '#f5802c' : '#8692A6'}
          style={{ margin: '0 10px 0 20px' }}
        />
        <Typography
          style={{
            fontWeight: 500,
            color: props.focus ? '#f5802c' : '#8692a6',
          }}
        >
          {props.name}
        </Typography>
      </Button>
    </Grid>
  );
}
