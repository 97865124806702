/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  IconButton,
  Typography,
  useTheme,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { DataGridStyles } from '../../data/DataGridStyles';

export default function ContractsTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);
  const classes = useStyles();
  let history = useHistory();

  const [rows, setRows] = useState([]);

  // function CustomToolbar() {
  //   return (
  //     <Grid container justify="flex-end" alignContent="center">
  //       <Button
  //         style={{ textTransform: 'none' }}
  //         href={`/createmilestones/${contractid}`}
  //         disabled={createMS}
  //       >
  //         <Typography>Create Milestones</Typography>
  //       </Button>
  //       <Button
  //         onClick={() => console.log(`/viewmilestones/${contractid}`)}
  //         style={{ textTransform: 'none' }}
  //         href={`/viewmilestones/${contractid}`}
  //         disabled={viewMS}
  //       >
  //         <Typography>View Milestones</Typography>
  //       </Button>
  //     </Grid>
  //   );
  // }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortDirection: 'desc',
      renderCell: (params) => (
        <Link>
          <Typography style={{ color: 'blue', cursor: 'pointer' }}>
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 180,
      sortable: false,
    },

    {
      field: 'consumer',
      headerName: 'Consumer',
      width: 180,
      sortable: false,
      hide: props.filter === 'consumer',
    },
    {
      field: 'provider',
      headerName: 'Provider',
      width: 180,
      sortable: false,
      hide: props.filter === 'provider',
    },
    {
      field: 'contractype',
      headerName: 'Contract Type',
      width: 150,
      sortable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 130,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 130,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 130,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      sortable: false,
    },
    {
      field: 'funds',
      headerName: 'Funds',
      width: 130,
      sortable: false,
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: con.contractid,
          title: con.title,
          consumer: `${con.consumerfname} ${con.consumermname} ${con.consumerlname}`,
          provider: `${con.providerfname} ${con.providermname} ${con.providerlname}`,
          contractype: con.contractype,
          amount: `$ ${con.amount}`,
          startDate: new Date(con.createdat).toLocaleDateString('en-US'),
          dueDate: con.serviceabletime
            ? new Date(con.serviceabletime).toLocaleDateString('en-US')
            : 'ASAP',
          status: con.status,
          funds: con.funded == 'true' ? 'Fully funded' : 'Not funded',
        },
      ]);
    });
  }, []);

  return (
    <Grid
      container
      style={{ height: props.content.length * 52 + 66, width: '100%' }}
      className={classes.root}
    >
      <DataGrid
        autoHeight
        // components={{
        //   header: CustomToolbar,
        // }}
        rows={rows}
        columns={columns}
        hideFooter
        // showToolbar
        onSelectionChange={(param) => {
          console.log(param);
          history.push(`/opencontract/${param.rowIds[0]}`);
        }}
      />
    </Grid>
  );
}
