import React, { useState, useEffect } from 'react';
import { Grid, Dialog, Box } from '@material-ui/core';
import axios from 'axios';
import { Ip } from '../../data/ip';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ShowAttachments(props) {
  const [content, setContent] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log('service request id', props.servicerequestid);
    axios
      .get(
        `${Ip}/servicerequest/${props.servicerequestid}/attachment/${props.attachmentid}`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setLoading(false), setContent(response.data[0].content);
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }, []);
  return (
    <Grid key={content}>
      <Dialog open={props.open} onClose={props.close}>
        {loading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: '800px', width: '600px' }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <object
            style={{ height: '800px', width: '600px' }}
            data={`data:${props.type};base64,${content}`}
            type={props.type}
          />
        )}
      </Dialog>
    </Grid>
  );
}
