import React, { Component } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import authImage from '../../../assets/Images/authentication_img.svg';

class ConfirmDetails extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
        }}
      >
        <img src={authImage} />
        <br />
        <Typography
          align="center"
          style={{
            fontSize: '24px',
            fontWeight: 500,
            paddingBottom: '11px',
          }}
        >
          Confirm Details
        </Typography>
        <Typography
          align="center"
          style={{
            fontSize: '14px',
            wordBreak: 'inherit',
            width: '40%',
            paddingBottom: '20px',
            color: '#8692a6',
          }}
        >
          You will receive a OTP to your mobile{' '}
          <span
            style={{
              fontWeight: 600,
            }}
          >
            {this.props.phonenumber}
          </span>{' '}
          and a confirmation link to your email{' '}
          <span
            style={{
              fontWeight: 600,
            }}
          >
            {this.props.email}
          </span>{' '}
          <span
            style={{
              color: '#1565d8',
              cursor: 'pointer',
              fontWeight: 600,
              textDecoration: 'underline',
            }}
            onClick={() => this.props.clicked()}
          >
            EDIT
          </span>
        </Typography>
        <Button
          variant="contained"
          size="large"
          style={{
            textTransform: 'none',
            position: 'relative',
            backgroundColor: '#f5802c',
            color: '#FFFFFF',
            width: '40%',
          }}
          onClick={() => {
            this.props.confirm();
          }}
        >
          Send
        </Button>
      </Grid>
    );
  }
}

export default ConfirmDetails;
