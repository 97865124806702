import { Typography, Grid, Button } from '@material-ui/core';
import React from 'react';

function verificationPage() {
  return (
    <Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ width: '100vw', height: '80vh' }}
      >
        <Typography variant="h5">
          Your Mobile Number has been Verified Successfully.
        </Typography>
        <Button
          variant="outlined"
          style={{ background: '#f5802c', color: 'white', marginTop: '30px' }}
        >
          Proceed
        </Button>
      </Grid>
    </Grid>
  );
}

export default verificationPage;
