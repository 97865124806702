import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Typography,
  Button,
  Grid,
  Box,
  Dialog,
  IconButton,
  TextField,
  Link,
  AppBar,
  MenuItem,
  Checkbox,
} from '@material-ui/core';

const PaymentCancelled = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ width: '100%', height: '100%' }}
    >
      <Grid
        container
        direction="row"
        justify="center"
        style={{ width: '100%', marginTop: '5%' }}
      >
        <CancelIcon style={{ fontSize: 200, color: '#f5802c' }} />
      </Grid>
      <Grid>
        <Typography align="center" variant="h3" style={{ marginTop: '20px' }}>
          Your Payment was UnSuccessful
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          style={{ marginTop: '20px' }}
        >
          Caption Text goes this way...
        </Typography>
      </Grid>
      <Grid>
        <Button
          variant="contained"
          style={{
            marginTop: '20px',
            backgroundColor: '#f5802c',
            color: '#FFFFFF',
            paddingLeft: '50px',
            paddingRight: '50px',
          }}
        >
          <b>Retry Payment</b>
        </Button>
      </Grid>
    </Grid>
  );
};

export default PaymentCancelled;
