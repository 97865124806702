import { USER_SIGNED_IN } from '../constants/actions';

const initialState = {
  userSignedIn: false,
};

function rootReducer(state = initialState, action) {
  if (action.type === USER_SIGNED_IN) {
    return { ...state, userSignedIn: action.payload };
  }
  return state;
}

export default rootReducer;
