import React, { Component } from 'react';
import {
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  Paper,
} from '@material-ui/core';
import Info from './Info';
import tick from '../../assets/Images/tixk-circle.svg';

class RegistrationCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = { otp: '' };
  }
  handleChange = (otp) => this.setState({ otp });

  render() {
    return (
      <Paper>
        <Info />
        <Grid
          container
          direction="column"
          style={{
            position: 'absolute',
            height: '100%',
            width: '70%',
            transform: 'translateX(+42%)',
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              height: '100%',
            }}
          >
            <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>
              Your account has been created successfully! <img src={tick} />
            </Typography>
            <Button
              variant="contained"
              style={{
                width: '200px',
                textTransform: 'none',
                position: 'relative',
                top: 20,
                backgroundColor: '#f5802c',
                color: '#FFFFFF',
                padding: '12px',
              }}
              href="/"
            >
              <Typography>Go To Home</Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default RegistrationCompleted;
