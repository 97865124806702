import React, { Component } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Button,
  Link,
  Paper,
  withStyles,
} from '@material-ui/core';
import { Ip } from '../data/ip';
import { connect } from 'react-redux';
import axios from 'axios';
import validator from 'validator';
import { userSignedIn } from '../redux/actions';
import ForgotPassword from './ForgotPassword';
import Info from './Registration/Info';
import CircularProgressIndicator from '../components/CircularProgressIndicator';
import GoHome from '../components/GoHome';

const useStyles = (theme) => ({
  remember: {
    [theme.breakpoints.up('1293')]: {
      transform: 'translateX(-6%)',
    },
  },
});

function mapDispatchToProps(dispatch) {
  return {
    signInUser: (signedIn) => dispatch(userSignedIn(signedIn)),
  };
}

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      invalidEmail: false,
      invalidPassword: false,
      notVerified: false,
      progressIndicator: false,
      forgot: false,
    };

    this.email = this.email.bind(this);
    this.password = this.password.bind(this);
    this.login = this.login.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }

  email(event) {
    this.setState({ email: event.target.value, invalidEmail: false });
  }
  password(event) {
    this.setState({ password: event.target.value, invalidPassword: false });
  }

  login() {
    console.log('Ip is', Ip);
    fetch(`${Ip}/authenticate`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: this.state.email,
        password: this.state.password,
      }),
    })
      .then((Response) => Response.json())
      .then((result) => {
        console.log('result is: ', result);
        if (result.message === null) {
          this.setState({
            progressIndicator: false,
            invalidEmail: true,
          });
        } else if (result.message === 'INVALID_CREDENTIALS') {
          this.setState({
            progressIndicator: false,
            invalidPassword: true,
          });
        } else if (result.message === 'USER_DISABLED') {
          this.setState({ progressIndicator: false, notVerified: true });
        } else {
          this.redirectTo(result.token);
        }
      });
  }

  redirectTo(token) {
    axios
      .get(`${Ip}/servexuser`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        let res = response.data[0];
        localStorage.setItem('ResetToken', token);
        localStorage.setItem('loginTime', new Date().getTime());
        res.userType && localStorage.setItem('UserType', res.userType);
        res.jobtitle && localStorage.setItem('JobTitle', res.jobtitle);
        res.preferredpaymentreceiptmethod &&
          localStorage.setItem(
            'PaymentMethod',
            res.preferredpaymentreceiptmethod
          );
        localStorage.setItem('userId', res.userid);
        res.profilepic && localStorage.setItem('ProfilePic', res.profilepic);
        localStorage.setItem(
          'userName',
          JSON.stringify({
            fname: res.fname,
            mname: res.mname ? res.mname : '',
            lname: res.lname,
          })
        );
        if (res.addresses.length > 0)
          localStorage.setItem(
            'location',
            JSON.stringify({
              latitude: res.addresses[0].latitude,
              longitude: res.addresses[0].longitude,
              name: res.addresses[0].addressline1,
            })
          );
        if (res.addresses.length === 0) {
          window.location.href = '/register2';
        } else if (
          localStorage.getItem('createServiceRequestBySearchingProviders')
        ) {
          window.location.href = `/requestprovider/${localStorage.getItem(
            'createServiceRequestBySearchingProviders'
          )}`;
        } else {
          // Set signed in redux
          this.props.signInUser(true);
          // this.props.history.push('/');
          window.location.href = '/';
        }
      });
  }

  render() {
    const { classes } = this.props;
    return localStorage.getItem('ResetToken') ? (
      <GoHome message="Already Logged in" />
    ) : (
      <Paper>
        <CircularProgressIndicator open={this.state.progressIndicator} />
        <Info />
        <Grid
          container
          direction="column"
          style={{
            position: 'absolute',
            height: '100%',
            width: '70%',
            transform: 'translateX(+42%)',
          }}
        >
          {this.state.forgot ? (
            <ForgotPassword
              email={this.state.email}
              close={() => this.setState({ forgot: false })}
            />
          ) : (
            <Grid>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{
                  position: 'relative',
                  top: '40px',
                  right: '50px',
                }}
              >
                <Typography style={{ color: '#8692a6', fontSize: 16 }}>
                  {`Don't have an account?`}&nbsp;
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#1565d8',
                      fontWeight: 600,
                    }}
                    href="/register1"
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                alignContent="flex-start"
                style={{
                  position: 'fixed',
                  top: '40%',
                  left: '30%',
                  width: '70%',
                }}
              >
                <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>
                  Sign In!
                </Typography>
                <Typography variant="body1" style={{ margin: '20px 0 5px 0' }}>
                  Email id
                </Typography>
                <TextField
                  required
                  type="email"
                  placeholder="Enter email id"
                  value={this.state.email}
                  variant="outlined"
                  size="small"
                  style={{ width: '57%' }}
                  error={
                    this.state.invalidEmail || this.state.notVerified
                      ? true
                      : false
                  }
                  helperText={
                    this.state.invalidEmail
                      ? 'Enter a valid email'
                      : this.state.notVerified
                      ? 'Please verify your account through the link sent to your mail'
                      : null
                  }
                  onChange={this.email}
                  onKeyPress={(event) => {
                    var code;
                    if (event.key !== undefined) {
                      code = event.key;
                    } else if (event.keyIdentifier !== undefined) {
                      code = event.keyIdentifier;
                    } else if (event.keyCode !== undefined) {
                      code = event.keyCode;
                    }
                    if (code === 13 || code === 'Enter') {
                      this.state.email && this.state.password
                        ? (this.login(),
                          this.setState({ progressIndicator: true }))
                        : null;
                    }
                  }}
                />
                <Typography variant="body1" style={{ margin: '15px 0 5px 0' }}>
                  Password
                </Typography>

                <TextField
                  required
                  type="password"
                  variant="outlined"
                  placeholder="Enter password"
                  value={this.state.password}
                  size="small"
                  style={{ width: '57%' }}
                  error={this.state.invalidPassword ? true : false}
                  helperText={
                    this.state.invalidPassword ? 'Enter a valid Password' : null
                  }
                  onChange={this.password}
                  onKeyPress={(event) => {
                    var code;
                    if (event.key !== undefined) {
                      code = event.key;
                    } else if (event.keyIdentifier !== undefined) {
                      code = event.keyIdentifier;
                    } else if (event.keyCode !== undefined) {
                      code = event.keyCode;
                    }
                    if (code === 13 || code === 'Enter') {
                      this.state.email && this.state.password
                        ? (this.login(),
                          this.setState({ progressIndicator: true }))
                        : null;
                    }
                  }}
                />

                <Typography
                  style={{
                    position: 'relative',
                    color: '#1565d8',
                    cursor: 'pointer',
                    marginTop: '15px',
                    width: '57%',
                  }}
                  align="right"
                  onClick={() => {
                    this.setState({ forgot: true });
                  }}
                >
                  Forgot Password?
                </Typography>
                <Button
                  onClick={() => {
                    (!this.state.email ||
                      !validator.isEmail(this.state.email)) &&
                      this.setState({ invalidEmail: true });
                    (!this.state.password || this.state.password.length < 8) &&
                      this.setState({ invalidPassword: true });
                    this.state.email &&
                    !this.state.invalidEmail &&
                    this.state.password &&
                    this.state.password.length >= 8 &&
                    !this.state.invalidPassword
                      ? (this.login(),
                        this.setState({ progressIndicator: true }))
                      : null;
                  }}
                  variant="contained"
                  size="large"
                  style={{
                    textTransform: 'none',
                    marginTop: 15,
                    backgroundColor: '#f5802c',
                    color: '#FFFFFF',
                  }}
                >
                  <Typography style={{ fontSize: 16 }}>Login</Typography>
                </Button>
                <Button
                  href="/register1"
                  variant="contained"
                  size="large"
                  style={{
                    textTransform: 'none',
                    margin: '15px 0 30px 0',
                    backgroundColor: '#f5802c',
                    color: '#FFFFFF',
                  }}
                >
                  <Typography style={{ fontSize: 16 }}>Sign up</Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}
export default connect(null, mapDispatchToProps)(withStyles(useStyles)(Login));
// export default connect(null, mapDispatchToProps)(Login);
