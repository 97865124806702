import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  Card,
  Button,
  Select,
  Input,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { useParams } from 'react-router';
import { Ip } from '../../../data/ip';
import axios from 'axios';
import OfferedBidsTable from './OfferedBidsTable';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';

export default function Bids() {
  let { filterid } = useParams();
  let Ids = filterid.split(',').map((step) => step);
  let serviceRequestId = Ids[0];
  let title = Ids[1];

  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [acceptId, setAcceptId] = useState([]);
  const [totalElements, setTotalElements] = useState();
  const [refresh, setRefresh] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setData([]);
    setLoad(true);
    axios
      .get(
        `${Ip}/servicerequestoffer?pageSize=${pageSize}&pageNumber=${pageNumber}${
          filterid !== 'null' ? `&servicerequestid=${serviceRequestId}` : ''
        }&sortField=created_at&sortDirection=DESC`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        }
      )
      .then((response) => {
        console.log(response);

        if (response.status === 200) {
          if (response.data.totalelements === 0 || response.data === '') {
            setCreate(true);
            setLoad(false);
          } else {
            setData(response.data.servicerequestoffers);
            setTotalElements(response.data.totalelements);
            setLoad(false);
          }
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }, [pageSize, pageNumber, refresh]);

  function handleAccept() {
    setLoad(true);
    const body = {
      servicerequestofferid: acceptId[0],
      status: 'Accepted',
      servicerequest: {
        servicerequestid: acceptId[1],
      },
    };
    console.log(JSON.stringify(body, null, 2));
    fetch(`${Ip}/servicerequestoffer`, {
      method: 'put',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          setRefresh(!refresh);
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      key={data.length}
    >
      <CircularProgressIndicator open={load} />
      {data.length > 0 ? (
        <Typography align="center" style={{ marginTop: 20, fontSize: 40 }}>
          Offered Bids
        </Typography>
      ) : (
        create && (
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            {title ? (
              <Typography variant="h3">
                No one offered a bid for this request!
              </Typography>
            ) : (
              <Typography variant="h3">
                {`You don't have any bids at the moment!`}
              </Typography>
            )}
          </Grid>
        )
      )}
      {data.length > 0 && (
        <OfferedBidsTable
          content={data}
          accept={(id) => setAcceptId(id)}
          clicked={handleAccept}
          title={title}
          filter={filterid}
        />
      )}

      {data.length > 0 && (
        <Grid container directoin="row" justify="flex-end" alignItems="center">
          <Typography>rows per page&nbsp;&nbsp;</Typography>
          <Select
            name="RowsPerPage"
            id="rowsperpage"
            input={<Input disableUnderline />}
            value={pageSize}
            onChange={(event) => {
              setPageSize(event.target.value);
              setPageNumber(0);
            }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem
              value={10}
              disabled={(pageNumber + 1) * 5 >= totalElements}
            >
              10
            </MenuItem>
            <MenuItem
              value={15}
              disabled={(pageNumber + 1) * 10 >= totalElements}
            >
              15
            </MenuItem>
            <MenuItem
              value={20}
              disabled={(pageNumber + 1) * 15 >= totalElements}
            >
              20
            </MenuItem>
          </Select>
          <IconButton
            disabled={pageNumber === 0}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            disabled={(pageNumber + 1) * pageSize >= totalElements}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}
