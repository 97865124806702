import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';

export default function DisplayMessages(props) {
  return (
    <Grid
      style={{
        width: '100%',
        height: '350px',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Grid
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right:
            '-17px' /* Increase/Decrease this value for cross-browser compatibility */,
          overflowY: 'scroll',
        }}
      >
        {props.messages.map((msg, i) => (
          <Box pt={1} pr={2} pl={2} key={i}>
            {i === 0 ||
            new Date(msg.timestamp).toLocaleDateString('en-US') !==
              new Date(
                props.messages[i - 1 >= 0 ? i - 1 : i].timestamp
              ).toLocaleDateString('en-US') ? (
              <Grid
                container
                direction="row"
                alignContent="center"
                justify="center"
                style={{ paddingBottom: 4 }}
              >
                <Typography
                  style={{
                    fontSize: 11,
                    backgroundColor: '#607D8B',
                    borderRadius: 25,
                    padding: '4px 8px',
                    color: '#ffffff',
                  }}
                >
                  {new Date(msg.timestamp).toLocaleDateString('en-US') ===
                  new Date().toLocaleDateString('en-US')
                    ? 'Today'
                    : new Date(msg.timestamp).toLocaleDateString('en-US')}
                </Typography>
              </Grid>
            ) : null}
            <Grid
              container
              direction="row"
              justify={
                msg.senderId === props.userId ? 'flex-end' : 'flex-start'
              }
              alignItems="flex-end"
            >
              <Grid
                Container
                style={{
                  borderRadius: 20,
                  padding: '5px 10px 5px 10px',
                  color: msg.senderId === props.userId ? '#ffffff' : '#000000',
                  backgroundColor:
                    msg.senderId === props.userId ? '#2196F3' : '#E0E0E0',

                  maxWidth: '85%',
                }}
              >
                <Typography
                  style={{
                    fontSize: 12.5,
                    overflowWrap: 'break-word',
                  }}
                >
                  {msg.content}
                </Typography>
                <Typography align="right" style={{ fontSize: 9 }}>
                  {new Date().toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }) ===
                  new Date(msg.timestamp).toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })
                    ? 'now'
                    : new Date(msg.timestamp).toLocaleString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}
                </Typography>
              </Grid>
            </Grid>

            <div ref={props.messagesEndRef} />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
