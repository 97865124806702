import React, { Component, useEffect } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Button,
  CardMedia,
  Box,
  Paper,
  Link,
  withStyles,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import { Ip } from '../../../data/ip';
import validator from 'validator';
import Info from '../Info';
import defaultPic from '../../../assets/Images/profile-cion.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';
import GoHome from '../../../components/GoHome';

class Register2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addresstype: '',
      addressline1: '',
      addressline2: '',
      city: '',
      stateName: '',
      country: '',
      zip: '',
      latitude: '',
      longitude: '',
      profilePic: '',
      downloadedProfilePic: localStorage.getItem('ProfilePic'),
      changedProfilePic: '',

      userType: '',
      progressIndicator: false,

      validAddresstype: true,
      validAddressline1: true,
      validCity: true,
      validStateName: true,
      validCountry: true,
      validUserType: true,
      validZip: true,
    };
    this.addresstype = this.addresstype.bind(this);
    this.addressline1 = this.addressline1.bind(this);
    this.addressline2 = this.addressline2.bind(this);
    this.city = this.city.bind(this);
    this.stateName = this.stateName.bind(this);
    this.country = this.country.bind(this);
    this.zip = this.zip.bind(this);
    this.userType = this.userType.bind(this);
    this.register = this.register.bind(this);
    this.geoCoding = this.geoCoding.bind(this);
    this.registerUsertypeandProfilePic = this.registerUsertypeandProfilePic.bind(
      this
    );
    this.handleUpload = this.handleUpload.bind(this);
  }
  componentDidMount() {
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position),
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      console.log('current loc', this.state.latitude, this.state.longitude);
    });
  }

  addresstype(event) {
    this.setState({ addresstype: event.target.value, validAddresstype: true });
  }
  addressline1(event) {
    this.setState({
      addressline1: event.target.value,
      validAddressline1: true,
    });
  }
  addressline2(event) {
    this.setState({ addressline2: event.target.value });
  }
  city(event) {
    this.setState({ city: event.target.value, validCity: true });
  }
  stateName(event) {
    this.setState({ stateName: event.target.value, validStateName: true });
  }
  country(event) {
    this.setState({ country: event.target.value, validCountry: true });
  }
  zip(event) {
    !validator.isEmpty(event.target.value) &&
    validator.isNumeric(event.target.value)
      ? this.setState({ zip: event.target.value, validZip: true })
      : this.setState({ zip: event.target.value, validZip: false });
  }
  userType(event) {
    this.setState({ userType: event.target.value, validUserType: true });
  }

  geoCoding() {
    this.setState({ progressIndicator: true });
    this.state.latitude && this.state.longitude
      ? this.register()
      : fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.addressline1},${this.state.addressline2},${this.state.city},${this.state.stateName},${this.state.country},${this.state.zip},+CA&key=AIzaSyDWXB7pLY_ubdVDvG1vvD9oNshbUQ9qn80`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log('geo data:', data);
            if (data.status === 'OK') {
              this.setState({
                latitude: data.results[0].geometry.location.lat,
                longitude: data.results[0].geometry.location.lng,
              });
              console.log('geo loc', this.state.latitude, this.state.longitude);
            }
          })
          .then(this.register)
          .catch((err) => {
            alert(`ERR: ${err}`);
            console.log(err);
          });
  }
  register() {
    const body = [
      {
        addresstype: this.state.addresstype,
        addressline1: this.state.addressline1,
        addressline2: this.state.addressline2,
        city: this.state.city,
        state: this.state.stateName,
        zip: this.state.zip,
        country: this.state.country,
        latitude: String(this.state.latitude),
        longitude: String(this.state.longitude),
      },
    ];
    console.log(body);
    fetch(`${Ip}/user/address`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((Response) => {
        if (Response.status === 200) {
          this.registerUsertypeandProfilePic();
          localStorage.setItem(
            'location',
            JSON.stringify({
              latitude: String(this.state.latitude),
              longitude: String(this.state.longitude),
              name: this.state.addressline1,
            })
          );
        }
        console.log(Response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }
  registerUsertypeandProfilePic() {
    const body = {
      usertype: this.state.userType,
      profilepic: this.state.profilePic ? this.state.profilePic : null,
    };
    console.log(body);
    fetch(`${Ip}/user`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 200) {
          this.state.userType === 'SERVICE_PROVIDER'
            ? this.props.history.push('/register3')
            : localStorage.getItem('createServiceRequestBySearchingProviders')
            ? this.props.history.push(
                `/requestprovider/${localStorage.getItem(
                  'createServiceRequestBySearchingProviders'
                )}`
              )
            : this.props.history.push('/registrationcompleted');
        }
        console.log(response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  async handleUpload(event) {
    const targetFile = event.target.files[0] || null;
    var str = targetFile
      ? await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(targetFile);
          reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
          reader.onerror = (error) => reject(error);
        })
      : null;
    targetFile &&
      this.setState({
        profilePic: str,
        changedProfilePic: URL.createObjectURL(targetFile),
      });
  }
  render() {
    const { classes } = this.props;
    return localStorage.getItem('location') ? (
      <GoHome message="Registration is completed" />
    ) : (
      <Paper>
        <CircularProgressIndicator open={this.state.progressIndicator} />
        <Info />
        <Grid
          container
          direction="column"
          style={{
            position: 'absolute',
            height: '100%',
            width: '70%',
            transform: 'translateX(+42%)',
          }}
        >
          <Grid
            container
            direction="column"
            alignContent="flex-start"
            style={{
              position: 'fixed',
              top: '14%',
              left: '30%',
              width: '70%',
            }}
          >
            <Typography
              variant="body1"
              style={{
                marginTop: '20px',
                color: '#696f79',
                fontWeight: 500,
              }}
            >
              Profile Pic
            </Typography>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ width: '57%' }}
            >
              <Box
                style={{
                  borderRadius: '50%',
                  width: '140px',
                  height: '140px',
                  margin: '10px 19px 0 0',
                  flexGrow: 0,
                  border: 'solid 2px #f5802c',
                }}
              >
                <img
                  src={
                    this.state.changedProfilePic
                      ? this.state.changedProfilePic
                      : this.state.downloadedProfilePic
                      ? `data:;base64,${this.state.downloadedProfilePic}`
                      : defaultPic
                  }
                  style={{
                    margin: '2%',
                    width: '96%',
                    height: '96%',
                    borderRadius: '50%',
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Button component="label" style={{ textTransform: 'none' }}>
                <Typography
                  style={{
                    color: '#1565d8',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Upload a new profile pic
                </Typography>
                <input
                  type="file"
                  accept="image/x-png,image/png,image/jpeg"
                  onChange={this.handleUpload}
                  hidden
                />
              </Button>
            </Grid>
            <Typography variant="body1" style={{ margin: '20px 0 5px 0' }}>
              Address type*
            </Typography>
            <TextField
              type="text"
              label={this.state.addresstype === '' ? 'Address type' : ''}
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              size="small"
              style={{ width: '57%' }}
              onChange={this.addresstype}
              error={!this.state.validAddresstype}
              helperText={
                !this.state.validAddresstype
                  ? 'Please select address type'
                  : null
              }
              value={this.state.addresstype}
              select
            >
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Work">Workplace</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
            <Typography variant="body1" style={{ margin: '15px 0 5px 0' }}>
              Address line1*
            </Typography>
            <TextField
              required
              type="text"
              placeholder="Address line 1"
              variant="outlined"
              size="small"
              style={{ width: '57%' }}
              value={this.state.addressline1}
              onChange={(event) => {
                if (!event.target.value.includes('#')) this.addressline1(event);
              }}
              error={!this.state.validAddressline1}
              helperText={
                !this.state.validAddressline1 ? 'Please enter address' : null
              }
            />
            <Typography variant="body1" style={{ margin: '15px 0 5px 0' }}>
              Address line2
            </Typography>
            <TextField
              required
              type="text"
              placeholder="Address line 2"
              variant="outlined"
              size="small"
              style={{ width: '57%' }}
              value={this.state.addressline2}
              onChange={(event) => {
                if (!event.target.value.includes('#')) this.addressline2(event);
              }}
            />
            <Grid
              container
              direction="row"
              style={{
                marginTop: '15px',
                width: '57%',
              }}
              justify="space-between"
            >
              <Grid container direction="column" style={{ width: '47%' }}>
                <Typography variant="body1" style={{ marginBottom: '5px' }}>
                  City*
                </Typography>
                <TextField
                  required
                  type="text"
                  placeholder="City"
                  variant="outlined"
                  size="small"
                  value={this.state.city}
                  onChange={(event) => {
                    if (!event.target.value.includes('#')) this.city(event);
                  }}
                  error={!this.state.validCity}
                  helperText={
                    !this.state.validCity
                      ? 'Please enter name of the city'
                      : null
                  }
                />
              </Grid>
              <Grid container direction="column" style={{ width: '47%' }}>
                <Typography variant="body1" style={{ marginBottom: '5px' }}>
                  State*
                </Typography>
                <TextField
                  required
                  type="text"
                  placeholder="State"
                  variant="outlined"
                  size="small"
                  value={this.state.stateName}
                  onChange={(event) => {
                    if (!event.target.value.includes('#'))
                      this.stateName(event);
                  }}
                  error={!this.state.validStateName}
                  helperText={
                    !this.state.validStateName
                      ? 'Please enter name of the state'
                      : null
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              style={{
                marginTop: '15px',
                width: '57%',
              }}
              justify="space-between"
            >
              <Grid container direction="column" style={{ width: '47%' }}>
                <Typography variant="body1" style={{ marginBottom: '5px' }}>
                  Zip code*
                </Typography>
                <TextField
                  required
                  type="text"
                  placeholder="Zip code"
                  variant="outlined"
                  size="small"
                  value={this.state.zip}
                  onChange={(event) => {
                    if (!event.target.value.includes('#')) this.zip(event);
                  }}
                  error={!this.state.validZip}
                  helperText={
                    !this.state.validZip && this.state.zip !== ''
                      ? 'Only numerics are allowed'
                      : !this.state.validZip
                      ? 'Please enter zipcode'
                      : null
                  }
                />
              </Grid>
              <Grid container direction="column" style={{ width: '47%' }}>
                <Typography variant="body1" style={{ marginBottom: '5px' }}>
                  Country*
                </Typography>
                <TextField
                  required
                  type="text"
                  placeholder="Country"
                  variant="outlined"
                  size="small"
                  value={this.state.country}
                  onChange={(event) => {
                    if (!event.target.value.includes('#')) this.country(event);
                  }}
                  error={!this.state.validCountry}
                  helperText={
                    !this.state.validCountry
                      ? 'Please enter name of the country'
                      : null
                  }
                />
              </Grid>
            </Grid>

            <Typography
              variant="body1"
              style={{ margin: '15px 0 5px 0', width: '57%' }}
            >
              Do you want to register as a service provider*
            </Typography>
            <TextField
              type="text"
              label={this.state.userType === '' ? 'Yes/No' : ''}
              InputLabelProps={{ shrink: false }}
              variant="outlined"
              size="small"
              style={{ width: '57%' }}
              onChange={this.userType}
              error={!this.state.validUserType}
              helperText={
                !this.state.validUserType ? 'Please select user type' : null
              }
              value={this.state.userType}
              select
            >
              <MenuItem value="SERVICE_PROVIDER">Yes</MenuItem>
              <MenuItem value="SERVICE_CONSUMER">No</MenuItem>
            </TextField>

            <Button
              variant="contained"
              size="large"
              style={{
                textTransform: 'none',
                margin: '15px 0 30px 0',

                backgroundColor: '#f5802c',
                color: '#FFFFFF',
                width: '57%',
              }}
              onClick={() => {
                this.state.profilePic &&
                  localStorage.setItem('ProfilePic', this.state.profilePic);
                this.state.addresstype === '' &&
                  this.setState({ validAddresstype: false });
                this.state.addressline1 === '' &&
                  this.setState({ validAddressline1: false });
                this.state.city === '' && this.setState({ validCity: false });
                this.state.stateName === '' &&
                  this.setState({ validStateName: false });
                this.state.zip === '' && this.setState({ validZip: false });
                this.state.country === '' &&
                  this.setState({ validCountry: false });
                this.state.userType === '' &&
                  this.setState({ validUserType: false });
                this.state.addresstype &&
                  this.state.addressline1 &&
                  this.state.city &&
                  this.state.stateName &&
                  this.state.country &&
                  this.state.zip &&
                  this.state.userType &&
                  this.geoCoding();
              }}
            >
              <Typography style={{ fontSize: 16 }}>Next</Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
export default Register2;

// import React, { Component } from 'react';
// import {
//   TextField,
//   Grid,
//   Button,
//   Typography,
//   MenuItem,
//   Box,
// } from '@material-ui/core';
// import { Ip } from '../../data/ip';
// import validator from 'validator';

// class Register2 extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       addresses: [
//         {
//           addresstype: '',
//           addressline1: '',
//           addressline2: '',
//           city: '',
//           state: '',
//           country: '',
//           zip: '',
//           latitude: '',
//           longitude: '',
//         },
//       ],
//       userType: this.props.serviceType || '',
//       validZip: false,
//     };

//     this.addresstype = this.addresstype.bind(this);
//     this.addressline1 = this.addressline1.bind(this);
//     this.addressline2 = this.addressline2.bind(this);
//     this.city = this.city.bind(this);
//     this.stateName = this.stateName.bind(this);
//     this.country = this.country.bind(this);
//     this.zip = this.zip.bind(this);
//     this.userType = this.userType.bind(this);
//     this.register = this.register.bind(this);
//     this.geoCoding = this.geoCoding.bind(this);
//     this.position = this.position.bind(this);
//     this.registerUsertypeandProfilePic = this.registerUsertypeandProfilePic.bind(this);
//   }

//   addresstype(event) {
//     this.setState({ addresstype: event.target.value });
//   }

//   addressline1(event) {
//     this.setState({ addressline1: event.target.value });
//   }

//   addressline2(event) {
//     this.setState({ addressline2: event.target.value });
//   }

//   city(event) {
//     this.setState({ city: event.target.value });
//   }

//   stateName(event) {
//     this.setState({ state: event.target.value });
//   }

//   country(event) {
//     this.setState({ country: event.target.value });
//   }

//   zip(event) {
//     !validator.isEmpty(event.target.value) &&
//     validator.isNumeric(event.target.value)
//       ? this.setState({ zip: event.target.value, validZip: true })
//       : this.setState({ zip: event.target.value, validZip: false });
//   }

//   userType(event) {
//     this.setState({ userType: event.target.value });
//   }

//   position() {
//     navigator.geolocation.getCurrentPosition(
//       (position) => {
//         this.setState({
//           latitude: position.coords.latitude,
//           longitude: position.coords.longitude,
//         });
//         console.log('current loc', this.state.latitude, this.state.longitude);
//       },
//       (error) => {
//         this.geoCoding();
//       }
//     );
//   }

//   geoCoding() {
//     fetch(
//       `https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.addressline1},${this.state.addressline2},${this.state.city},${this.state.state},${this.state.country},+CA&key=AIzaSyDWXB7pLY_ubdVDvG1vvD9oNshbUQ9qn80`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         console.log('geo data:', data);
//         if (data.status === 'OK') {
//           this.setState({
//             latitude: data.results[0].geometry.location.lat,
//             longitude: data.results[0].geometry.location.lng,
//           });
//           console.log('geo loc', this.state.latitude, this.state.longitude);
//         }
//       })
//       .catch((err) => {
//         alert(`ERR: ${err}`);
//         console.log(err);
//       });
//   }

//   register(event) {
//     const body = [
//       {
//         addresstype: this.state.addresstype,
//         addressline1: this.state.addressline1,
//         addressline2: this.state.addressline2,
//         city: this.state.city,
//         state: this.state.state,
//         zip: this.state.zip,
//         country: this.state.country,
//         latitude: String(this.state.latitude),
//         longitude: String(this.state.longitude),
//       },
//     ];
//     console.log(body);
//     fetch(`${Ip}/user/address`, {
//       method: 'post',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
//       },
//       body: JSON.stringify(body),
//     })
//       .then((Response) => {
//         if (Response.status === 200) {
//           !this.props.change
//             ? this.registerUsertypeandProfilePic()
//             : (this.props.update(),
//               localStorage.setItem(
//                 'location',
//                 JSON.stringify({
//                   latitude: String(this.state.latitude),
//                   longitude: String(this.state.longitude),
//                   name: this.state.addressline1,
//                 })
//               ));
//         }
//         console.log(Response);
//       })
//       .catch((err) => {
//         alert(`ERR: ${err}`);
//         console.log(err);
//       });
//   }

//   registerUsertypeandProfilePic() {
//     const body = {
//       usertype: this.state.userType,
//     };
//     console.log(body);
//     fetch(`${Ip}/user`, {
//       method: 'PATCH',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
//       },
//       body: JSON.stringify(body),
//     })
//       .then((response) => {
//         if (response.status === 200) {
//           this.state.userType === 'SERVICE_PROVIDER'
//             ? this.props.history.push('/Register3')
//             : localStorage.getItem('createServiceRequestBySearchingProviders')
//             ? this.props.history.push(
//                 `/requestprovider/${localStorage.getItem(
//                   'createServiceRequestBySearchingProviders'
//                 )}`
//               )
//             : this.props.history.push('/');
//         }
//         console.log(response);
//       })
//       .catch((err) => {
//         alert(`ERR: ${err}`);
//         console.log(err);
//       });
//   }

//   render() {
//     const defaultProps = {
//       borderColor: 'text.primary',
//       m: 1,
//       border: !this.props.change ? 1 : 0,
//       style: { width: '45rem', height: 'auto' },
//     };

//     return (
//       <div>
//         <form name="MyForm2" autoComplete="on">
//           <Grid
//             container
//             justify="center"
//             wrap="wrap"
//             direction="column"
//             spacing={2}
//             alignItems="center"
//           >
//             {!this.props.change ? (
//               <Grid container item justify="center" alignItems="center" xs={12}>
//                 <Typography variant="h4" align="center">
//                   USER REGISTRATION
//                 </Typography>
//               </Grid>
//             ) : null}
//             <Box
//               borderColor="primary.main"
//               borderRadius={16}
//               alignItems="center"
//               justifyContent="center"
//               {...defaultProps}
//             >
//               <Box
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="center"
//                 p={1}
//                 m={1}
//                 css={{ height: 100 }}
//               >
//                 <Box p={1}>
//                   <TextField
//                     required
//                     name="addresstype"
//                     id="select"
//                     type="text"
//                     variant="outlined"
//                     margin="dense"
//                     label="Addresstype"
//                     onChange={this.addresstype}
//                     select
//                     style={{ width: '204.8px' }}
//                   >
//                     <MenuItem value="Home">Home</MenuItem>
//                     <MenuItem value="Work">Workplace</MenuItem>
//                     <MenuItem value="Other">Other</MenuItem>
//                   </TextField>
//                 </Box>
//                 <Box p={1}>
//                   <TextField
//                     required
//                     name="address"
//                     id="address"
//                     type="text"
//                     multiline
//                     variant="outlined"
//                     margin="dense"
//                     label="Address line1"
//                     onChange={this.addressline1}
//                   />
//                 </Box>
//                 <Box p={1}>
//                   <TextField
//                     name="address2"
//                     id="address2"
//                     type="text"
//                     multiline
//                     variant="outlined"
//                     margin="dense"
//                     label="Address line2"
//                     onChange={this.addressline2}
//                   ></TextField>
//                 </Box>
//               </Box>

//               <Box
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="center"
//                 p={1}
//                 m={1}
//                 css={{ height: 100 }}
//               >
//                 <Box p={1}>
//                   <TextField
//                     required
//                     name="city"
//                     id="city"
//                     type="text"
//                     variant="outlined"
//                     margin="dense"
//                     label="City"
//                     onChange={this.city}
//                   />
//                 </Box>
//                 <Box p={1}>
//                   <TextField
//                     required
//                     name="state"
//                     id="state"
//                     type="text"
//                     variant="outlined"
//                     margin="dense"
//                     label="State"
//                     onChange={this.stateName}
//                   />
//                 </Box>
//               </Box>
//               <Box
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="center"
//                 p={1}
//                 m={1}
//                 css={{ height: 100 }}
//               >
//                 <Box p={1}>
//                   <TextField
//                     required
//                     name="country"
//                     id="country"
//                     type="text"
//                     variant="outlined"
//                     margin="dense"
//                     label="Country"
//                     onChange={this.country}
//                   />
//                 </Box>
//                 <Box p={1}>
//                   <TextField
//                     required
//                     name="zip"
//                     id="zip"
//                     type="value"
//                     variant="outlined"
//                     margin="dense"
//                     label="Zip/Pincode"
//                     onChange={this.zip}
//                     onFocus={this.position}
//                     error={
//                       !this.state.validZip &&
//                       this.state.zip !== undefined &&
//                       this.state.zip !== ''
//                     }
//                     helperText={
//                       !this.state.validZip &&
//                       this.state.zip !== undefined &&
//                       this.state.zip !== ''
//                         ? 'Only Numerics are allowed'
//                         : null
//                     }
//                   />
//                 </Box>
//               </Box>
//               {!this.props.change ? (
//                 <Box
//                   display="flex"
//                   alignItems="center"
//                   justifyContent="center"
//                   p={1}
//                   m={1}
//                   css={{ height: 100 }}
//                 >
//                   <Box p={1}>
//                     <TextField
//                       required
//                       name="usertype"
//                       id="select"
//                       type="text"
//                       variant="outlined"
//                       margin="dense"
//                       label="Do you want to register as a Service Provider"
//                       onChange={this.userType}
//                       select
//                       style={{ width: '480px' }}
//                     >
//                       <MenuItem value="SERVICE_PROVIDER">Yes</MenuItem>
//                       <MenuItem value="SERVICE_CONSUMER">No</MenuItem>
//                     </TextField>
//                   </Box>
//                 </Box>
//               ) : null}
//               <Box
//                 display="flex"
//                 alignItems="center"
//                 justifyContent="center"
//                 p={1}
//               >
//                 <Button
//                   onClick={this.register}
//                   variant="contained"
//                   color="primary"
//                   disabled={
//                     !this.state.addresstype ||
//                     !this.state.addressline1 ||
//                     !this.state.city ||
//                     !this.state.state ||
//                     !this.state.country ||
//                     !this.state.zip ||
//                     !this.state.latitude ||
//                     !this.state.longitude ||
//                     !this.state.userType
//                   }
//                 >
//                   Save
//                 </Button>
//               </Box>
//             </Box>
//           </Grid>
//         </form>
//       </div>
//     );
//   }
// }
// export default Register2;
