/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  IconButton,
  Typography,
  colors,
  Paper,
  useTheme,
  Dialog,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ShowAttachments from '../ShowAttachments';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import ChatRoom from '../../ChatRoom/ChatRoom';
import { DataGridStyles } from '../../../data/DataGridStyles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteDialog from '../../../components/DeleteDialog';

export default function ServiceRequestTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [type, setType] = useState();
  const [attachmentId, setAttachmentId] = useState();
  const [serviceRequestId, setServiceRequestId] = useState();
  const [attachmentName, setAttachmentName] = useState('');
  const [providerId, setProviderId] = useState();
  const [providerName, setProviderName] = useState();
  const [serviceTitle, setServiceTitle] = useState();
  const [open, setOpen] = useState(false);
  const [del, setDel] = useState(true);
  const [viewOffers, setViewOffers] = useState(true);
  const [chat, setChat] = useState(true);
  const [openChatWindow, setOpenChatWindow] = useState(false);
  const [deleteContent, setDeleteContent] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState();

  function CustomToolbar() {
    return (
      <Grid
        container
        justify="flex-end"
        alignContent="center"
        style={{
          height: '50px',
          borderBottom: 'solid 2px #cfd9e2',
        }}
      >
        <Button
          style={{ textTransform: 'none' }}
          disabled={chat}
          onClick={() => {
            setOpenChatWindow(true);
            localStorage.setItem('chatWith', providerId + '');
            localStorage.setItem('chatWithName', providerName);
          }}
        >
          <Typography>Chat with Provider</Typography>
        </Button>
        <Button
          style={{ textTransform: 'none' }}
          href={`/offeredbids/${[serviceRequestId, serviceTitle]}`}
          disabled={viewOffers}
        >
          <Typography>View Offers</Typography>
        </Button>
        <Button
          style={{ textTransform: 'none' }}
          title="Delete the selected services"
          onClick={() => setShowDeleteDialog(true)}
          disabled={del}
        >
          <Typography>Delete</Typography>
          <DeleteIcon />
        </Button>
      </Grid>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      hide: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 290,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 300,
      sortable: false,
    },
    {
      field: 'servicetype',
      headerName: 'Service type',
      width: 130,
      sortable: false,
    },
    {
      field: 'attachments',
      headerName: 'Attachments',
      sortable: false,
      width: 170,
      height: 'auto',

      renderCell: (params) => (
        <Select
          name="Attachments"
          id="attachments"
          IconComponent={AttachmentIcon}
          disableUnderline
          value={attachmentName}
        >
          {params.value.map((ats, i) => (
            <MenuItem
              value={ats.name}
              key={i}
              onClick={() => {
                console.log('params are', params.row.id);
                setServiceRequestId(params.row.id);
                setAttachmentId(ats.srattachmentid);
                setAttachmentName(ats.name);
                setType(ats.type);
                setOpen(!open);
              }}
            >
              {ats.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      sortable: false,
    },
    {
      field: 'createdDate',
      headerName: 'Created date',
      width: 130,
      sortable: true,
    },
    {
      field: 'update',
      headerName: 'Update',
      width: 130,
      sortable: false,
      renderCell: (params) => (
        <Button
          disabled={params.row.status === 'BidAccepted'}
          href={`/updateservicerequest/${params.value}`}
        >
          <EditIcon />
        </Button>
      ),
    },
    {
      field: 'updatedat',
      headerName: ' ',
      width: 130,
      hide: true,
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: con.servicerequestid,
          title: con.title,
          description: con.description,
          servicetype: con.servicename,
          attachments: con.attachments,
          update: con.servicerequestid,
          status: con.status,
          createdDate: new Date(con.createdat).toLocaleDateString('en-US'),
          updatedat: con.updatedat,
        },
      ]);
    });
  }, []);

  return (
    <Grid
      container
      style={{ height: props.content.length * 52 + 116, width: '100%' }}
      className={classes.root}
    >
      <DataGrid
        sortModel={[
          {
            field: 'updatedat',
            sort: 'desc',
          },
        ]}
        autoHeight
        components={{
          header: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        hideFooter
        showToolbar
        onSelectionChange={(param) => {
          if (param.rowIds.length === 1) {
            for (var i in props.content) {
              if (props.content[i].servicerequestid === +param.rowIds[0]) {
                props.content[i].status === 'BidAccepted' &&
                  (setChat(false),
                  setProviderName(
                    `${
                      props.content[i].serviceproviderdetails
                        .offeredby_userfirstname
                    } ${
                      props.content[i].serviceproviderdetails
                        .offeredby_usermiddlename != ''
                        ? props.content[i].serviceproviderdetails
                            .offeredby_usermiddlename + ' '
                        : ''
                    }${
                      props.content[i].serviceproviderdetails
                        .offeredby_userlastname
                    }`
                  ),
                  setProviderId(
                    props.content[i].serviceproviderdetails.offeredby_userid
                  ));

                props.content[i].status === 'Open' &&
                  (setDel(false),
                  setServiceRequestId(props.content[i].servicerequestid),
                  setDeleteContent(props.content[i]));

                props.content[i].status === 'OpenOffered' &&
                  (setViewOffers(false),
                  setServiceRequestId(props.content[i].servicerequestid),
                  setServiceTitle(props.content[i].title));
              }
            }
          } else {
            setDel(true), setViewOffers(true), setChat(true);
          }
        }}
      />
      {open && (
        <ShowAttachments
          servicerequestid={serviceRequestId}
          attachmentid={attachmentId}
          type={type}
          open={open}
          close={() => setOpen(!open)}
        />
      )}

      {openChatWindow && (
        <ChatRoom onClose={() => setOpenChatWindow(!openChatWindow)} />
      )}

      {showDeleteDialog && (
        <DeleteDialog
          open={showDeleteDialog}
          close={() => setShowDeleteDialog(false)}
          title="Do you want to delete this service request"
          messageTitle1="Title"
          messageContent1={deleteContent.title}
          messageTitle2="Description"
          messageContent2={deleteContent.description}
          messageTitle3="Service name"
          messageContent3={deleteContent.servicename}
          messageTitle4="Status"
          messageContent4={deleteContent.status}
          delete={() => props.clicked(serviceRequestId)}
        />
      )}
    </Grid>
  );
}
