import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  Link,
  Box,
  TextField,
  Button,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import profileIcon2 from '../assets/Images/profile-icon-2.svg';
import SearchIcon from '@material-ui/icons/Search';
import { Ip } from '../data/ip';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = (theme) => ({
  root: {
    backgroundColor: '#ffffff',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
});

const mapStateToProps = (state) => {
  return { signedIn: state.userSignedIn };
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      findVal: '',
      testVal: '',
      userName: '',
      totalElements: '',
      profilePic: localStorage.getItem('ProfilePic'),
      data: [],
      create: false,
    };
    this.findHandler = this.findHandler.bind(this);
  }

  findHandler(event) {
    this.setState({
      findVal: event.target.value,
    });
  }

  componentDidMount() {
    let X = localStorage.getItem('userName');
    let user = X ? JSON.parse(X) : '';
    X &&
      this.setState({ userName: `${user.fname} ${user.mname} ${user.lname}` });
    X &&
      axios
        .get(
          `${Ip}/servicerequest?pageNumber=${0}&pageSize=${2}&sortDirection=DESC&sortField=updatedat`,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${
                localStorage.getItem('ResetToken') || ''
              }`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            if (response.data.totalelements === 0) {
              this.setState({ create: true });
            } else
              this.setState({ totalElements: response.data.totalelements });
            this.setState({ data: response.data.servicerequests });
          }
        })
        .catch((err) => {
          alert(`ERR: ${err}`);
          console.log(err);
        });
  }

  render() {
    const { classes } = this.props;
    const style1 = {
      color: '#2f3949',
      fontWeight: 500,
      fontSize: '14px',
    };
    return (
      <Grid container style={{ marginTop: 20 }}>
        {!localStorage.getItem('ResetToken') ? (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="baseline"
          >
            <Link
              href="/login"
              underline="always"
              style={{ marginRight: '1em' }}
            >
              <Typography variant="h6">Sign In</Typography>
            </Link>

            <Link href="/register1" underline="always">
              <Typography variant="h6">Sign Up</Typography>
            </Link>
          </Grid>
        ) : null}
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center"
          >
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ width: '50%' }}
            >
              <Box
                style={{
                  borderRadius: '50%',
                  width: '35px',
                  height: '35px',
                  flexGrow: 0,
                  border: 'solid 2px #f5802c',
                }}
              >
                <Grid
                  container
                  justify="center"
                  alignContent="center"
                  style={{ width: '100%', height: '100%' }}
                >
                  <img
                    src={
                      this.state.profilePic
                        ? `data:;base64,${this.state.profilePic}`
                        : profileIcon2
                    }
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      cursor: 'pointer',
                    }}
                  />
                </Grid>
              </Box>

              <Typography
                style={{
                  color: '#2f3949',
                  fontSize: '24px',
                  fontWeight: 500,
                  textTransform: 'capitalize',
                  marginLeft: '5px',
                }}
              >
                {this.state.userName}
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ width: '50%' }}
            >
              <Typography
                style={{
                  fontSize: '18px',
                  fontWeight: 600,
                  marginRight: '5px',
                }}
              >
                I need
              </Typography>
              <TextField
                className={classes.root}
                variant="outlined"
                size="small"
                placeholder="Find Service"
                onChange={this.findHandler}
                onKeyPress={(event) => {
                  var code;
                  if (event.key !== undefined) {
                    code = event.key;
                  } else if (event.keyIdentifier !== undefined) {
                    code = event.keyIdentifier;
                  } else if (event.keyCode !== undefined) {
                    code = event.keyCode;
                  }
                  if ((code === 13 || code === 'Enter') && this.state.findVal) {
                    window.location.href = `/searchresults/${this.state.findVal}`;
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment style={{ whiteSpace: 'pre' }}>
                      <SearchIcon style={{ color: '#f5802c' }} />
                      <Typography>{`  `}</Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      style={{ whiteSpace: 'pre', height: '100%' }}
                    >
                      <Button
                        disableElevation
                        href={
                          this.state.findVal
                            ? `searchresults/${this.state.findVal}`
                            : null
                        }
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: 'none',
                          color: '#ffffff',
                          height: '40px',
                          borderTopLeftRadius: '0px',
                          borderBottomLeftRadius: '0px',
                        }}
                      >
                        Find
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {localStorage.getItem('ResetToken') ? (
            <Grid
              container
              direction="column"
              style={{
                width: '100%',
                padding: '20px',
                marginTop: 20,
                backgroundColor: '#ffffff',
              }}
            >
              <Grid container direction="row" justify="space-between">
                <Typography
                  style={{
                    fontSize: '18px',
                    fontWeight: 500,
                  }}
                >
                  My Service Requests{' '}
                  {this.state.totalElements ? (
                    <span>
                      (
                      <span style={{ color: '#f5802c' }}>
                        {this.state.totalElements}
                      </span>
                      )
                    </span>
                  ) : null}
                </Typography>
                <Link
                  href="/myservicerequests"
                  underline="always"
                  style={{ color: '#1565d8' }}
                >
                  <Typography
                    style={{
                      color: '#1565d8',
                    }}
                  >
                    View All Service Requests
                  </Typography>
                </Link>
                {this.state.data.length > 0 ? (
                  this.state.data.map((data, i) => (
                    <Grid
                      key={i}
                      container
                      direction="column"
                      justify="center"
                      alignItems="flex-start"
                      style={{
                        margin: '20px 0 0 0',
                        padding: '15px 34px 17px 15px',
                        borderRadius: '6px',
                        backgroundColor: '#f8f9fb',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#2f3949',
                          fontWeight: 500,
                          fontSize: '18px',
                        }}
                      >
                        {data.servicename}
                      </Typography>
                      <Typography
                        style={{
                          color: '#8692a6',
                          fontWeight: 500,
                          fontSize: '14px',
                          marginTop: '10px',
                        }}
                      >
                        Title: {data.title}
                      </Typography>
                      <Typography
                        style={{
                          color: '#8692a6',
                          fontWeight: 500,
                          fontSize: '14px',
                          marginTop: '2px',
                        }}
                      >
                        Description: {data.description}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        style={{ marginTop: '15px' }}
                      >
                        <Typography style={{ width: '25%' }}>
                          <span style={style1}>Service request id: </span>{' '}
                          <span style={{ color: '#f5802c' }}>
                            {data.servicerequestid}
                          </span>
                        </Typography>
                        <Typography style={{ width: '25%' }}>
                          <span style={style1}> Created Date: </span>
                          <span style={{ color: '#f5802c' }}>
                            {new Date(data.createdat).toLocaleDateString(
                              'en-US'
                            )}
                          </span>
                        </Typography>
                        <Typography style={{ width: '25%' }}>
                          <span style={style1}>Status: </span>
                          <span style={{ color: '#f5802c' }}>
                            {' '}
                            {data.status}
                          </span>
                        </Typography>
                        <Typography style={{ width: '25%' }}>
                          <span style={style1}>Bidtype:</span>
                          <span style={{ color: '#f5802c' }}>
                            {' '}
                            {data.bidtype}
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  ))
                ) : !this.state.create ? (
                  <Grid
                    container
                    justify="center"
                    style={{ margin: '100px 0 100px 0' }}
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    style={{
                      margin: '100px 0 100px 0',
                    }}
                  >
                    <Typography
                      align="center"
                      style={{ fontWeight: 500, color: '#8692a6' }}
                    >
                      No serivce Requests found.
                      <br />
                      Please Create a Request to See Results.
                    </Typography>
                    <Button
                      variant="contained"
                      size="large"
                      style={{
                        textTransform: 'none',
                        position: 'relative',
                        top: 15,
                        backgroundColor: '#f5802c',
                        color: '#FFFFFF',
                      }}
                      href="/createservicerequest"
                    >
                      <Typography> Create Service Request</Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : null}

          {/* <Typography>
            {this.props.signedIn ? 'Signed In' : 'Not Signed In'}
          </Typography> */}
        </Grid>
      </Grid>
    );
  }
}

const ConnectedHome = connect(mapStateToProps)(Home);
export default withStyles(useStyles)(ConnectedHome);
