import React, { useState } from 'react';
import { Typography, Button, Menu, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from 'material-ui-popup-state/HoverPopover';
import {
  usePopupState,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state/hooks';

export default function ContractsButton(props) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  return (
    <Grid>
      <Button
        {...bindHover(popupState)}
        style={{
          textTransform: 'none',
          color: '#ffffff',
          borderRadius: 0,
          borderBottom: props.border ? '3px solid #f5802c' : null,
          paddingBottom: props.border ? 13 : 16,
          marginRight: 15,
          opacity: props.border ? 1 : 0.7,
        }}
      >
        <Typography style={{ height: 24 }}>
          Contracts
          <ExpandMoreIcon style={{ marginLeft: 8 }} />
        </Typography>
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        {localStorage.getItem('UserType') === 'SERVICE_PROVIDER' && (
          <div>
            <Button
              fullWidth
              href="/contracts/provider"
              style={{
                textTransform: 'none',
                width: '200px',
                height: '40px',
              }}
            >
              <Typography>Contracts as provider</Typography>
            </Button>
            <br />
          </div>
        )}
        <Button
          fullWidth
          href="/contracts/consumer"
          style={{
            textTransform: 'none',
            width: '200px',
            height: '40px',
          }}
        >
          <Typography>Contracts as consumer</Typography>
        </Button>
      </Popover>
    </Grid>
  );
}
