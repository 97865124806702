import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Button,
  InputAdornment,
  MenuItem,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { Ip } from '../../data/ip';
import CloseIcon from '@material-ui/icons/Close';
import validator from 'validator';

export default function ChangeAddress(props) {
  const [addressType, setAddressType] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [stateName, setStateName] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [validAddressType, setValidAddressType] = useState(true);
  const [validAddressLine1, setValidAddressLine1] = useState(true);
  const [validCity, setValidCity] = useState(true);
  const [validStateName, setValidStateName] = useState(true);
  const [validZip, setValidZip] = useState(true);
  const [validCountry, setValidCountry] = useState(true);

  useEffect(() => {
    props.open &&
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude),
          setLongitude(position.coords.longitude),
          console.log(
            'current loc',
            position.coords.latitude,
            position.coords.longitude
          );
      });
  }, [props.open]);

  function geoCoding() {
    latitude && longitude
      ? register(latitude, longitude)
      : fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${addressLine1},${addressLine2},${city},${stateName},${country},${zip},+CA&key=AIzaSyDWXB7pLY_ubdVDvG1vvD9oNshbUQ9qn80`
        )
          .then((response) => response.json())
          .then((data) => {
            console.log('geo data:', data);
            if (data.status === 'OK') {
              register(
                data.results[0].geometry.location.lat,
                data.results[0].geometry.location.lng
              );
            }
          })
          .catch((err) => {
            alert(`ERR: ${err}`);
            console.log(err);
          });
  }

  function register(lat, lng) {
    const body = [
      {
        addresstype: addressType,
        addressline1: addressLine1,
        addressline2: addressLine2,
        city: city,
        state: stateName,
        zip: zip,
        country: country,
        latitude: String(lat),
        longitude: String(lng),
      },
    ];
    console.log(body);
    fetch(`${Ip}/user/address`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((Response) => {
        if (Response.status === 200) {
          props.refresh();
          props.close();
          setAddressType('');
          setAddressLine1('');
          setAddressLine2('');
          setCity('');
          setStateName('');
          setCountry('');
          setZip('');
          setLatitude('');
          setLongitude('');
          localStorage.setItem(
            'location',
            JSON.stringify({
              latitude: String(lat),
              longitude: String(lng),
              name: addressLine1,
            })
          );
        }
        console.log(Response);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      PaperProps={{
        style: { borderRadius: 0 },
      }}
      maxWidth={false}
      style={{ backgroundColor: 'rgba(119, 129, 146, 0.3)' }}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        style={{ width: '650px' }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{
            hieght: '50px',
            padding: '16px 12px 10px 32px',
            backgroundColor: '#f9fafb',
          }}
        >
          <Typography style={{ fontWeight: '600' }}>Address</Typography>
          <IconButton
            size="small"
            onClick={() => {
              props.close();
              setAddressType('');
              setAddressLine1('');
              setAddressLine2('');
              setCity('');
              setStateName('');
              setCountry('');
              setZip('');
              setLatitude('');
              setLongitude('');
              setValidAddressType(true);
              setValidAddressLine1(true);
              setValidCity(true);
              setValidStateName(true);
              setValidZip(true);
              setValidCountry(true);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Grid
            container
            direction="row"
            style={{
              width: '94%',
              height: '69px',
              marginTop: 20,
            }}
            justify="space-around"
          >
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            >
              <Typography variant="body1" style={{ fontSize: 12 }}>
                Address type*
              </Typography>
              <TextField
                variant="outlined"
                size="small"
                label={addressType === '' ? 'Address type' : ''}
                InputLabelProps={{ shrink: false }}
                select
                value={addressType}
                onChange={(event) => {
                  setAddressType(event.target.value), setValidAddressType(true);
                }}
                error={!validAddressType}
                helperText={!validAddressType ? 'Select address type' : ''}
              >
                <MenuItem value="Work">Work</MenuItem>
                <MenuItem value="Home">Home</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            >
              <Typography variant="body1" style={{ fontSize: 12 }}>
                Address Line 1*
              </Typography>
              <TextField
                required
                type="text"
                placeholder="Address Line 1"
                variant="outlined"
                size="small"
                value={addressLine1}
                onChange={(event) => {
                  if (!event.target.value.includes('#')) {
                    setAddressLine1(event.target.value);
                    setValidAddressLine1(true);
                  }
                }}
                error={!validAddressLine1}
                helperText={!validAddressLine1 ? 'Add address line 1' : ''}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            style={{
              width: '94%',
              height: '69px',
              marginTop: 20,
            }}
            justify="space-around"
          >
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            >
              <Typography variant="body1" style={{ fontSize: 12 }}>
                Address Line 2
              </Typography>
              <TextField
                required
                type="text"
                placeholder="Address Line 2"
                variant="outlined"
                size="small"
                value={addressLine2}
                onChange={(event) => {
                  if (!event.target.value.includes('#'))
                    setAddressLine2(event.target.value);
                }}
              />
            </Grid>
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            >
              <Typography variant="body1" style={{ fontSize: 12 }}>
                City*
              </Typography>
              <TextField
                required
                type="text"
                placeholder="City"
                variant="outlined"
                size="small"
                value={city}
                onChange={(event) => {
                  if (!event.target.value.includes('#')) {
                    setCity(event.target.value);
                    setValidCity(true);
                  }
                }}
                error={!validCity}
                helperText={!validCity ? 'Add city' : ''}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            style={{
              width: '94%',
              height: '69px',
              marginTop: 20,
            }}
            justify="space-around"
          >
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            >
              <Typography variant="body1" style={{ fontSize: 12 }}>
                State*
              </Typography>
              <TextField
                required
                type="text"
                placeholder="State"
                variant="outlined"
                size="small"
                value={stateName}
                onChange={(event) => {
                  if (!event.target.value.includes('#')) {
                    setStateName(event.target.value);
                    setValidStateName(true);
                  }
                }}
                error={!validStateName}
                helperText={!validStateName ? 'Add state' : ''}
              />
            </Grid>
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            >
              <Typography variant="body1" style={{ fontSize: 12 }}>
                Country*
              </Typography>
              <TextField
                required
                type="text"
                placeholder="Country"
                variant="outlined"
                size="small"
                value={country}
                onChange={(event) => {
                  if (!event.target.value.includes('#')) {
                    setCountry(event.target.value);
                    setValidCountry(true);
                  }
                }}
                error={!validCountry}
                helperText={!validCountry ? 'Add country' : ''}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            style={{
              width: '94%',
              height: '69px',
              marginTop: 20,
            }}
            justify="space-around"
          >
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            >
              <Typography variant="body1" style={{ fontSize: 12 }}>
                Zipcode*
              </Typography>
              <TextField
                required
                type="text"
                placeholder="Zipcode"
                variant="outlined"
                size="small"
                value={zip}
                onChange={(event) => {
                  !validator.isEmpty(event.target.value) &&
                  validator.isNumeric(event.target.value)
                    ? (setZip(event.target.value), setValidZip(true))
                    : (setZip(event.target.value), setValidZip(false));
                }}
                error={!validZip}
                helperText={!validZip ? 'Add valid zipcode' : ''}
              />
            </Grid>
            <Grid
              container
              direction="column"
              style={{ width: '47%' }}
              justify="space-around"
            ></Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          style={{
            height: '69px',
            backgroundColor: '#f9fafb',
            marginTop: 20,
          }}
          justify="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            style={{
              textTransform: 'none',
              position: 'relative',
              backgroundColor: '#ffffff',
              color: '#f5802c',
              width: '100px',
              height: '36px',
              margin: '17px 0px 16px 0px',
            }}
            onClick={() => {
              props.close();
              setAddressType('');
              setAddressLine1('');
              setAddressLine2('');
              setCity('');
              setStateName('');
              setCountry('');
              setZip('');
              setLatitude('');
              setLongitude('');
              setValidAddressType(true);
              setValidAddressLine1(true);
              setValidCity(true);
              setValidStateName(true);
              setValidZip(true);
              setValidCountry(true);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: 'none',
              position: 'relative',
              backgroundColor: '#f5802c',
              color: '#FFFFFF',
              width: '100px',
              height: '36px',
              margin: '17px 32px 16px 20px',
            }}
            onClick={() => {
              !addressType && setValidAddressType(false);
              !addressLine1 && setValidAddressLine1(false);
              !city && setValidCity(false);
              !stateName && setValidStateName(false);
              !country && setValidCountry(false);
              !zip && setValidZip(false);
              addressType !== '' &&
                addressLine1 !== '' &&
                city !== '' &&
                stateName !== '' &&
                country !== '' &&
                zip !== '' &&
                (geoCoding(), props.load());
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
