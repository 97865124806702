import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { Ip } from '../../data/ip';
import DisplayHeading from './DisplayComponents/DisplayHeading';

export default function BankAccountDetails(props) {
  // const setUpBankAccountDetails = async () => {
  //     let r;
  //     let body = {
  //         refreshURL: 'http://localhost:3000/',
  //         returnURL: 'http://localhost:3000/profile',
  //       };
  //     try {
  //       r = await axios.post(`${Ip}/payout/setup`, body, {
  //         headers: {
  //           Accept: 'application/json',
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
  //         },
  //       });
  //     } catch (e) {
  //       console.log({ error: e });
  //     }
  //     setLoad(false);
  //     console.log({ sessionCreation: r.data });
  //     // await stripe.redirectToCheckout({
  //     //   sessionId: r.data,
  //     // });
  //   };

  function setUpBankAccountDetails() {
    let body = {
      refreshURL: 'http://localhost:3000/',
      returnURL: 'http://localhost:3000/profile',
    };
    console.log(JSON.stringify(body, null, 2));
    fetch(`${Ip}/payout/setup`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.text())
      .then((response) => {
        window.location.href = response;
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }
  return (
    <Grid style={{ width: '100%' }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          backgroundColor: '#ffffff',
          marginBottom: 25,
          height: '64vh',
        }}
      >
        <DisplayHeading
          DisplayHeadingName="Please setup your bank account details to receive payments"
          caption=""
          button="Setup"
          disabled={false}
          clicked={setUpBankAccountDetails}
        />
        {/* <Typography align="center" variant="h6" style={{ marginTop: 15 }}>
          Please setup your bank account details to receive payments
        </Typography>
        <Button
          style={{
            width: 40,
            textTransform: 'none',
            height: 36,
            backgroundColor: '#f5802c',
            color: '#ffffff',
            fontSize: 14,
            marginTop: 10,
            marginBottom: 30,
          }}
        >
          Setup
        </Button> */}
      </Grid>
    </Grid>
  );
}
