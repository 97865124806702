import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Button,
  CardMedia,
  Box,
  Paper,
  Link,
  withStyles,
  InputAdornment,
  MenuItem,
  Dialog,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AvailableServices from '../../../data/AvailableServices';

export default function AddServices(props) {
  const [availableServices, setAvailableServices] = useState([]);
  const [userServices, setUserServices] = useState({
    serviceid: '',
    experiencelevel: '',
    hourlyrate: '',
    currency: 'USD',
    yearofexperience: '',
  });
  const [serviceName, setServiceName] = useState();
  let servicesListinDB = AvailableServices();
  useEffect(() => {
    console.log(props.addedServices);
    console.log(availableServices);
    let ser = '';
    let added = props.addedServices;
    if (availableServices.length === 0) {
      for (var i = 0; i < servicesListinDB.length; i++) {
        ser = [
          ...ser,
          {
            name: servicesListinDB[i].name,
            serviceid: servicesListinDB[i].serviceid,
          },
        ];
      }
    } else {
      for (var i in availableServices)
        for (var j in added) {
          if (availableServices[i].serviceid === +added[j].serviceid) {
            ser = [...availableServices];
            ser.splice(i, 1);
          }
        }
    }

    setAvailableServices(ser);
    ser = '';
  }, [servicesListinDB, props.addedServices]);
  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      PaperProps={{
        style: { borderRadius: 0 },
      }}
      maxWidth={false}
      style={{ backgroundColor: 'rgba(119, 129, 146, 0.3)' }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ height: '452px', width: '650px' }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{
            hieght: '50px',
            position: 'relative',
            padding: '16px 12px 10px 32px',
            backgroundColor: '#f9fafb',
          }}
        >
          <Typography style={{ fontWeight: '600' }}>
            Add service offer
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              props.close();
              setUserServices({
                serviceid: '',
                experiencelevel: '',
                hourlyrate: '',
                currency: 'USD',
                yearofexperience: '',
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        {availableServices && (
          <Grid container direction="column" alignItems="center">
            <Grid
              container
              direction="row"
              style={{
                position: 'relative',
                width: '94%',
                height: '69px',
                top: 20,
              }}
              justify="space-around"
            >
              <Grid
                container
                direction="column"
                style={{ width: '47%' }}
                justify="space-around"
              >
                <Typography variant="body1" style={{ fontSize: 12 }}>
                  What is the main service you offer?
                </Typography>
                <Autocomplete
                  options={availableServices}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, value) =>
                    value
                      ? (setUserServices({
                          ...userServices,
                          serviceid: '' + value.serviceid,
                        }),
                        setServiceName(value.name))
                      : (setUserServices({
                          ...userServices,
                          serviceid: value,
                        }),
                        setServiceName(value))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Service"
                      variant="outlined"
                      fullWidth
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid
                container
                direction="column"
                style={{ width: '47%' }}
                justify="space-around"
              >
                <Typography variant="body1" style={{ fontSize: 12 }}>
                  Experience Level
                </Typography>
                <TextField
                  required
                  type="text"
                  label={
                    userServices.experiencelevel === ''
                      ? 'Experience Level'
                      : ''
                  }
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                  size="small"
                  value={userServices.experiencelevel}
                  onChange={(event) =>
                    setUserServices({
                      ...userServices,
                      experiencelevel: event.target.value,
                    })
                  }
                  select
                >
                  <MenuItem value="Expert">Expert</MenuItem>
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Beginner">Beginner</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              style={{
                position: 'relative',
                width: '94%',
                height: '69px',
                top: 35,
              }}
              justify="space-around"
            >
              <Grid
                container
                direction="column"
                style={{ width: '47%' }}
                justify="space-around"
              >
                <Typography variant="body1" style={{ fontSize: 12 }}>
                  Years of experience
                </Typography>
                <TextField
                  required
                  type="text"
                  placeholder=" Years of experience"
                  variant="outlined"
                  size="small"
                  value={userServices.yearofexperience}
                  onChange={(event) => {
                    setUserServices({
                      ...userServices,
                      yearofexperience: event.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid
                container
                direction="column"
                style={{ width: '47%' }}
                justify="space-around"
              >
                <Typography variant="body1" style={{ fontSize: 12 }}>
                  Hourly rate
                </Typography>
                <TextField
                  required
                  type="text"
                  placeholder="Hourly rate"
                  variant="outlined"
                  size="small"
                  value={userServices.hourlyrate}
                  onChange={(event) =>
                    setUserServices({
                      ...userServices,
                      hourlyrate: event.target.value,
                    })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ whiteSpace: 'pre' }}>
                        <TextField
                          select
                          InputProps={{ disableUnderline: true }}
                          value={'USD'}
                          disabled
                        >
                          <MenuItem value="USD">$</MenuItem>
                        </TextField>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              style={{
                position: 'relative',
                height: '69px',
                top: 189,
                backgroundColor: '#f9fafb',
              }}
              justify="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                style={{
                  textTransform: 'none',
                  position: 'relative',
                  backgroundColor: '#ffffff',
                  color: '#f5802c',
                  width: '100px',
                  height: '36px',
                  margin: '17px 0px 16px 0px',
                }}
                onClick={() => {
                  props.close();
                  setUserServices({
                    serviceid: '',
                    experiencelevel: '',
                    hourlyrate: '',
                    currency: 'USD',
                    yearofexperience: '',
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  textTransform: 'none',
                  position: 'relative',
                  backgroundColor: '#f5802c',
                  color: '#FFFFFF',
                  width: '100px',
                  height: '36px',
                  margin: '17px 32px 16px 20px',
                }}
                disabled={
                  !userServices.experiencelevel ||
                  !userServices.hourlyrate ||
                  !userServices.serviceid ||
                  !userServices.yearofexperience
                }
                onClick={() => {
                  props.add(userServices);
                  props.servicesDetailstoDisplay({
                    ...userServices,
                    servicename: serviceName,
                  });
                  props.close();
                  props.showtable();
                  setUserServices({
                    serviceid: '',
                    experiencelevel: '',
                    hourlyrate: '',
                    currency: 'USD',
                    yearofexperience: '',
                  });
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
}
