/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import PlaceBid from '../../ServiceRequests/WorkOrderQueue/PlaceBid';
import { DataGridStyles } from '../../../data/DataGridStyles';

export default function MyBidsTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [edit, setEdit] = useState(true);
  const [editing, setEditing] = useState();
  const [openAssign, setOpenAssign] = useState(false);

  function CustomToolbar() {
    return (
      <Grid
        container
        justify="flex-end"
        alignContent="center"
        style={{
          height: '50px',
          borderBottom: 'solid 2px #cfd9e2',
        }}
      >
        <Button
          style={{ textTransform: 'none' }}
          onClick={() => setOpenAssign(true)}
          disabled={edit}
        >
          <Typography>Edit</Typography>
          <EditIcon fontSize="small" />
        </Button>
      </Grid>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
      hide: true,
      sortDirection: 'desc',
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 370,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 370,
      sortable: false,
    },
    {
      field: 'bidType',
      headerName: 'Bid Type',
      width: 130,
      sortable: false,
    },
    {
      field: 'bidPrice',
      headerName: 'Bid Price',
      width: 130,
    },

    {
      field: 'date',
      headerName: 'Date',
      width: 130,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      sortable: false,
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: [
            con.currency,
            con.offerprice,
            con.offertype,
            con.servicerequestid,
            con.servicerequestofferid,
            con.servicerequeststatus,
          ],
          title: con.servicerequesttitle,
          description: con.servicerequestdescription,
          bidType: con.offertype,
          bidPrice: `$ ${con.offerprice}`,
          date: new Date(con.created_at).toLocaleDateString('en-US'),
          status: con.servicerequeststatus,
        },
      ]);
    });
  }, []);

  return (
    <Grid
      container
      style={{ height: props.content.length * 52 + 116, width: '100%' }}
      className={classes.root}
    >
      <DataGrid
        autoHeight
        components={{
          header: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        hideFooter
        showToolbar
        onSelectionChange={(param) => {
          console.log(param);
          var req =
            param.rowIds.length > 0 &&
            param.rowIds[0].split(',').map((step) => step);
          console.log(param);
          param.rowIds.length === 1 && req[5] !== 'BidAccepted'
            ? (setEdit(false),
              setEditing({
                currency: req[0],
                offerPrice: req[1],
                offerType: req[2],
                serviceRequestId: req[3],
                serviceRequestOfferId: req[4],
              }))
            : setEdit(true);
        }}
      />

      {openAssign && (
        <PlaceBid
          open={openAssign}
          close={() => setOpenAssign(!openAssign)}
          edit={editing}
          refresh={() => props.refresh()}
        />
      )}
    </Grid>
  );
}
