import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  MenuItem,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

export default function AddServices(props) {
  const [userServices, setUserServices] = useState({
    serviceid: '',
    experiencelevel: '',
    hourlyrate: '',
    currency: 'USD',
    yearofexperience: '',
  });
  const [invalidServiceId, setInvalidServiceId] = useState(false);
  const [invalidExperienceLevel, setInvalidExperienceLevel] = useState(false);
  const [invalidHourlyRate, setInvalidHourlyRate] = useState(false);
  const [invalidYearsOfExperience, setInvalidYearsOfExperience] = useState(
    false
  );
  const [serviceName, setServiceName] = useState();
  const selectExperienceLevel = [
    { name: 'Beginner', value: 'Beginner' },
    { name: 'Expert', value: 'Expert' },
    { name: 'Intermediate', value: 'Intermediate' },
  ];

  return (
    props.addMore && (
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{
          width: '100%',
          height:
            invalidServiceId ||
            invalidExperienceLevel ||
            invalidYearsOfExperience ||
            invalidHourlyRate
              ? 70
              : 52,
          backgroundColor: '#f0f0f0',
          border: 'solid 2px #cfd9e2',
          padding: '0 1% 0 1%',
          marginBottom: 20,
        }}
      >
        <Autocomplete
          options={props.availableServices.sort((a, b) =>
            a.name > b.name ? 1 : -1
          )} //Sorting the items alphabetically
          getOptionLabel={(option) => option.name.toString()}
          style={{
            width: '21%',
            backgroundColor: '#ffffff',
            marginRight: '1%',
            padding: '0 4px 0 4px',
            maxHeight: 32,
          }}
          onChange={(event, value) =>
            value
              ? (setUserServices({
                  ...userServices,
                  serviceid: '' + value.serviceid,
                }),
                setServiceName(value.name),
                setInvalidServiceId(false))
              : (setUserServices({
                  ...userServices,
                  serviceid: value,
                }),
                setServiceName(value),
                setInvalidServiceId(false))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Service Offer"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              error={invalidServiceId}
              helperText={invalidServiceId ? 'Select Service' : null}
            />
          )}
        />
        <Autocomplete
          options={selectExperienceLevel}
          getOptionLabel={(option) => option.name}
          style={{
            width: '21%',
            backgroundColor: '#ffffff',
            marginRight: '1%',
            padding: '0 4px 0 4px',
            maxHeight: 32,
          }}
          onChange={(event, value) =>
            value
              ? (setUserServices({
                  ...userServices,
                  experiencelevel: value.value,
                }),
                setInvalidExperienceLevel(false))
              : (setUserServices({
                  ...userServices,
                  experiencelevel: value,
                }),
                setInvalidExperienceLevel(false))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Experience Level"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              error={invalidExperienceLevel}
              helperText={
                invalidExperienceLevel ? 'Select Experience Level' : null
              }
            />
          )}
        />

        <TextField
          style={{
            width: '21%',
            backgroundColor: '#ffffff',
            marginRight: '1%',
            padding: '0 4px 0 4px',
            maxHeight: 32,
          }}
          InputProps={{ disableUnderline: true }}
          placeholder="Years of experience"
          value={userServices.yearofexperience}
          onChange={(event) => {
            setUserServices({
              ...userServices,
              yearofexperience: event.target.value,
            });
            setInvalidYearsOfExperience(false);
          }}
          error={invalidYearsOfExperience}
          helperText={invalidYearsOfExperience ? 'Please fill' : null}
        />
        <TextField
          style={{
            width: '21%',
            backgroundColor: '#ffffff',
            marginRight: '1%',
            padding: '0 4px 0 4px',
            maxHeight: 32,
          }}
          placeholder="Hourly rate"
          value={userServices.hourlyrate}
          onChange={(event) => {
            setUserServices({
              ...userServices,
              hourlyrate: event.target.value,
            });
            setInvalidHourlyRate(false);
          }}
          error={invalidHourlyRate}
          helperText={invalidHourlyRate ? 'Please fill' : null}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment>
                <TextField
                  select
                  InputProps={{ disableUnderline: true }}
                  value={'USD'}
                  disabled
                >
                  <MenuItem value="USD">$</MenuItem>
                </TextField>
              </InputAdornment>
            ),
          }}
        />
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
          style={{ width: '10%', marginRight: 10 }}
        >
          <IconButton
            style={{
              color: '#219653',
              backgroundColor: '#ffffff',
              width: 32,
              height: 32,
            }}
            onClick={() => {
              !userServices.serviceid && setInvalidServiceId(true);
              !userServices.experiencelevel && setInvalidExperienceLevel(true);
              !userServices.yearofexperience &&
                setInvalidYearsOfExperience(true);
              !userServices.hourlyrate && setInvalidHourlyRate(true);

              userServices.serviceid &&
                !invalidServiceId &&
                userServices.experiencelevel &&
                !invalidExperienceLevel &&
                userServices.yearofexperience &&
                !invalidYearsOfExperience &&
                userServices.hourlyrate &&
                !invalidHourlyRate &&
                (props.add(userServices),
                props.servicesDetailstoDisplay({
                  ...userServices,
                  servicename: serviceName,
                }),
                props.close(),
                setUserServices({
                  serviceid: '',
                  experiencelevel: '',
                  hourlyrate: '',
                  currency: 'USD',
                  yearofexperience: '',
                }));
            }}
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            style={{
              color: '#eb5757',
              backgroundColor: '#ffffff',
              width: 32,
              height: 32,
            }}
            onClick={() => {
              setInvalidServiceId(false);
              setInvalidExperienceLevel(false);
              setInvalidYearsOfExperience(false);
              setInvalidHourlyRate(false);
              props.close();
              setUserServices({
                serviceid: '',
                experiencelevel: '',
                hourlyrate: '',
                currency: 'USD',
                yearofexperience: '',
              });
            }}
          >
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
    )
  );
}
