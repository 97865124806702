import React, { Component } from 'react';
import * as QueryString from 'query-string';
import {
  TextField,
  Grid,
  Button,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { Ip } from '../data/ip';
import validator from 'validator';
import tick from '../assets/Images/tixk-circle.svg';
import Info from './Registration/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const queries = QueryString.parse(this.props.location.search);

    this.state = {
      newPassword: '',
      confirmPassword: '',
      resetToken: queries.resettoken,

      validPassword: true,
      validConfirmPassword: true,
      showPassword: false,
      changed: false,
    };

    this.newPassword = this.newPassword.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
    this.submit = this.submit.bind(this);
  }

  newPassword(event) {
    validator.matches(
      event.target.value,
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
    )
      ? this.setState({
          newPassword: event.target.value,
          validPassword: true,
        })
      : this.setState({
          newPassword: event.target.value,
          validPassword: false,
        });
  }

  confirmPassword(event) {
    event.target.value === this.state.newPassword
      ? this.setState({
          confirmPassword: event.target.value,
          validConfirmPassword: true,
        })
      : this.setState({
          confirmPassword: event.target.value,
          validConfirmPassword: false,
        });
  }

  submit(event) {
    const body = {
      newPassword: this.state.newPassword,
      resetToken: this.state.resetToken,
    };
    console.log({ body });
    fetch(`${Ip}/resetpassword`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((Result) => {
        console.log(Result.status);
        console.log(Result.ok);
        if (Result.status == 200 || Result.ok) {
          this.setState({ changed: true });
        } else {
          alert(JSON.stringify(Result, null, 2));
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    const defaultProps = {
      bgcolor: 'background.paper',
      borderColor: 'text.primary',
      m: 1,
      border: 1,
      style: { width: '30rem', height: '26rem' },
    };

    return (
      console.log(
        this.state.newPassword,
        this.state.confirmPassword,
        !this.state.validPassword
      ),
      (
        <Paper>
          <Info />
          <Grid
            container
            direction="column"
            style={{
              position: 'absolute',
              height: '100%',
              width: '70%',
              transform: 'translateX(+42%)',
            }}
          >
            {this.state.changed ? (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                style={{
                  height: '100%',
                }}
              >
                <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>
                  Your password changed successfully
                  <img src={tick} />
                </Typography>
                <Button
                  variant="contained"
                  style={{
                    width: '200px',
                    textTransform: 'none',
                    position: 'relative',
                    top: 20,
                    backgroundColor: '#f5802c',
                    color: '#FFFFFF',
                    padding: '12px',
                  }}
                  href="/login"
                >
                  <Typography>Continue to Login</Typography>
                </Button>
              </Grid>
            ) : (
              <Grid
                container
                direction="column"
                alignContent="flex-start"
                style={{
                  position: 'fixed',
                  top: '40%',
                  left: '30%',
                  width: '70%',
                }}
              >
                <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>
                  Choose your new password
                </Typography>
                <Typography
                  variant="body1"
                  style={{ position: 'relative', top: 20 }}
                >
                  New Password
                </Typography>
                <TextField
                  required
                  type="password"
                  placeholder="Enter new password"
                  variant="outlined"
                  size="small"
                  style={{ position: 'relative', top: 25, width: '57%' }}
                  value={this.state.newPassword}
                  onChange={this.newPassword}
                  error={
                    !this.state.validPassword && this.state.newPassword !== ''
                      ? true
                      : !this.state.validPassword
                  }
                  helperText={
                    !this.state.validPassword && this.state.newPassword !== ''
                      ? 'Password must have atleast 8 characters with one digit, one upper case letter, one lower case letter and one special character'
                      : !this.state.validPassword
                      ? 'Password should not be null'
                      : null
                  }
                />
                <Typography
                  variant="body1"
                  style={{ position: 'relative', top: 40 }}
                >
                  Confirm Password
                </Typography>

                <TextField
                  required
                  type={this.state.showPassword ? 'text' : 'password'}
                  variant="outlined"
                  placeholder="Confirm password"
                  size="small"
                  style={{ position: 'relative', top: 45, width: '57%' }}
                  value={this.state.confirmPassword}
                  onChange={this.confirmPassword}
                  error={
                    !this.state.validConfirmPassword &&
                    this.state.confirmPassword !== ''
                      ? true
                      : !this.state.validConfirmPassword
                  }
                  helperText={
                    !this.state.validConfirmPassword &&
                    this.state.confirmPassword !== ''
                      ? 'password and confirm password must be same'
                      : !this.state.validConfirmPassword
                      ? 'Confirm Password should not be null'
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ whiteSpace: 'pre' }}>
                        <IconButton
                          onClick={() => {
                            this.setState((prevState) => ({
                              showPassword: !prevState.showPassword,
                            }));
                          }}
                          style={{
                            height: 35,
                            width: 35,
                          }}
                          color={this.state.showPassword ? 'primary' : 'none'}
                        >
                          {this.state.showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  style={{
                    width: '57%',
                    textTransform: 'none',
                    position: 'relative',
                    top: 70,
                    backgroundColor: '#f5802c',
                    color: '#FFFFFF',
                    padding: '12px',
                  }}
                  onClick={() => {
                    this.state.newPassword === '' &&
                      this.setState({ validPassword: false });
                    this.state.confirmPassword === '' &&
                      this.setState({ validConfirmPassword: false });

                    this.state.newPassword &&
                      this.state.validPassword &&
                      this.state.confirmPassword &&
                      this.state.validConfirmPassword &&
                      this.submit();
                  }}
                >
                  <Typography style={{ fontSize: 16 }}>Submit</Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      )
    );
    // return (
    //   <div>
    //     <form name="myForm3" autoComplete="on">
    //       <Grid
    //         container
    //         justify="center"
    //         direction="column"
    //         spacing={2}
    //         alignItems="center"
    //       >
    //         <Box
    //           bgcolor="background.paper"
    //           borderColor="text.primary"
    //           mt={1}
    //           style={{ height: '7rem' }}
    //         ></Box>
    //         <Box
    //           borderColor="primary.main"
    //           borderRadius={16}
    //           alignItems="center"
    //           justifyContent="center"
    //           align="center"
    //           {...defaultProps}
    //         >
    //           <Grid item justify="center" alignItems="center" xs={12}>
    //             <Typography variant="h4" align="center">
    //               <b>RESET PASSWORD</b>
    //             </Typography>
    //           </Grid>

    //           <Box
    //             align="center"
    //             display="flex"
    //             alignItems="center"
    //             justifyContent="center"
    //             mt={8}
    //             p={1}
    //           >
    //             <TextField
    //               required
    //               type="password"
    //               label="New Password"
    //               variant="outlined"
    //               margin="dense"
    //
    //             />
    //           </Box>
    //           <Box
    //             align="center"
    //             display="flex"
    //             alignItems="center"
    //             justifyContent="center"
    //             p={1}
    //           >
    //             <TextField
    //               required
    //               type="password"
    //               label="Confirm Password"
    //               variant="outlined"
    //               margin="dense"
    //               value={this.state.confirmPassword}
    //               onChange={this.confirmPassword}
    //               error={
    //                 this.state.confirmPassword !== this.state.newPassword &&
    //                 this.state.confirmPassword !== ''
    //               }
    //               helperText={
    //                 this.state.confirmPassword.length < 8 &&
    //                 this.state.confirmPassword !== ''
    //                   ? 'password and confirm password must be same'
    //                   : null
    //               }
    //             />
    //           </Box>

    //           <Box
    //             display="flex"
    //             alignItems="center"
    //             justifyContent="center"
    //             p={1}
    //             bgcolor="background.paper"
    //           >
    //             <Button
    //               variant="contained"
    //               color="primary"
    //               onClick={this.submit}
    //               disabled={
    //                 !this.state.validPassword ||
    //                 this.state.newPassword !== this.state.confirmPassword
    //               }
    //               centerRipple
    //             >
    //               Submit
    //             </Button>
    //           </Box>
    //         </Box>
    //       </Grid>
    //     </form>
    //   </div>
    // );
  }
}

export default ResetPassword;
