import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, InputAdornment } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';

export default function TypeMessageField(props) {
  const [chatMessage, setChatMessage] = useState('');

  return (
    <Grid container direction="row" justify="space-around">
      <TextField
        name="message"
        id="message"
        placeholder="type message..."
        variant="outlined"
        margin="dense"
        fullWidth
        autoFocus
        autoComplete="off"
        style={{ padding: '0 3px' }}
        value={chatMessage}
        onChange={(event) => {
          setChatMessage(event.target.value);
        }}
        onKeyPress={(event) => {
          var code;
          if (event.key !== undefined) {
            code = event.key;
          } else if (event.keyIdentifier !== undefined) {
            code = event.keyIdentifier;
          } else if (event.keyCode !== undefined) {
            code = event.keyCode;
          }
          if (code === 13 || code === 'Enter') {
            props.sendMessage(event.target.value);
            setChatMessage('');
          }
        }}
        InputProps={{
          style: { borderRadius: `25px`, fontSize: 12.5 },
          endAdornment: (
            <InputAdornment
              style={{ whiteSpace: 'pre', cursor: 'pointer', color: '#f5802c' }}
              onClick={() => {
                props.sendMessage(chatMessage);
                setChatMessage('');
              }}
            >
              <SendIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
}
