import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import tick from '../../assets/Images/checkIcon.svg';
import axios from 'axios';
import { Ip } from '../../data/ip';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';

export default function SubscriptionModel() {
  let history = useHistory();
  const [monthly, setMonthly] = useState(true);
  const [yearly, setYearly] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`${Ip}/subscriptionplan`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(
            response.data.sort(
              (a, b) => a.subscriptionplanid - b.subscriptionplanid
            )
          );
          console.log(response.data);
        }
      });
  }, []);
  return data.length > 0 ? (
    <Grid container direction="column" alignItems="center">
      <Typography align="center" style={{ fontSize: 40, marginTop: 31 }}>
        Simple, Transparent Subscription
      </Typography>
      <Typography
        align="center"
        style={{ fontSize: 20, color: '#848199', marginTop: 15 }}
      >
        No contracts. No surprise fees.
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{
          backgroundColor: '#ffffff',
          width: 201,
          height: 32,
          borderRadius: 25,
          marginTop: 15,
        }}
      >
        <Button
          variant={monthly ? 'contained' : 'text'}
          color="primary"
          style={{
            width: '50%',
            textTransform: 'none',
            color: monthly ? '#ffffff' : '#848199',
            maxHeight: 32,
            borderRadius: 25,
          }}
          onClick={() => {
            setMonthly(true), setYearly(false);
          }}
        >
          Monthly
        </Button>
        <Button
          variant={yearly ? 'contained' : 'text'}
          color="primary"
          style={{
            width: '50%',
            textTransform: 'none',
            color: yearly ? '#ffffff' : '#848199',
            maxHeight: 32,
            borderRadius: 25,
          }}
          onClick={() => {
            setYearly(true), setMonthly(false);
          }}
        >
          Yearly
        </Button>
      </Grid>
      <Typography
        align="center"
        style={{
          position: 'absolute',
          top: 325,
          width: 121,
          height: 27,
          color: '#ffffff',
          backgroundColor: '#f5802c',
          borderRadius: 4,
          fontSize: 12,
          padding: 4,
          letterSpacing: 0.83,
        }}
      >
        Most Popular
      </Typography>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        style={{ width: '80%', marginTop: 50, marginBottom: 30 }}
      >
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{
            width: '30%',
            borderRadius: 8,
            padding: '0 37px 0 37px',
            backgroundColor: '#ffffff',
          }}
        >
          <Grid>
            <Typography style={{ fontSize: 17, margin: '40px 0 20px 0' }}>
              <span
                style={{
                  color: '#f5802c',
                  fontSize: 20,
                  fontWeight: 300,
                  textDecorationLine: 'line-through',
                }}
              >
                {monthly ? `$${data[0].amount * 2}` : `$${data[1].amount * 2}`}
              </span>
              <span style={{ color: '#f5802c', fontSize: 36, fontWeight: 600 }}>
                {monthly ? `$${data[0].amount}` : `$${data[1].amount}`}
              </span>
              /{monthly ? 'month' : 'year'}
              {/* <sup style={{ fontSize: 19, color: '#f54748' }}>50% off</sup> */}
            </Typography>

            <Typography style={{ fontSize: 24 }}>Basic</Typography>
            <Typography
              style={{ fontSize: 15, color: '#848199', marginBottom: 34 }}
            >
              Lorem Ipsum is simply dummy text of the printing
            </Typography>
            <Feature feature="Feature 1" />
            <Feature feature="Feature 2" />
            <Feature feature="Feature 3" />
            <Feature feature="Feature 4" />
            <Feature feature="Feature 5" />
          </Grid>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            style={{
              textTransform: 'none',
              fontSize: 15,
              marginBottom: 14,
              marginTop: 30,
            }}
            onClick={() => {
              history.push('/payment'),
                localStorage.setItem(
                  'SubscriptionDetails',
                  JSON.stringify({
                    subscriptionplanid: monthly
                      ? `${data[0].subscriptionplanid}`
                      : `${data[1].subscriptionplanid}`,
                    status: monthly ? 'BasicMonthly' : 'BasicYearly',
                    name: 'Basic',
                    price: monthly
                      ? `$${data[0].amount}`
                      : `$${data[1].amount}`,
                    period: monthly ? 'month' : 'year',
                  })
                );
            }}
          >
            Choose Plan
          </Button>
        </Grid>

        <Grid
          container
          direction="column"
          justify="space-between"
          style={{
            width: '30%',
            borderRadius: 8,
            padding: '0 37px 0 37px',
            backgroundColor: '#ffffff',
          }}
        >
          <Grid>
            <Typography style={{ fontSize: 17, margin: '40px 0 20px 0' }}>
              <span
                style={{
                  color: '#f5802c',
                  fontSize: 20,
                  fontWeight: 300,
                  textDecorationLine: 'line-through',
                }}
              >
                {monthly ? `$${data[2].amount * 2}` : `$${data[3].amount * 2}`}
              </span>
              <span style={{ color: '#f5802c', fontSize: 36, fontWeight: 600 }}>
                {monthly ? `$${data[2].amount}` : `$${data[3].amount}`}
              </span>
              /{monthly ? 'month' : 'year'}
              {/* <sup style={{ fontSize: 19, color: '#f54748' }}>60% off</sup> */}
            </Typography>
            <Typography style={{ fontSize: 24 }}>Intermediate</Typography>
            <Typography
              style={{ fontSize: 15, color: '#848199', marginBottom: 34 }}
            >
              Lorem Ipsum is simply dummy text of the printing
            </Typography>
            <Feature feature="Feature 1" />
            <Feature feature="Feature 2" />
            <Feature feature="Feature 3" />
            <Feature feature="Feature 4" />
            <Feature feature="Feature 5" />
            <Feature feature="Feature 6" />
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{
              textTransform: 'none',
              fontSize: 15,
              marginBottom: 14,
              marginTop: 30,
              color: '#ffffff',
            }}
            onClick={() => {
              history.push('/payment'),
                localStorage.setItem(
                  'SubscriptionDetails',
                  JSON.stringify({
                    subscriptionplanid: monthly
                      ? `${data[2].subscriptionplanid}`
                      : `${data[3].subscriptionplanid}`,
                    status: monthly
                      ? 'IntermediateMonthly'
                      : 'IntermediateYearly',
                    name: 'Intermediate',
                    price: monthly
                      ? `$${data[2].amount}`
                      : `$${data[3].amount}`,
                    period: monthly ? 'month' : 'year',
                  })
                );
            }}
          >
            Choose Plan
          </Button>
        </Grid>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{
            width: '30%',
            borderRadius: 8,
            padding: '0 37px 0 37px',
            backgroundColor: '#ffffff',
          }}
        >
          <Grid>
            <Typography style={{ fontSize: 17, margin: '40px 0 20px 0' }}>
              <span
                style={{
                  color: '#f5802c',
                  fontSize: 20,
                  fontWeight: 300,
                  textDecorationLine: 'line-through',
                }}
              >
                {monthly ? `$${data[4].amount * 2}` : `$${data[5].amount * 2}`}
              </span>
              <span style={{ color: '#f5802c', fontSize: 36, fontWeight: 600 }}>
                {monthly ? `$${data[4].amount}` : `$${data[5].amount}`}
              </span>
              /{monthly ? 'month' : 'year'}
              {/* <sup style={{ fontSize: 19, color: '#f54748' }}>50% off</sup> */}
            </Typography>
            <Typography style={{ fontSize: 24 }}>Ultimate</Typography>
            <Typography
              style={{ fontSize: 15, color: '#848199', marginBottom: 34 }}
            >
              Lorem Ipsum is simply dummy text of the printing
            </Typography>
            <Feature feature="Feature 1" />
            <Feature feature="Feature 2" />
            <Feature feature="Feature 3" />
            <Feature feature="Feature 4" />
            <Feature feature="Feature 5" />
            <Feature feature="Feature 6" />
            <Feature feature="Feature 7" />
            <Feature feature="Feature 8" />
          </Grid>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            style={{
              textTransform: 'none',
              fontSize: 15,
              marginBottom: 14,
              marginTop: 30,
            }}
            onClick={() => {
              history.push('/payment'),
                localStorage.setItem(
                  'SubscriptionDetails',
                  JSON.stringify({
                    subscriptionplanid: monthly
                      ? `${data[4].subscriptionplanid}`
                      : `${data[5].subscriptionplanid}`,
                    status: monthly ? 'UltimateMonthly' : 'UltimateYearly',
                    name: 'Ultimate',
                    price: monthly
                      ? `$${data[4].amount}`
                      : `$${data[5].amount}`,
                    period: monthly ? 'month' : 'year',
                  })
                );
            }}
          >
            Choose Plan
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <CircularProgressIndicator open={data.length === 0} />
  );
}

function Feature(props) {
  return (
    <Typography style={{ marginBottom: 16, color: '#2f3949', fontSize: 15 }}>
      {<img src={tick} style={{ marginRight: 10 }} />}
      {props.feature}
    </Typography>
  );
}
