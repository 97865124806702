// import React, { useEffect, useState } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   IconButton,
//   Typography,
//   InputBase,
//   fade,
//   makeStyles,
//   Button,
//   Box,
//   Grid,
//   Select,
//   Link,
// } from '@material-ui/core';
// import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
// import ServiceRequestButton from './ServiceRequestsButton';
// import ProfileButton from './ProfileButton';
// import BidsButton from './BidsButton';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//     display: 'none',
//     [theme.breakpoints.up('sm')]: {
//       display: 'block',
//     },
//   },
//   search: {
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: fade(theme.palette.common.white, 0.15),
//     '&:hover': {
//       backgroundColor: fade(theme.palette.common.white, 0.25),
//     },
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       marginLeft: theme.spacing(1),
//       width: 'auto',
//     },
//   },
//   searchIcon: {
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   inputRoot: {
//     color: 'inherit',
//   },
//   inputInput: {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//     transition: theme.transitions.create('width'),
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//       width: '12ch',
//       '&:focus': {
//         width: '20ch',
//       },
//     },
//   },
// }));

// export default function SearchAppBar() {
//   const classes = useStyles();

//   return localStorage.getItem('ResetToken') ? (
//     <div className={classes.root}>
//       <AppBar position="relative">
//         <Toolbar>
//           <IconButton
//             edge="start"
//             className={classes.menuButton}
//             color="inherit"
//             aria-label="open drawer"
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography className={classes.title} variant="h6" noWrap>
//             <Link style={{ textDecoration: 'none', color: 'inherit' }} href="/">
//               ServeX
//             </Link>
//           </Typography>
//           <div className={classes.search}>
//             <div className={classes.searchIcon}>
//               <SearchIcon />
//             </div>
//             <InputBase
//               placeholder="Search…"
//               classes={{
//                 root: classes.inputRoot,
//                 input: classes.inputInput,
//               }}
//               inputProps={{ 'aria-label': 'search' }}
//             />
//           </div>
//           <ProfileButton />
//         </Toolbar>
//       </AppBar>

//       {localStorage.getItem('ResetToken') && (
//         <Grid container justify="flex-start">
//           <ServiceRequestButton />
//           <BidsButton />
//           <Box pl={1} pt={1}>
//             <Button
//               style={{
//                 textTransform: 'none',
//                 outline: 0,
//                 borderRadius: 20,
//                 backgroundColor: '#E8F0FE',
//               }}
//               href="/contracts"
//             >
//               <Typography>Contracts</Typography>
//             </Button>
//           </Box>
//           <Box pl={1} pt={1}>
//             <Button
//               style={{
//                 textTransform: 'none',
//                 outline: 0,
//                 borderRadius: 20,
//                 backgroundColor: '#E8F0FE',
//               }}
//               href="/subscription"
//             >
//               <Typography>Subscription</Typography>
//             </Button>
//           </Box>
//         </Grid>
//       )}
//     </div>
//   ) : (
//     <div></div>
//   );
// }

import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  fade,
  makeStyles,
  Button,
  Box,
  Grid,
  Select,
  Link,
} from '@material-ui/core';
import { createBrowserHistory } from 'history';
import SearchIcon from '@material-ui/icons/Search';
import ServiceRequestButton from './ServiceRequestsButton';
import ProfileButton from './ProfileButton';
import BidsButton from './BidsButton';
import ContractsButton from './ContractsButton';
import servex from '../../assets/Images/NavbarServeX.svg';
import question from '../../assets/Images/Question-icon.svg';
import notification2 from '../../assets/Images/notification-icn-2.svg';

export default function Navbar() {
  const history = createBrowserHistory();
  const profile = ['/profile'].includes(history.location.pathname);
  const home = ['/'].includes(history.location.pathname);
  const createservicerequest = ['/createservicerequest'].includes(
    history.location.pathname
  );
  const myservicerequests = ['/myservicerequests'].includes(
    history.location.pathname
  );
  const workorderqueue = ['/workorderqueue'].includes(
    history.location.pathname
  );
  const directrequests = ['/directrequests'].includes(
    history.location.pathname
  );
  const mybids = ['/mybids'].includes(history.location.pathname);
  const offeredbids = history.location.pathname.includes('offeredbids');
  const contracts = ['/contracts/provider', '/contracts/consumer'].includes(
    history.location.pathname
  );
  const subscription = ['/subscription'].includes(history.location.pathname);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
      style={{ width: '100%', backgroundColor: '#ffffff' }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        alignContent="center"
        style={{ width: '88.2%', height: '50px' }}
      >
        <Link style={{ textDecoration: 'none', color: 'inherit' }} href="/">
          <img src={servex} />
        </Link>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ width: '100px' }}
        >
          <img src={notification2} />
          <img src={question} />
          <ProfileButton />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        style={{ width: '100%', backgroundColor: '#2f3949' }}
      >
        {localStorage.getItem('ResetToken') && !profile && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
            style={{ width: '88.2%', height: '58px' }}
          >
            <Grid container justify="flex-start">
              <Button
                style={{
                  textTransform: 'none',
                  color: '#ffffff',
                  borderRadius: 0,
                  borderBottom: home ? '3px solid #f5802c' : null,
                  paddingBottom: home ? 13 : 16,
                  margin: '0 15px 0px 14px',
                  opacity: home ? 1 : 0.7,
                }}
                href="/"
              >
                <Typography>Dashboard</Typography>
              </Button>

              <ServiceRequestButton
                border={
                  createservicerequest ||
                  myservicerequests ||
                  workorderqueue ||
                  directrequests
                }
              />

              <BidsButton border={mybids || offeredbids} />
              <ContractsButton border={contracts} />

              <Button
                style={{
                  textTransform: 'none',
                  color: '#ffffff',
                  borderRadius: 0,
                  borderBottom: subscription ? '3px solid #f5802c' : null,
                  paddingBottom: subscription ? 13 : 16,
                  marginRight: 15,
                  opacity: subscription ? 1 : 0.7,
                }}
                href="/subscription"
              >
                <Typography>Subscription</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
