import React, { useState, useEffect } from 'react';
import {
  Grid,
  Dialog,
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { Ip } from '../../../data/ip';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';

export default function OfferService(props) {
  const [currency, setCurrency] = useState(
    props.edit ? props.edit.currency : ''
  );
  const [offerPrice, setOfferPrice] = useState(
    props.edit ? props.edit.offerPrice : ''
  );
  const [offerType, setOfferType] = useState(
    props.edit ? props.edit.offerType : ''
  );

  const [err, setErr] = useState(false);

  const [load, setLoad] = useState(false);

  function handleServiceOffer() {
    setLoad(true);
    const body = {
      servicerequestofferid: props.edit ? props.edit.serviceRequestOfferId : '',
      currency: 'USD',
      offerprice: offerPrice,
      offertype: offerType,
      servicerequest: {
        servicerequestid: props.edit ? props.edit.serviceRequestId : props.id,
      },
    };
    console.log(JSON.stringify(body, null, 2));
    fetch(`${Ip}/servicerequestoffer`, {
      method: props.edit ? 'put' : 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((Response) => {
        if (Response.status === 200) {
          props.close();
          props.refresh();
          setLoad(false);
        }
        if (Response.status === 500) {
          setErr(true);
          setLoad(false);
        }
        console.log(Response.body);
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  return (
    <Dialog open={props.open} onClose={props.close}>
      <Box style={{ height: 'auto', width: '400px' }}>
        <CircularProgressIndicator open={load} />
        {err ? (
          <Grid container direction="column" alignItems="center">
            <Box p={2}>
              <Typography align="justify">
                {`An offer made by you already exists! You can't make another offer on
            the same service request! Instead you can edit the bid in MyBids
            section.`}
              </Typography>
            </Box>
            <Box mb={2}>
              <Button variant="outlined" onClick={() => props.close()}>
                OK
              </Button>
            </Box>
          </Grid>
        ) : (
          <Grid container direction="column" alignItems="center">
            <Box mt={2} mb={1}>
              <Typography variant="h4">Offer Service</Typography>
            </Box>

            <TextField
              required
              name="offerprice"
              type="tel"
              variant="outlined"
              margin="dense"
              label="Offer Price"
              autoComplete="off"
              onChange={(event) => setOfferPrice(event.target.value)}
              style={{ width: '204.8px' }}
              value={offerPrice}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <TextField
                      select
                      InputProps={{ disableUnderline: true }}
                      value={'USD'}
                      disabled
                    >
                      <MenuItem value="USD">$</MenuItem>
                    </TextField>
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <TextField
              required
              name="Offertype"
              type="text"
              variant="outlined"
              margin="dense"
              label="Offer Type"
              onChange={(event) => setOfferType(event.target.value)}
              select
              style={{ width: '204.8px' }}
              value={offerType}
            >
              <MenuItem value="Fixed">Fixed</MenuItem>
              <MenuItem value="Hourly">Hourly</MenuItem>
            </TextField>
            <Box mt={1} mb={2}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#f5802c',
                  color: '#FFFFFF',
                  textTransform: 'none',
                }}
                onClick={handleServiceOffer}
              >
                save
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    </Dialog>
  );
}
