import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, TextField } from '@material-ui/core';
import DisplayHeading from './DisplayComponents/DisplayHeading';
import ChangeAddress from './ChangeAddress';
import { Ip } from '../../data/ip';
import tixkcircle from '../../assets/Images/tixk-circle.svg';
import validator from 'validator';

export default function ProfileDetails(props) {
  const [changeAddress, setChangeAddress] = useState(false);
  const [enterOtpValidation, setEnterOtpValidation] = useState(false);
  const [invalidOtp, setInValidOtp] = useState(false);
  const [otp, setOtp] = useState('');

  function sendOTP() {
    fetch(`${Ip}/sms`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify({ phoneid: `${props.phoneId}` }),
    })
      .then((Response) => {
        if (Response.status === 200) {
          setEnterOtpValidation(true);
        }
      })
      .catch((err) => {});
  }

  function ValidateOTP() {
    fetch(`${Ip}/otp?otp=${otp}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: null,
    })
      .then((Response) => {
        if (Response.status === 200) {
          window.location.href = '/profile';
        } else {
          setInValidOtp(true);
        }
      })
      .catch((err) => {});
  }

  return (
    <Grid style={{ width: '100%' }}>
      <Grid
        container
        direction="column"
        style={{ backgroundColor: '#ffffff', marginBottom: 25 }}
      >
        <DisplayHeading
          DisplayHeadingName="Profile"
          caption="Basic Info, for a faster search experience"
        />

        <Grid style={{ margin: '0 25px 0 25px' }}>
          <DisplayContent type="First Name:" data={props.fname} />
          {props.mname && (
            <DisplayContent type="Middle Name:" data={props.mname} />
          )}
          {props.lname && (
            <DisplayContent type="Last Name:" data={props.lname} />
          )}
          <DisplayContent type="Languages known:" data={`Hindi, English`} />
          {/* <DisplayContent type="Gender:" data={`Male`} /> */}
          <DisplayContent type="Payment type:" data={`Card`} last={true} />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        style={{ backgroundColor: '#ffffff', marginBottom: 25 }}
      >
        <DisplayHeading
          DisplayHeadingName="Login Details"
          caption="Basic Info, for a faster search experience"
        />

        <Grid style={{ margin: '0 25px 0 25px' }}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignContent="center"
            style={{
              height: 37,
              borderBottom: '1px solid #cfd9e2',
              marginBottom: 20,
            }}
          >
            <Grid container direction="row" style={{ width: 400 }}>
              <Typography
                style={{
                  fontSize: 14,
                  color: '#8692a6',
                  width: 140,
                  marginBottom: '20px',
                }}
              >
                Email Id:
              </Typography>
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                {props.email}
              </Typography>
            </Grid>
            <img
              src={tixkcircle}
              width="20px"
              style={{ marginRight: '25px', marginBottom: '20px' }}
            />
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignContent="flex-start"
            style={{
              height: 37,
              borderBottom: '1px solid #cfd9e2',
              marginBottom: 20,
            }}
          >
            <Grid container direction="row" style={{ width: 400 }}>
              <Typography
                style={{ fontSize: 14, color: '#8692a6', width: 140 }}
              >
                Mobile Number:
              </Typography>
              <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                {props.mobileNumber}
              </Typography>
            </Grid>

            {props.isPhoneVerified ? (
              <img
                src={tixkcircle}
                width="20px"
                style={{ marginRight: '25px', marginBottom: '20px' }}
              />
            ) : (
              !enterOtpValidation && (
                <Button
                  style={{
                    textTransform: 'none',
                    color: 'green',
                    width: 100,
                    padding: 0,
                    lineHeight: 0,
                  }}
                  onClick={() => {
                    sendOTP();
                    setEnterOtpValidation(true);
                  }}
                >
                  Verify
                </Button>
              )
            )}
          </Grid>

          {enterOtpValidation && (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignContent="flex-start"
              style={{
                height: 37,
                borderBottom: '1px solid #cfd9e2',
                marginBottom: 20,
              }}
            >
              <Grid container direction="row" style={{ width: '60%' }}>
                <Typography
                  style={{
                    fontSize: 14,
                    color: '#8692a6',
                    width: 140,
                  }}
                >
                  Enter OTP:
                </Typography>
                <TextField
                  name="otp"
                  id="otp"
                  type="text"
                  placeholder="Please Enter otp"
                  autoFocus
                  inputProps={{
                    style: {
                      fontSize: 14,
                      color: invalidOtp ? 'red' : '',
                      padding: 2,
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={otp}
                  onChange={(event) => {
                    if (
                      (validator.isNumeric(event.target.value) ||
                        validator.isEmpty(event.target.value)) &&
                      event.target.value.length <= 6
                    ) {
                      setOtp(event.target.value);
                      setInValidOtp(false);
                    }
                  }}
                />
                {invalidOtp && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    Invalid OTP
                  </Typography>
                )}
              </Grid>
              <Button
                style={{
                  textTransform: 'none',
                  color: 'green',
                  width: 100,
                  padding: 0,
                  lineHeight: 0,
                }}
                onClick={() => {
                  ValidateOTP();
                }}
              >
                Submit
              </Button>
            </Grid>
          )}
          <DisplayContent type="Password:" data={`******`} last={true} />
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        style={{ backgroundColor: '#ffffff', marginBottom: 25 }}
      >
        <DisplayHeading
          DisplayHeadingName="Contact Details"
          caption="Basic Info, for a faster search experience"
          button={props.addressLength === 0 ? 'Add Address' : 'Edit'}
          clicked={() => {
            setChangeAddress(true);
          }}
        />

        <Grid style={{ margin: '0 25px 0 25px' }}>
          <DisplayContent type="Address Type:" data={props.addresstype} />
          <DisplayContent type="Address Line 1:" data={props.addressline1} />
          <DisplayContent type="Address Line 2:" data={props.addressline2} />
          <DisplayContent type="City:" data={props.city} />
          <DisplayContent type="State:" data={props.state} />
          <DisplayContent type="Country:" data={props.country} />
          <DisplayContent type="Zipcode:" data={props.zip} last={true} />
        </Grid>
      </Grid>
      <ChangeAddress
        open={changeAddress}
        close={() => setChangeAddress(false)}
        refresh={() => props.refresh()}
        load={() => props.load()}
      />
    </Grid>
  );
}

function DisplayContent(props) {
  return (
    <Grid
      container
      direction="row"
      style={{
        height: 37,
        borderBottom: props.last ? null : '1px solid #cfd9e2',
        marginBottom: props.last ? null : 20,
      }}
    >
      <Typography style={{ fontSize: 14, color: '#8692a6', width: 140 }}>
        {props.type}
      </Typography>
      <Typography style={{ fontSize: 14, fontWeight: 500 }}>
        {props.data}
      </Typography>
    </Grid>
  );
}
