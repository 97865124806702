import React, { Component } from 'react';
import {
  Typography,
  TextField,
  Grid,
  Button,
  Link,
  Paper,
  Checkbox,
  withStyles,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Ip } from '../../../data/ip';
import validator from 'validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Info from '../Info';
import ConfirmDetails from './ConfirmDetails';
import Pdf from '../../../assets/T&C.pdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';
import GoHome from '../../../components/GoHome';
const useStyles = (theme) => ({
  remember: {
    fontSize: 14,
    [theme.breakpoints.up('1293')]: {
      transform: 'translateX(-6%)',
    },
  },
});

class Register1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progressIndicator: false,
      fname: '',
      mname: '',
      lname: '',
      email: '',
      password: '',
      confirmPassword: '',
      phonenumber: '',
      countrycode: '',
      country: '',
      phonetype: '',
      showPassword: false,
      agree: false,
      agreeVerfication: false,
      proceedToConfirmDetails: false,

      validFname: true,
      validMname: true,
      validLname: true,
      validEmail: true,
      validPassword: true,
      validPhonenumber: true,
      validTenDigitNumber: true,
      validConfirmPassword: true,
      existingUser: false,
    };
    this.fname = this.fname.bind(this);
    this.mname = this.mname.bind(this);
    this.lname = this.lname.bind(this);
    this.email = this.email.bind(this);
    this.password = this.password.bind(this);
    this.phonenumber = this.phonenumber.bind(this);
    this.countrycode = this.countrycode.bind(this);
    this.phonetype = this.phonetype.bind(this);
    this.register = this.register.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
  }

  componentDidMount() {
    axios
      .get('https://ipapi.co/json/')
      .then((response) => {
        console.log(response.data);
        let data = response.data;
        this.setState({
          country: data.country_code_iso3,
          countrycode: data.country_calling_code,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  fname(event) {
    !validator.isEmpty(event.target.value) &&
    validator.isAlpha(event.target.value, 'en-GB', { ignore: ' ' })
      ? this.setState({ fname: event.target.value, validFname: true })
      : this.setState({ fname: event.target.value, validFname: false });
  }
  mname(event) {
    !validator.isEmpty(event.target.value) &&
    validator.isAlpha(event.target.value, 'en-GB', { ignore: ' ' })
      ? this.setState({ mname: event.target.value, validMname: true })
      : this.setState({ mname: event.target.value, validMname: false });
  }
  lname(event) {
    !validator.isEmpty(event.target.value) &&
    validator.isAlpha(event.target.value, 'en-GB', { ignore: ' ' })
      ? this.setState({ lname: event.target.value, validLname: true })
      : this.setState({ lname: event.target.value, validLname: false });
  }
  email(event) {
    !validator.isEmpty(event.target.value) &&
    validator.isEmail(event.target.value)
      ? this.setState({
          email: event.target.value,
          validEmail: true,
          existingUser: false,
        })
      : this.setState({ email: event.target.value, validEmail: false });
  }
  password(event) {
    validator.matches(
      event.target.value,
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
    )
      ? this.setState({ password: event.target.value, validPassword: true })
      : this.setState({ password: event.target.value, validPassword: false });
  }
  confirmPassword(event) {
    event.target.value !== '' && event.target.value === this.state.password
      ? this.setState({
          confirmPassword: event.target.value,
          validConfirmPassword: true,
        })
      : this.setState({
          confirmPassword: event.target.value,
          validConfirmPassword: false,
        });
  }
  phonenumber(event) {
    !validator.isEmpty(event.target.value) &&
    validator.isNumeric(event.target.value)
      ? this.setState({
          phonenumber: event.target.value,
          validPhonenumber: true,
        })
      : this.setState({
          phonenumber: event.target.value,
          validPhonenumber: false,
        });
    event.target.value.length >= 10 &&
      this.setState({ validTenDigitNumber: true });
  }
  countrycode(event) {
    this.setState({
      countrycode: event.target.value,
    });
  }
  phonetype(event) {
    this.setState({ phonetype: event.target.value });
  }
  register() {
    const body = {
      fname: this.state.fname,
      mname: this.state.mname,
      lname: this.state.lname,
      password: this.state.password,
      email: this.state.email,
      phones: [
        {
          phonenumber: this.state.phonenumber,
          phonetype: 'mobile',
          countrycode: this.state.countrycode,
        },
      ],
    };
    console.log(JSON.stringify(body, null, 2));
    fetch(`${Ip}/register`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        result.message === `User already exists with email ${this.state.email}`
          ? this.setState({
              existingUser: true,
              proceedToConfirmDetails: false,
            })
          : result.email === this.state.email
          ? (this.setState({
              proceedToConfirmDetails: false,
            }),
            localStorage.setItem('Email', this.state.email),
            localStorage.setItem('MobileNumber', this.state.phonenumber),
            this.props.history.push('/enterOTP'))
          : null;
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }
  render() {
    const { classes } = this.props;
    return localStorage.getItem('ResetToken') ? (
      <GoHome message="Registration is completed" />
    ) : (
      <Paper>
        <CircularProgressIndicator open={this.state.progressIndicator} />
        <Info />
        <Grid
          container
          direction="column"
          style={{
            position: 'absolute',
            height: '100%',
            width: '70%',
            transform: 'translateX(+42%)',
          }}
        >
          {this.state.proceedToConfirmDetails ? (
            <ConfirmDetails
              phonenumber={this.state.phonenumber}
              email={this.state.email}
              clicked={() => {
                this.setState({ proceedToConfirmDetails: false });
              }}
              confirm={() => {
                this.register();
              }}
            />
          ) : (
            <Grid container style={{ position: 'relative', top: '40px' }}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{
                  position: 'relative',
                  right: '50px',
                }}
              >
                <Typography style={{ color: '#8692a6', fontSize: 16 }}>
                  Already have an account?&nbsp;
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#1565d8',
                      fontWeight: 600,
                    }}
                    href="/login"
                  >
                    Sign In
                  </Link>
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                alignContent="flex-start"
                style={{
                  position: 'fixed',
                  top: '16%',
                  left: '30%',
                  width: '70%',
                }}
              >
                <Typography variant="body1" style={{ marginBottom: '5px' }}>
                  First Name*
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  placeholder="First Name"
                  style={{ width: '57%' }}
                  value={this.state.fname}
                  onChange={this.fname}
                  error={
                    !this.state.validFname && this.state.fname !== ''
                      ? true
                      : !this.state.validFname
                      ? true
                      : false
                  }
                  helperText={
                    !this.state.validFname && this.state.fname !== ''
                      ? 'Special characters and numbers are not allowed'
                      : !this.state.validFname
                      ? 'First name should not be null'
                      : null
                  }
                />
                <Grid
                  container
                  direction="row"
                  style={{
                    marginTop: '15px',
                    width: '57%',
                  }}
                  justify="space-between"
                >
                  <Grid container direction="column" style={{ width: '47%' }}>
                    <Typography variant="body1" style={{ marginBottom: '5px' }}>
                      Middle Name
                    </Typography>

                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder="Middle Name"
                      value={this.state.mname}
                      onChange={this.mname}
                      error={!this.state.validMname && this.state.mname !== ''}
                      helperText={
                        !this.state.validMname && this.state.mname !== ''
                          ? 'Special characters and numbers are not allowed'
                          : null
                      }
                    />
                  </Grid>
                  <Grid container direction="column" style={{ width: '47%' }}>
                    <Typography variant="body1" style={{ marginBottom: '5px' }}>
                      Last Name*
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder="Last Name"
                      value={this.state.lname}
                      onChange={this.lname}
                      error={
                        !this.state.validLname && this.state.lname !== ''
                          ? true
                          : !this.state.validLname
                          ? true
                          : false
                      }
                      helperText={
                        !this.state.validLname && this.state.lname !== ''
                          ? 'Special characters and numbers are not allowed'
                          : !this.state.validLname
                          ? 'First name should not be null'
                          : null
                      }
                    />
                  </Grid>
                </Grid>

                <Typography variant="body1" style={{ margin: '15px 0 5px 0' }}>
                  Email address*
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  placeholder="Enter email address"
                  style={{ width: '57%' }}
                  value={this.state.email}
                  onChange={this.email}
                  error={
                    (!this.state.validEmail && this.state.email !== ''
                      ? true
                      : !this.state.validEmail
                      ? true
                      : false) || this.state.existingUser
                  }
                  helperText={
                    !this.state.validEmail && this.state.email !== ''
                      ? 'Enter a valid email'
                      : this.state.existingUser
                      ? 'Email already registered'
                      : !this.state.validEmail
                      ? 'Email should not be empty'
                      : null
                  }
                />
                <Typography variant="body1" style={{ margin: '15px 0 5px 0' }}>
                  Phone number*
                </Typography>

                <TextField
                  variant="outlined"
                  size="small"
                  type="text"
                  placeholder="Enter mobile number"
                  style={{ width: '57%' }}
                  value={this.state.phonenumber}
                  onChange={this.phonenumber}
                  error={
                    !this.state.validPhonenumber &&
                    this.state.phonenumber !== undefined &&
                    this.state.phonenumber !== ''
                      ? true
                      : !this.state.validPhonenumber
                      ? true
                      : !this.state.validTenDigitNumber
                  }
                  helperText={
                    !this.state.validPhonenumber &&
                    this.state.phonenumber !== undefined &&
                    this.state.phonenumber !== ''
                      ? 'Only numerics are allowed'
                      : !this.state.validPhonenumber &&
                        this.state.phonenumber === ''
                      ? 'Mobile number should not be null'
                      : !this.state.validTenDigitNumber
                      ? 'Enter valid mobile number'
                      : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment style={{ whiteSpace: 'pre' }}>
                        <Typography>{`${this.state.countrycode} `}</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <Typography variant="body1" style={{ margin: '15px 0 5px 0' }}>
                  Password*
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type="password"
                  placeholder="Enter Password"
                  style={{ width: '57%' }}
                  value={this.state.password}
                  onChange={this.password}
                  error={
                    !this.state.validPassword && this.state.password !== ''
                      ? true
                      : !this.state.validPassword
                      ? true
                      : false
                  }
                  helperText={
                    !this.state.validPassword && this.state.password !== ''
                      ? 'Password must have atleast 8 characters with one digit, one upper case letter, one lower case letter and one special character'
                      : !this.state.validPassword
                      ? 'Password should not be null'
                      : null
                  }
                />
                <Typography variant="body1" style={{ margin: '15px 0 5px 0' }}>
                  Confirm password*
                </Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  type={this.state.showPassword ? 'text' : 'password'}
                  placeholder="Confirm password"
                  style={{
                    width: '57%',
                  }}
                  value={this.state.confirmPassword}
                  onChange={this.confirmPassword}
                  error={
                    this.state.confirmPassword !== this.state.password &&
                    this.state.confirmPassword !== ''
                      ? true
                      : !this.state.validConfirmPassword
                      ? true
                      : false
                  }
                  helperText={
                    this.state.confirmPassword !== this.state.password &&
                    this.state.confirmPassword !== ''
                      ? 'Password and Confirm password must be same'
                      : !this.state.validConfirmPassword
                      ? 'Confirm Password should not be null'
                      : null
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment style={{ whiteSpace: 'pre' }}>
                        <IconButton
                          onClick={() => {
                            this.setState((prevState) => ({
                              showPassword: !prevState.showPassword,
                            }));
                          }}
                          style={{
                            height: 35,
                            width: 35,
                          }}
                          color={this.state.showPassword ? 'primary' : 'none'}
                        >
                          {this.state.showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  style={{ width: '100%', marginTop: 15 }}
                >
                  <Checkbox
                    checked={this.state.agree}
                    size="small"
                    style={{
                      transform: 'translateX(-24%)',
                      color: '#1565d8',
                    }}
                    onChange={() =>
                      this.setState((prevState) => ({
                        agree: !prevState.agree,
                      }))
                    }
                  />
                  <Link
                    href={Pdf}
                    target="_blank"
                    rel="noopener"
                    underline="none"
                  >
                    <Typography
                      className={classes.remember}
                      style={{ cursor: 'pointer' }}
                    >
                      I agree to terms {`&`} conditions
                    </Typography>
                  </Link>
                </Grid>
                <Grid container direction="row" alignItems="center">
                  <Checkbox
                    checked={this.state.agreeVerfication}
                    size="small"
                    style={{
                      transform: 'translateX(-24%)',
                      marginRight: 12,
                      color: '#1565d8',
                    }}
                    onChange={() =>
                      this.setState((prevState) => ({
                        agreeVerfication: !prevState.agreeVerfication,
                      }))
                    }
                  />
                  <Typography
                    className={classes.remember}
                    style={{ cursor: 'pointer', color: '#f5802c' }}
                  >
                    I agree to receive account verfication emails and SMS
                  </Typography>
                </Grid>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    textTransform: 'none',
                    margin: '5px 0 30px 0',
                    backgroundColor: '#f5802c',
                    color: '#FFFFFF',
                    width: '57%',
                  }}
                  onClick={() => {
                    this.state.fname === '' &&
                      this.setState({ validFname: false });
                    this.state.lname === '' &&
                      this.setState({ validLname: false });
                    this.state.email === '' &&
                      this.setState({ validEmail: false });
                    this.state.phonenumber === '' &&
                      this.setState({ validPhonenumber: false });
                    this.state.phonenumber !== '' &&
                      this.state.phonenumber.length < 10 &&
                      this.setState({ validTenDigitNumber: false });
                    this.state.password === '' &&
                      this.setState({ validPassword: false });
                    this.state.confirmPassword === '' &&
                      this.setState({ validConfirmPassword: false });
                    !this.state.countrycode &&
                      alert('Please check your internet connection');
                    this.state.fname &&
                      this.state.validFname &&
                      this.state.lname &&
                      this.state.validLname &&
                      this.state.email &&
                      this.state.validEmail &&
                      this.state.phonenumber &&
                      this.state.validPhonenumber &&
                      this.state.phonenumber.length >= 10 &&
                      this.state.password &&
                      this.state.validPassword &&
                      this.state.confirmPassword &&
                      this.state.validConfirmPassword &&
                      this.state.countrycode &&
                      this.state.agree &&
                      this.state.agreeVerfication &&
                      this.setState({ proceedToConfirmDetails: true });
                  }}
                  // disabled={

                  //   !this.state.validPhonenumber ||
                  //   !this.state.validPassword ||
                  //   this.state.existingUser ||
                  //   this.state.password !== this.state.confirmPassword ||
                  //   !this.state.agree
                  // }
                >
                  <Typography style={{ fontSize: 16 }}>
                    Register Account
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
}
export default withStyles(useStyles)(Register1);
