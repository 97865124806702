import React from 'react';
import { Grid, Dialog, CircularProgress } from '@material-ui/core';

export default function CircularProgressIndicator(props) {
  return (
    props.open && (
      <Dialog
        open={props.open}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <Grid style={{ width: '55px', height: '55px' }}>
          <CircularProgress style={{ color: '#f5802c' }} />
        </Grid>
      </Dialog>
    )
  );
}
