// import React from 'react';

// import {
//   Grid,
//   Box,
//   Table,
//   TableContainer,
//   TableHead,
//   TableBody,
//   TableRow,
//   TableCell,
//   Paper,
//   Button,
// } from '@material-ui/core';
// import { withStyles, makeStyles } from '@material-ui/core/styles';
// import Checkbox from '@material-ui/core/Checkbox';

// export default function ResultsTable(props) {
//   const StyledTableCell = withStyles((theme) => ({
//     head: {
//       backgroundColor:
//         props.content.length > 0 ? theme.palette.primary.main : null,
//       color:
//         props.content.length > 0
//           ? theme.palette.common.white
//           : theme.palette.primary.main,
//     },
//     body: {
//       fontSize: 14,
//     },
//   }))(TableCell);

//   const StyledTableRow = withStyles((theme) => ({
//     root: {
//       '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//       },
//       '&:hover': {
//         backgroundColor: '#d5e3e8 !important',
//       },
//     },
//   }))(TableRow);

//   const useStyles = makeStyles({
//     table: {
//       minWidth: 650,
//     },
//     container: {
//       maxHeight: 520,
//     },
//   });

//   const classes = useStyles();

//   return (
//     <Grid item xs={12} alignContjustifyent="flex-end">
//       <Button>Ask for Service</Button>
//       <Box m={1}>
//         <TableContainer component={Paper} className={classes.container}>
//           {
//             <Table stickyHeader className={classes.table}>
//               <TableHead>
//                 <TableRow>
//                   <StyledTableCell>
//                     <Checkbox
//                       style={{
//                         height: '30px',
//                         width: '20px',
//                         color: 'inherit',
//                       }}
//                     />
//                   </StyledTableCell>
//                   <StyledTableCell>Name</StyledTableCell>
//                   <StyledTableCell align="center">
//                     Specilization
//                   </StyledTableCell>
//                   <StyledTableCell align="center">Experience</StyledTableCell>
//                   <StyledTableCell align="center">Hourly rate</StyledTableCell>
//                   <StyledTableCell align="center">Location</StyledTableCell>
//                 </TableRow>
//               </TableHead>

//               <TableBody>
//                 {props.content.map((row, i) => (
//                   <StyledTableRow
//                     hover
//                     onMouseEnter={() => {
//                       props.clicked({
//                         name: row.addressline1,
//                         latitude: row.latitude,
//                         longitude: row.longitude,
//                       });
//                     }}
//                     onMouseLeave={() => {
//                       props.clicked({
//                         name: '',
//                         latitude: '',
//                         longitude: '',
//                       });
//                     }}
//                     key={i}
//                   >
//                     <StyledTableCell>
//                       <Checkbox
//                         style={{
//                           height: '20px',
//                           width: '20px',
//                           color: 'inherit',
//                         }}
//                       />
//                     </StyledTableCell>
//                     <StyledTableCell component="th" scope="row">
//                       {row.fname} {row.lname}
//                     </StyledTableCell>
//                     <StyledTableCell align="center">
//                       {row.description}
//                     </StyledTableCell>
//                     <StyledTableCell align="center">
//                       {row.yearofexperience}
//                     </StyledTableCell>
//                     <StyledTableCell align="center">
//                       {row.hourlyrate}
//                     </StyledTableCell>
//                     <StyledTableCell align="center">
//                       {row.addressline1}
//                     </StyledTableCell>
//                   </StyledTableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           }
//         </TableContainer>
//       </Box>
//     </Grid>
//   );
// }

/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Grid, Button, Box, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGridStyles } from '../../data/DataGridStyles';

export default function MyBidsTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [serviceProviderId, setServiceProviderId] = useState();
  const [placeRequest, setPlaceRequest] = useState(true);
  const [change, setChange] = useState(false);
  const [rowId, setRowId] = useState();

  function CustomToolbar() {
    return (
      <Grid
        container
        justify="flex-end"
        alignContent="center"
        style={{
          height: '50px',
          borderBottom: 'solid 2px #cfd9e2',
        }}
      >
        <Button
          style={{ textTransform: 'none' }}
          title="Delete the selected services"
          href={
            localStorage.getItem('ResetToken')
              ? `/requestprovider/${serviceProviderId}`
              : '/login'
          }
          disabled={placeRequest}
          onClick={() => {
            localStorage.getItem('ResetToken')
              ? null
              : localStorage.setItem(
                  'createServiceRequestBySearchingProviders',
                  serviceProviderId
                );
          }}
        >
          <Typography>Ask for service</Typography>
        </Button>
      </Grid>
    );
  }

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 130,
    },
    {
      field: 'specialization',
      headerName: 'Specialization',
      width: 170,
    },
    {
      field: 'experience',
      headerName: 'Experience',
      width: 110,
    },
    {
      field: 'hourlyrate',
      headerName: 'Hourly Rate',
      width: 115,
    },
    {
      field: 'location',
      headerName: 'Location',
      width: 110,
    },
    {
      field: 'latitude',
      hide: true,
    },
    {
      field: 'longitude',
      hide: true,
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: [con.serviceid, con.userid],
          name: `${con.fname} ${con.mname ? con.mname : ' '} ${
            con.lname ? con.lname : ' '
          }`,
          specialization: con.description,
          experience: con.yearofexperience,
          hourlyrate: `$ ${con.hourlyrate}`,
          location: con.addressline1,
          latitude: con.latitude,
          longitude: con.longitude,
        },
      ]);
    });
  }, []);

  return (
    <Grid
      container
      style={{
        height:
          props.content.length === 1
            ? props.content.length * 70 + 120
            : props.content.length * 52 + 120,
        width: '100%',
        height: '538px',
      }}
      className={classes.root}
    >
      <DataGrid
        components={{
          header: CustomToolbar,
        }}
        showToolbar
        rows={rows}
        columns={columns}
        hideFooter
        onSelectionChange={(param) => {
          console.log(param.rowIds[0]);
          param.rowIds.length === 1
            ? (setPlaceRequest(false), setServiceProviderId(param.rowIds[0]))
            : setPlaceRequest(true);
        }}
        onRowClick={(param) => {
          change || param.row.id !== rowId
            ? (props.clicked({
                name: param.row.location,
                latitude: param.row.latitude,
                longitude: param.row.longitude,
              }),
              setChange(false),
              setRowId(param.row.id))
            : (props.clicked({
                name: '',
                latitude: '',
                longitude: '',
              }),
              setChange(true));
        }}
        onRowHover={(param) => {
          // props.clicked({
          //   name: param.row.location,
          //   latitude: param.row.latitude,
          //   longitude: param.row.longitude,
          // });
          // setChange(false);
          //   setTimeout(() => {
          //     props.clicked({
          //       name: '',
          //       latitude: '',
          //       longitude: '',
          //     });
          //   }, 3000);
          // props.clicked({
          //   name: param.row.location,
          //   latitude: param.row.latitude,
          //   longitude: param.row.longitude,
          // });
        }}
      />
    </Grid>
  );
}
