import React, { useState, useEffect } from 'react';
import { TextField, Grid, Button, Typography, Box } from '@material-ui/core';
import { Ip } from '../data/ip';
import axios from 'axios';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

export default function ForgotPassword(props) {
  const [email, setEmail] = useState(props.email || '');
  const [validEmail, setValidEmail] = useState(true);
  const [err, setErr] = useState('');
  const [proceed, setProceed] = useState(false);

  function submit() {
    axios
      .get(`${Ip}/forgotpassword?email=${email}`)
      .then((Response) => {
        console.log(Response);
        if (Response.status === 200) {
          setProceed((prevState) => !prevState);
        }
      })
      .catch((err) => {
        console.log(err);
        setErr('email id is not registered with us');
      });
  }

  return proceed ? (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
      }}
    >
      <Typography
        align="center"
        style={{ wordBreak: 'inherit', paddingBottom: '20px', width: '40%' }}
      >
        We have sent a link to your mail. Please follow the link to reset
        password
      </Typography>
      <Button
        variant="contained"
        size="large"
        style={{
          textTransform: 'none',
          position: 'relative',
          backgroundColor: '#f5802c',
          color: '#FFFFFF',
          width: '10%',
        }}
        onClick={() => props.close()}
      >
        Ok
      </Button>
    </Grid>
  ) : (
    <Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        style={{
          position: 'relative',
          top: '40px',
        }}
      >
        <Button onClick={() => props.close()} style={{ textTransform: 'none' }}>
          <Typography>
            <NavigateBeforeIcon />
            Back
          </Typography>
        </Button>
      </Grid>
      <Grid
        container
        direction="column"
        alignContent="flex-start"
        style={{
          position: 'fixed',
          top: '40%',
          left: '30%',
          width: '70%',
        }}
      >
        <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>
          Forgot Password!
        </Typography>
        <Typography variant="body1" style={{ position: 'relative', top: 20 }}>
          Email id
        </Typography>
        <TextField
          required
          type="email"
          placeholder="Enter email id"
          variant="outlined"
          size="small"
          style={{ position: 'relative', top: 25, width: '57%' }}
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            setErr(''), setValidEmail(true);
          }}
          error={err || !validEmail}
          helperText={
            err ? err : !validEmail ? 'Email should not be null' : null
          }
          onKeyPress={(event) => {
            var code;
            if (event.key !== undefined) {
              code = event.key;
            } else if (event.keyIdentifier !== undefined) {
              code = event.keyIdentifier;
            } else if (event.keyCode !== undefined) {
              code = event.keyCode;
            }
            if (code === 13 || code === 'Enter') {
              email === '' ? null : submit();
            }
          }}
        />

        <Button
          onClick={() => {
            email === '' && setValidEmail(false);
            email && validEmail && submit();
          }}
          variant="contained"
          size="large"
          style={{
            textTransform: 'none',
            position: 'relative',
            top: 50,
            backgroundColor: '#f5802c',
            color: '#FFFFFF',
            width: '57%',
          }}
        >
          <Typography style={{ fontSize: 16 }}>Submit</Typography>
        </Button>
      </Grid>
      {/* <Grid
         container
         justify="center"
         direction="column"
         spacing={2}
         alignItems="center"
       >
         <Box mt={5} alignItems="center" justifyContent="center" align="center">
           <Grid
             container
             direction="column"
             item
             justify="center"
             alignItems="center"
             xs={12}
           >
             <Typography variant="h4" align="center">
               FORGOT PASSWORD
             </Typography>
             <br />
 
             <Typography>
               Enter your e-mail address and we will <br />
               send you a link to reset your password.
             </Typography>
           </Grid>
 
           <Box
             align="center"
             display="flex"
             alignItems="center"
             justifyContent="center"
             p={1}
           >
             <TextField
               required
               type="email"
               label="Email id"
               variant="outlined"
               margin="dense"
               value={email}
               onChange={(event) => {
                 setEmail(event.target.value);
               }}
               onChangeCapture={() => setErr('')}
               error={err === 'email id is not registered with us'}
               helperText={err}
               onKeyPress={(event) => {
                var code;
                    if (event.key !== undefined) {
                      code = event.key;
                    } else if (event.keyIdentifier !== undefined) {
                      code = event.keyIdentifier;
                    } else if (event.keyCode !== undefined) {
                      code = event.keyCode;
                    }
                    if (code === 13 || code === 'Enter') {
                   email === '' ? null : submit();
                 }
               }}
             />
           </Box>
 
           <Box display="flex" alignItems="center" justifyContent="center" p={1}>
             <Button
               variant="contained"
               color="primary"
               centerRipple
               onClick={() => submit()}
               disabled={email === ''}
             >
               Submit
             </Button>
           </Box>
         </Box>
       </Grid> */}
    </Grid>
  );
}
