import React, { useEffect, useState } from 'react';
import { Ip } from '../../data/ip';
import CreateServiceRequest from './CreateServiceRequest';
import { useParams } from 'react-router';
import axios from 'axios';
import { Grid, Typography } from '@material-ui/core';

export default function UpdateServiceRequest() {
  let { request } = useParams();
  const [serviceRequestbyId, setServiceRequestbyId] = useState();
  useEffect(() => {
    axios
      .get(`${Ip}/servicerequest/${request}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
      })
      .then((response) => {
        console.log('requestById', response);
        if (response.status === 200) {
          setServiceRequestbyId(response.data);
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }, []);

  return serviceRequestbyId ? (
    <CreateServiceRequest
      update={true}
      serviceRequestId={request}
      title={serviceRequestbyId.title}
      description={serviceRequestbyId.description}
      serviceabletime={serviceRequestbyId.serviceabletime}
      attachments={serviceRequestbyId.attachments}
      serviceid={serviceRequestbyId.service.serviceid}
      deliverymode={serviceRequestbyId.receivingmethod}
    />
  ) : (
    <Grid container justify="center">
      <Typography variant="h3">Loading...!</Typography>
    </Grid>
  );
}
