/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ShowAttachments from '../ShowAttachments';
import PlaceBid from './PlaceBid';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { DataGridStyles } from '../../../data/DataGridStyles';

export default function OtherServiceRequestsTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles(DataGridStyles);
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [type, setType] = useState();
  const [attachmentId, setAttachmentId] = useState();
  const [rowServiceRequestId, setRowServiceRequestId] = useState();
  const [open, setOpen] = useState(false);
  const [placeBid, setPlaceBid] = useState(true);
  const [openAssign, setOpenAssign] = useState(false);
  const [serviceRequestId, setServiceRequestId] = useState([]);

  function CustomToolbar() {
    return (
      <Grid
        container
        justify="flex-end"
        alignContent="center"
        style={{
          height: '50px',
          borderBottom: 'solid 2px #cfd9e2',
        }}
      >
        <Box mr={1}>
          <Button
            disabled={placeBid}
            style={{ textTransform: 'none' }}
            onClick={() => setOpenAssign(true)}
          >
            <Typography>Place a Bid</Typography>
            <ChevronRightIcon />
          </Button>
        </Box>
      </Grid>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
    },
    {
      field: 'id2',
      headerName: 'id2',
      hide: true,
    },
    { field: 'title', headerName: 'Title', width: 380 },
    {
      field: 'description',
      headerName: 'Description',
      width: 380,
    },
    {
      field: 'servicetype',
      headerName: 'Service type',
      width: 180,
    },
    {
      field: 'attachments',
      headerName: 'Attachments',
      width: 180,
      sortable: false,
      height: 'auto',
      renderCell: (params) => (
        <Select
          name="Attachments"
          id="attachments"
          IconComponent={AttachmentIcon}
          input={<Input disableUnderline value="" />}
        >
          {params.value.map((ats, i) => (
            <MenuItem
              value={ats}
              key={i}
              onClick={() => {
                setRowServiceRequestId(params.row.id2);
                setAttachmentId(ats.srattachmentid);
                setType(ats.type);
                setOpen(!open);
              }}
            >
              {ats.name}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
    },
  ];

  useEffect(() => {
    props.content.map((con) => {
      setRows((prevRows) => [
        ...prevRows,
        {
          id: [con.servicerequestid, con.status],
          id2: con.servicerequestid,
          title: con.title,
          description: con.description,
          servicetype: con.servicename,
          attachments: con.attachments,
          status: con.status,
        },
      ]);
    });
  }, []);

  return (
    <Grid
      container
      style={{ height: props.content.length * 52 + 116 }}
      className={classes.root}
    >
      <DataGrid
        autoHeight
        components={{
          header: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
        checkboxSelection
        disableSelectionOnClick
        hideFooter
        showToolbar
        onSelectionChange={(param) => {
          var req =
            param.rowIds.length > 0 &&
            param.rowIds[0].split(',').map((step) => step);
          console.log(param);
          param.rowIds.length === 1 && req[1] !== 'BidAccepted'
            ? (setPlaceBid(false), setServiceRequestId(req[0]))
            : setPlaceBid(true);
        }}
      />

      {open && (
        <ShowAttachments
          servicerequestid={rowServiceRequestId}
          attachmentid={attachmentId}
          type={type}
          open={open}
          close={() => setOpen(!open)}
        />
      )}

      {openAssign && (
        <PlaceBid
          open={openAssign}
          close={() => setOpenAssign(!openAssign)}
          id={serviceRequestId}
          refresh={() => props.refresh()}
        />
      )}
    </Grid>
  );
}
