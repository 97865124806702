/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  Input,
  IconButton,
} from '@material-ui/core';
import { Ip } from '../../../data/ip';
import WorkOrderQueueTable from './WorkOrderQueueTable';
import axios from 'axios';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CircularProgress from '@material-ui/core/CircularProgress';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';

export default function WorkOrderQueue() {
  const [data, setData] = useState([]);
  const [create, setCreate] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalElements, setTotalElements] = useState();
  const [refresh, setRefresh] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setData([]);
    axios
      .get(
        `${Ip}/servicerequest?filter=all&pageNumber=${pageNumber}&pageSize=${pageSize}&sortDirection=DESC&sortField=updatedat&status=%7BOpen,OpenOffered%7D`,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (response.data.servicerequests.length === 0) {
            setCreate(true);
          }
          var array = response.data.servicerequests;
          var attachments = response.data.attachments;
          for (var i in array)
            for (var j in attachments) {
              if (Number(j) === array[i].servicerequestid) {
                var atchIncluded = { ...array[i], attachments: attachments[j] };
                array.splice(i, 1, atchIncluded);
              }
            }
          console.log(array);
          setData(array);
          setTotalElements(response.data.totalelements);
          setLoad(false);
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }, [pageSize, pageNumber, refresh]);

  return (
    <Grid container justify="center" alignItems="center" direction="column">
      <CircularProgressIndicator open={load} />
      {data.length > 0 ? (
        <Typography align="center" style={{ marginTop: 20, fontSize: 40 }}>
          Work Order Queue
        </Typography>
      ) : (
        create && (
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Typography variant="h3">
              There are no work orders for you!
            </Typography>
          </Grid>
        )
      )}
      <Grid container justify="space-around">
        {data.length > 0 && (
          <WorkOrderQueueTable
            content={data}
            refresh={() => setRefresh(!refresh)}
          />
        )}
        {data.length > 0 && (
          <Grid
            container
            directoin="row"
            justify="flex-end"
            alignItems="center"
          >
            <Typography>rows per page&nbsp;&nbsp;</Typography>
            <Select
              name="RowsPerPage"
              id="rowsperpage"
              input={<Input disableUnderline />}
              value={pageSize}
              onChange={(event) => {
                setPageSize(event.target.value);
                setPageNumber(0);
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem
                value={10}
                disabled={(pageNumber + 1) * 5 >= totalElements}
              >
                10
              </MenuItem>
              <MenuItem
                value={15}
                disabled={(pageNumber + 1) * 10 >= totalElements}
              >
                15
              </MenuItem>
              <MenuItem
                value={20}
                disabled={(pageNumber + 1) * 15 >= totalElements}
              >
                20
              </MenuItem>
            </Select>
            <IconButton
              disabled={pageNumber === 0}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              disabled={(pageNumber + 1) * pageSize >= totalElements}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              <NavigateNextIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
