import React, { useState } from 'react';
import { Typography, Button, Menu, Box, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from 'material-ui-popup-state/HoverPopover';
import {
  usePopupState,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state/hooks';

export default function ServiceRequestButton(props) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  });

  return (
    <Box>
      <Button
        {...bindHover(popupState)}
        style={{
          textTransform: 'none',
          color: '#ffffff',
          borderRadius: 0,
          borderBottom: props.border ? '3px solid #f5802c' : null,
          paddingBottom: props.border ? 13 : 16,
          marginRight: 15,
          opacity: props.border ? 1 : 0.7,
        }}
      >
        <Typography style={{ height: 24 }}>
          Service Requests
          <ExpandMoreIcon style={{ marginLeft: 8, height: 24 }} />
        </Typography>
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <Button
          style={{
            textTransform: 'none',
            width: '220px',
            height: '40px',
          }}
          href="/createservicerequest"
        >
          <Typography>Create Service Request</Typography>
        </Button>
        <br />
        <Button
          fullWidth
          href="/myservicerequests"
          style={{
            textTransform: 'none',
            width: '220px',
            height: '40px',
          }}
        >
          <Typography>My Service Requests</Typography>
        </Button>
        <br />
        {localStorage.getItem('UserType') === 'SERVICE_PROVIDER' && (
          <Grid container direction="column">
            <Button
              fullWidth
              href="/workorderqueue"
              style={{
                textTransform: 'none',
                width: '220px',
                height: '40px',
              }}
            >
              <Typography>Work Order Queue</Typography>
            </Button>
            <Button
              fullWidth
              href="/directrequests"
              style={{
                textTransform: 'none',
                width: '220px',
                height: '40px',
              }}
            >
              <Typography>Direct Requests</Typography>
            </Button>
          </Grid>
        )}
      </Popover>
    </Box>
  );
}
