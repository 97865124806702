import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  CardMedia,
  Link,
  Box,
  makeStyles,
} from '@material-ui/core';
import bg1 from '../../assets/Images/bg1.png';
import servex from '../../assets/Images/ServeX.svg';
import { createBrowserHistory } from 'history';
import DoneIcon from '@material-ui/icons/Done';
import InvertedCommas from '../../assets/Images/InvertedCommas.svg';
import InfoBorder from '../../assets/Images/InfoBorder.svg';
import tick from '../../assets/Images/tixk-circle.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
}));

export default function Info() {
  const classes = useStyles();
  const history = createBrowserHistory();
  const login = ['/login'].includes(history.location.pathname);
  const register1 = ['/register1'].includes(history.location.pathname);
  const register2 = ['/register2'].includes(history.location.pathname);
  const register3 = ['/register3'].includes(history.location.pathname);
  const registrationcompleted = ['/registrationcompleted'].includes(
    history.location.pathname
  );
  const resetpassword = ['/resetpassword'].includes(history.location.pathname);
  const enterOTP = ['/enterOTP'].includes(history.location.pathname);

  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      style={{
        position: 'fixed',
        height: '100%',
        width: '30%',
        backgroundColor: 'rgba(0,0,0,0)',
      }}
    >
      <Grid style={{ position: 'relative', height: '100%', width: '100%' }}>
        <CardMedia
          component="img"
          image={bg1}
          style={{ height: '100%', width: '100%' }}
        />
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{
            position: 'absolute',
            color: 'white',
            top: 40,
            left: '13%',
          }}
        >
          <Link style={{ textDecoration: 'none', color: 'inherit' }} href="/">
            <img src={servex} />
          </Link>
          <Grid style={{ width: '80%' }}>
            {(login || resetpassword) && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignContent="flex-start"
                style={{ wordBreak: 'inherit', marginTop: 200 }}
              >
                <img src={InvertedCommas} style={{ width: 24, height: 20 }} />
                <Typography
                  align="justify"
                  style={{
                    margin: '30px 0 30.6px 0',
                    opacity: 0.9,
                    lineHeight: 2.13,
                    fontWeight: 200,
                  }}
                >
                  The passage experienced a surge in popularity during the 1960s
                  when Letraset used it on their dry-transfer sheets, and again
                  during the 90s as desktop publishers bundled the text with
                  their software.
                </Typography>
                <Typography
                  style={{
                    fontSize: 20,
                    margin: '0 8px 33px 0',
                    fontWeight: 600,
                  }}
                >
                  Vincent Obi{' '}
                  <img
                    src={tick}
                    style={{ width: 16, height: 16, marginBottom: 2 }}
                  />
                </Typography>
                <Grid container direction="row" justify="flex-end">
                  <img
                    src={InfoBorder}
                    style={{ width: 33.5, height: 33, marginBottom: 2 }}
                  />
                </Grid>
              </Grid>
            )}
            {register1 && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                style={{ wordBreak: 'break-word', marginTop: 100 }}
              >
                <Heading title="Register With Us!" />
                <Points
                  number={1}
                  name="Account / Personal Information"
                  opacity={true}
                  first={true}
                  tick={false}
                />
                <Points
                  number={2}
                  name={`Verify Email & Mobile`}
                  tick={false}
                />
                <Points number={3} name="Setup Your Profile" tick={false} />
                <Points
                  number={4}
                  name="Add Services"
                  last={true}
                  tick={false}
                />
              </Grid>
            )}
            {enterOTP && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                style={{ wordBreak: 'break-word', marginTop: 100 }}
              >
                <Heading title={`Verify Email & Mobile`} />
                <Points
                  number={1}
                  name="Account / Personal Information"
                  first={true}
                  tick={true}
                />
                <Points
                  number={2}
                  name={`Verify Email & Mobile`}
                  opacity={true}
                  tick={false}
                />
                <Points number={3} name="Setup Your Profile" tick={false} />
                <Points
                  number={4}
                  name="Add Services"
                  last={true}
                  tick={false}
                />
              </Grid>
            )}
            {register2 && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                style={{ wordBreak: 'break-word', marginTop: 100 }}
              >
                <Heading title="Setup Your Profile" />
                <Points
                  number={1}
                  name="Account / Personal Information"
                  first={true}
                  tick={true}
                />
                <Points number={2} name={`Verify Email & Mobile`} tick={true} />
                <Points
                  number={3}
                  name="Setup Your Profile"
                  opacity={true}
                  tick={false}
                />
                <Points
                  number={4}
                  name="Add Services"
                  last={true}
                  tick={false}
                />
              </Grid>
            )}
            {register3 && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                style={{ wordBreak: 'break-word', marginTop: 100 }}
              >
                <Heading title="Add Services" />
                <Points
                  number={1}
                  name="Account / Personal Information"
                  first={true}
                  tick={true}
                />
                <Points number={2} name={`Verify Email & Mobile`} tick={true} />
                <Points number={3} name="Setup Your Profile" tick={true} />
                <Points
                  number={4}
                  name="Add Services"
                  opacity={true}
                  last={true}
                  tick={false}
                />
              </Grid>
            )}
            {registrationcompleted && (
              <Grid
                container
                direction="column"
                justify="flex-start"
                style={{ wordBreak: 'break-word', marginTop: 100 }}
              >
                <Heading title="Add Services" />
                <Points
                  number={1}
                  name="Account / Personal Information"
                  first={true}
                  tick={true}
                />
                <Points number={2} name={`Verify Email & Mobile`} tick={true} />
                <Points number={3} name="Setup Your Profile" tick={true} />
                <Points
                  number={4}
                  name="Add Services"
                  last={true}
                  tick={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Heading(props) {
  return (
    <Grid>
      <Typography style={{ fontSize: 30, fontWeight: 'bold' }}>
        {props.title}
      </Typography>
      <Typography style={{ fontSize: 18, opacity: 0.8, fontWeight: 300 }}>
        For the purpose of industry regulation, your details are required.
      </Typography>
    </Grid>
  );
}

function Points(props) {
  return (
    <Grid>
      <Grid
        container
        direction="row"
        style={{
          marginTop: props.first ? 48 : 0,
          opacity: props.opacity ? null : 0.55,
        }}
        alignItems="center"
      >
        <Box
          style={{
            borderRadius: '50%',
            width: '35px',
            height: '35px',
            flexGrow: 0,
            border: 'solid 2px ',
            borderColor: props.tick ? '#ffffff' : '#f5802c',
          }}
        >
          <Grid
            container
            justify="center"
            alignContent="center"
            style={{ width: '100%', height: '100%' }}
          >
            {props.tick ? <DoneIcon /> : props.number}
          </Grid>
        </Box>
        <Typography style={{ marginLeft: 15 }}>{props.name}</Typography>
      </Grid>
      {!props.last && (
        <Box
          style={{
            width: 1.5,
            height: 40,
            flexGrow: 0,
            margin: '15px 0 15px 18px',
            opacity: 0.55,
            borderLeft: '2px dashed #ffffff',
          }}
        />
      )}
    </Grid>
  );
}
