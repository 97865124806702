import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  Card,
  Button,
  Select,
  Input,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { Ip } from '../../../data/ip';
import axios from 'axios';
import ViewMilestonesTable from './ViewMilestonesTable';
import CreateMilestones from './CreateMilestones';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';

export default function ViewMilestones(props) {
  const [data, setData] = useState([]);
  const [showMessageOrButton, setShowMessageOrButton] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [createMs, setCreateMs] = useState(false);
  const [editMs, setEditMs] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setData([]);
    setEditMs(false);
    (props.contractStatus === 'created' ||
      props.contractStatus === 'RejectedByProvider') &&
    String(props.providerid) === localStorage.getItem('userId')
      ? setShowMessageOrButton(true)
      : axios
          .get(
            `${Ip}/milestone?contractid=${props.contractid}&pageNumber=0&pageSize=10&sortField=milestoneid&sortDirection=ASC`,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${
                  localStorage.getItem('ResetToken') || ''
                }`,
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              if (response.data.totalelements === 0) {
                setShowMessageOrButton(true);
              }
              var resp = response.data.milestones;
              var ms = [];
              setData(resp);
              for (var i in resp) {
                console.log(i);
                ms = [
                  ...ms,
                  {
                    contractid: `${resp[i].contractid}`,
                    title: resp[i].title,
                    description: resp[i].description,
                    completionstatus: resp[i].completionstatus,
                    paymentPercentage: `${resp[i].paymentpercentage}`,
                    paymentstatus: resp[i].paymentstatus,
                    milestoneid: `${resp[i].milestoneid}`,
                  },
                ];
              }
              setMilestones(ms);
            }
          })
          .catch((err) => {
            setShowMessageOrButton(true);
            console.log(err);
          });
  }, [refresh]);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      key={data.length}
    >
      <CircularProgressIndicator
        open={data.length === 0 && !showMessageOrButton && !createMs}
      />
      {showMessageOrButton && (
        <Grid container justify="center" alignItems="center" direction="column">
          <Box m={1}>
            {props.contractStatus === 'RejectedByProvider' &&
            String(props.providerid) === localStorage.getItem('userId') ? (
              <Typography style={{ fontSize: 20, fontWeight: 500 }}>
                Rejected the proposed milestones, we will update once the user
                resends the milestones proposal.
              </Typography>
            ) : String(props.providerid) === localStorage.getItem('userId') ? (
              <Typography style={{ fontSize: 20, fontWeight: 500 }}>
                No Milestones were created by the user for this contract!
              </Typography>
            ) : (
              <Button
                style={{ textTransform: 'none', color: '#ffffff' }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setCreateMs(true);
                  setShowMessageOrButton(false);
                }}
              >
                Create Milestones
              </Button>
            )}
          </Box>
        </Grid>
      )}

      {createMs && (
        <CreateMilestones
          contractid={props.contractid}
          amount={props.amount}
          refresh={() => {
            setRefresh(!refresh), setCreateMs(false);
          }}
        />
      )}

      {data.length > 0 && !editMs && (
        <Grid container direction="column">
          <ViewMilestonesTable
            funded={props.funded}
            contractid={props.contractid}
            contractStatus={props.contractStatus}
            content={data}
            amount={props.amount}
            editMs={() => setEditMs(true)}
            providerid={props.providerid}
            refresh={() => setRefresh(!refresh)}
          />
          {/* <Grid
              container
              directoin="row"
              justify="flex-end"
              alignItems="center"
            >
              <Typography>rows per page&nbsp;&nbsp;</Typography>
              <Select
                name="RowsPerPage"
                id="rowsperpage"
                input={<Input disableUnderline />}
                value={pageSize}
                onChange={(event) => {
                  setPageSize(event.target.value);
                  setPageNumber(0);
                }}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem
                  value={10}
                  disabled={(pageNumber + 1) * 5 >= totalElements}
                >
                  10
                </MenuItem>
                <MenuItem
                  value={15}
                  disabled={(pageNumber + 1) * 10 >= totalElements}
                >
                  15
                </MenuItem>
                <MenuItem
                  value={20}
                  disabled={(pageNumber + 1) * 15 >= totalElements}
                >
                  20
                </MenuItem>
              </Select>
              <IconButton
                disabled={pageNumber === 0}
                onClick={() => setPageNumber(pageNumber - 1)}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                disabled={(pageNumber + 1) * pageSize >= totalElements}
                onClick={() => setPageNumber(pageNumber + 1)}
              >
                <NavigateNextIcon />
              </IconButton>
            </Grid> */}
        </Grid>
      )}
      {editMs && (
        <CreateMilestones
          contractid={props.contractid}
          amount={props.amount}
          milestones={milestones}
          deleted={deleted}
          refresh={() => {
            setRefresh(!refresh), setCreateMs(false);
          }}
        />
      )}
    </Grid>
  );
}
