import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import DisplayHeading from './DisplayComponents/DisplayHeading';
import AddServices from '../../components/AddServices';
import ServicesTable from '../Registration/Register3/ServicesTable';
import AvailableServices from '../../data/AvailableServices';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import { Ip } from '../../data/ip';

export default function ServiceDetails(props) {
  const [userServices, setUserServices] = useState('');
  const [availableServices, setAvailableServices] = useState([]);
  const [servicesDetailstoDisplay, setServicesDetailstoDisplay] = useState(
    props.services || []
  );
  const [addMore, setAddMore] = useState(false);
  const [load, setLoad] = useState(false);
  const [firstVisit, setFirstVisit] = useState(true);

  let servicesListinDB = AvailableServices();

  useEffect(() => {
    let ser = '';
    let added = servicesDetailstoDisplay;
    let remove = [];
    if (availableServices.length === 0 && firstVisit) {
      for (var i = 0; i < servicesListinDB.length; i++) {
        ser = [
          ...ser,
          {
            name: servicesListinDB[i].name,
            serviceid: servicesListinDB[i].serviceid,
          },
        ];
      }
      servicesListinDB.length > 0 && setFirstVisit(false);
    } else {
      if (added)
        for (var i in availableServices)
          for (var j in added) {
            if (availableServices[i].serviceid === +added[j].serviceid) {
              ser = [...availableServices];
              remove = [...remove, availableServices[i]];
            }
          }
    }
    if (remove.length > 0) {
      ser = ser.filter((value) => !remove.includes(value));
    }
    ser && setAvailableServices(ser);
    ser = '';
  }, [servicesListinDB, servicesDetailstoDisplay, firstVisit]);

  useEffect(() => {
    if (userServices) {
      const body = {
        jobTitle: localStorage.getItem('JobTitle'),
        userServices: [userServices],
        paymentReceiptMethodId: localStorage.getItem('PaymentMethod') || '1',
      };
      console.log(`payload: ${JSON.stringify(body, null, 2)}`);

      fetch(`${Ip}/user/service`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          props.servicesChanged();
          if (response.status === 200)
            localStorage.getItem('UserType') !== 'SERVICE_PROVIDER'
              ? fetch(`${Ip}/user`, {
                  method: 'PATCH',
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${
                      localStorage.getItem('ResetToken') || ''
                    }`,
                  },
                  body: JSON.stringify({
                    usertype: 'SERVICE_PROVIDER',
                  }),
                })
                  .then((response) => {
                    localStorage.setItem('UserType', 'SERVICE_PROVIDER');
                    console.log('userType', response);
                    setLoad(false);
                  })
                  .catch((err) => {
                    alert(`ERR: ${err}`);
                    console.log(err);
                  })
              : setLoad(false);
        })
        .catch((err) => {
          alert(`ERR: ${err}`);
          console.log(err);
        });
    }
  }, [userServices]);

  return (
    <Grid style={{ width: '100%' }} key={servicesDetailstoDisplay.length}>
      <CircularProgressIndicator open={load} />
      <Grid
        container
        direction="column"
        style={{
          backgroundColor: '#ffffff',
          marginBottom: 25,
          height: '100%',
        }}
      >
        <DisplayHeading
          DisplayHeadingName="My Services"
          caption="Basic Info, for a faster search experience"
          button="+ Add More Services"
          disabled={addMore}
          clicked={() => {
            setAddMore(true);
          }}
        />

        <Grid
          container
          direction="column"
          alignItems="center"
          style={{
            width: '100%',
            padding: '0 25px 0 25px',
            marginBottom: 20,
          }}
        >
          {servicesDetailstoDisplay.length > 0 && (
            <Grid style={{ marginTop: 15, width: '100%' }}>
              <ServicesTable
                content={servicesDetailstoDisplay}
                update={() => props.update()}
              />
            </Grid>
          )}

          <AddServices
            close={() => setAddMore(false)}
            addMore={addMore}
            availableServices={availableServices}
            add={(add) => {
              setUserServices(add), setLoad(true);
            }}
            servicesDetailstoDisplay={(serviceDetails) => {
              setServicesDetailstoDisplay((prevState) => [
                ...prevState,
                serviceDetails,
              ]);
            }}
          />
          {userServices.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              style={{
                color: '#ffffff',
                width: '20px',
                textTransform: 'none',
              }}
            >
              Done
            </Button>
          )}
        </Grid>
        {servicesDetailstoDisplay.length === 0 && !addMore && (
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{ height: '200px' }}
          >
            <Typography
              align="center"
              style={{ fontSize: 20, fontWeight: 500, color: '#8692a6' }}
            >
              No serives found.
              <br />
              Please add services to see results!
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
