/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Button,
  Box,
  MenuItem,
  Input,
  Select,
  IconButton,
  Typography,
  colors,
  Paper,
  useTheme,
  Dialog,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Ip } from '../../../data/ip';
import CircularProgressIndicator from '../../../components/CircularProgressIndicator';
import DeleteDialog from '../../../components/DeleteDialog';

export default function ServicesTable(props) {
  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      '& .MuiDataGrid-root': {
        border: 'solid 2px #cfd9e2',
        borderRadius: '0px',
        '& .Mui-selected': {
          backgroundColor: '#DFEEFB',
          '&:hover': {
            backgroundColor: '#DFEEFB',
          },
        },
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-viewport': {
        '& .MuiIconButton-label': {
          color: '#728192',
        },
      },

      '& .MuiDataGrid-row': {
        borderBottom: 'solid 2px #cfd9e2',
        '&:hover': {
          backgroundColor: '#DFEEFB',
        },
      },

      '& .MuiDataGrid-columnsContainer': {
        borderBottom: 'solid 2px #cfd9e2',
      },
      '& .MuiDataGrid-colCellWrapper': {
        color: '#728192',
        backgroundColor: '#f0f0f0',
        '& .MuiIconButton-label': {
          color: '#728192',
        },
      },
    },
  });
  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [load, setLoad] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteContent, setDeleteContent] = useState('');
  // function CustomToolbar() {
  //   return (
  //     <Grid
  //       container
  //       justify="flex-end"
  //       alignContent="center"
  //       style={{
  //         height: '49px',
  //         borderBottom: 'solid 2px #cfd9e2',
  //       }}
  //     >
  //       <Button style={{ textTransform: 'none' }}>
  //         <Typography>Chat with Provider</Typography>
  //       </Button>
  //     </Grid>
  //   );
  // }

  const columns = [
    {
      field: 'serviceoffer',
      headerName: 'Service Offer',
      width: 208,
      sortable: false,
    },
    {
      field: 'experiencelevel',
      headerName: 'Experience Level',
      width: 216,
      sortable: false,
    },
    {
      field: 'yearsofexperience',
      headerName: 'Experience',
      width: 216,
      sortable: false,
    },
    {
      field: 'hourlyrate',
      headerName: 'Hourly Rate',
      width: 150,
      sortable: false,
    },
    {
      field: 'delete',
      headerName: ' ',
      width: 65,
      sortable: false,
      align: 'left',
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            console.log(params);
            setDeleteContent(params.row);
            setShowDeleteDialog(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    props.content &&
      props.content.map((con) => {
        setRows((prevRows, i) => [
          ...prevRows,
          {
            id: con.serviceid,
            serviceoffer: con.servicename,
            experiencelevel: con.experiencelevel,
            hourlyrate: `$ ${con.hourlyrate}`,
            yearsofexperience: con.yearofexperience,
          },
        ]);
      });
  }, []);

  return (
    <Grid
      container
      style={{ height: props.content.length * 49 + 80, width: '99.92%' }}
      className={classes.root}
    >
      <CircularProgressIndicator open={load} />

      <DataGrid
        autoHeight
        // components={{
        //   header: CustomToolbar,
        // }}
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        hideFooter
        // checkboxSelection
        // showToolbar
      />
      {showDeleteDialog && (
        <DeleteDialog
          open={showDeleteDialog}
          close={() => setShowDeleteDialog(false)}
          title="Do you want to delete this service"
          messageTitle1="Service Name"
          messageContent1={deleteContent.serviceoffer}
          messageTitle2="Experience Level"
          messageContent2={deleteContent.experiencelevel}
          messageTitle3="Experience"
          messageContent3={deleteContent.yearsofexperience}
          messageTitle4="Hourly Rate"
          messageContent4={deleteContent.hourlyrate}
          delete={() => {
            setLoad(true);
            axios
              .delete(`${Ip}/user/service?serviceids=${deleteContent.id}`, {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${
                    localStorage.getItem('ResetToken') || ''
                  }`,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  props.update();
                  setLoad(false);
                }
              })
              .catch((err) => {
                alert(`ERR: ${err}`);
                console.log(err);
              });
          }}
        />
      )}
    </Grid>
  );
}
