import React, { Component } from 'react';
import {
  Typography,
  Button,
  Box,
  TextField,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import authImage from '../../../assets/Images/authentication_img.svg';
import OtpInput from 'react-otp-input';
import Info from '../Info';
import { Ip } from '../../../data/ip';
import * as QueryString from 'query-string';
import { Alert, AlertTitle } from '@material-ui/lab';

class EnterOTP extends Component {
  constructor(props) {
    super(props);
    const queries = QueryString.parse(this.props.location.search);
    this.state = {
      otp: '',
      mobileNumber: queries.mobile,
      inValidOTP: false,
    };
  }

  handleChange = (otp) => this.setState({ otp });

  ValidateOTP = () => {
    fetch(`${Ip}/otp?otp=${this.state.otp}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: null,
    })
      .then((Response) => {
        if (Response.status === 200) {
          window.location.href = '/verificationPage';
        } else {
          this.setState({ inValidOTP: true });
        }
      })
      .catch((err) => {});
  };

  render() {
    return (
      <Paper>
        <Info />
        <Grid
          container
          direction="column"
          style={{
            position: 'absolute',
            height: '100%',
            width: '70%',
            transform: 'translateX(+42%)',
          }}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
            }}
          >
            <img src={authImage} />
            <br />
            <Typography
              align="center"
              style={{
                fontSize: '24px',
                fontWeight: 500,
                paddingBottom: '11px',
              }}
            >
              Enter Verfication Code
            </Typography>
            <Typography
              align="center"
              style={{
                fontSize: '14px',
                wordBreak: 'inherit',
                width: '40%',
                paddingBottom: '20px',
                color: '#8692a6',
              }}
            >
              Please enter the OTP sent to your mobile{' '}
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                {this.state.mobileNumber}
              </span>{' '}
            </Typography>
            <OtpInput
              value={this.state.otp}
              onChange={this.handleChange}
              numInputs={6}
              separator={<span>&nbsp;&nbsp;</span>}
              isInputNum={true}
              shouldAutoFocus={true}
              containerStyle={{
                paddingBottom: '20px',
              }}
              inputStyle={{
                width: '55px',
                height: '42px',
                borderRadius: '4px',
                border: 'solid 1px rgba(134, 146, 166, 0.5)',
                backgroundColor: '#ffffff',
              }}
            />
            {this.state.inValidOTP && (
              <Typography
                variant="caption"
                style={{ marginBottom: '10px', color: 'red' }}
              >
                Enter valid OTP
              </Typography>
            )}

            <Button
              variant="contained"
              size="large"
              style={{
                textTransform: 'none',
                position: 'relative',
                backgroundColor: '#f5802c',
                color: '#FFFFFF',
                width: '40%',
              }}
              onClick={this.ValidateOTP}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default EnterOTP;
