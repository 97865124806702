import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Tooltip,
} from '@material-ui/core';
import { Ip } from '../../data/ip';
import AvailableServices from '../../data/AvailableServices';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';

export default function CreateServiceRequest(props) {
  let history = useHistory();
  const [title, setTitle] = useState(props.title || '');
  const [description, setDescription] = useState(props.description || '');
  const [dueDate, setdueDate] = useState(
    props.serviceabletime ? 'Pick a date' : 'ASAP'
  );
  const [selectedDate, setSelectedDate] = useState(props.serviceabletime || '');
  const [attachments, setAttachments] = useState(props.attachments || []);
  const [selectServiceId, setSelectServiceId] = useState(props.serviceid || '');
  const [deliveryMode, setDeliveryMode] = useState(props.deliverymode || '');

  async function handleUpload(event) {
    const targetFiles = event.target.files;
    for (let f of targetFiles) {
      var stop = false;
      var str = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);
        reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
        reader.onerror = (error) => reject(error);
      });
      attachments.map((element) =>
        element.content === str ? (stop = true) : null
      );
      if (stop) continue;
      console.log(f);
      {
        setAttachments((prevAttachments) => [
          ...prevAttachments,
          {
            content: str,
            name: f.name,
            type: f.type,
          },
        ]);
      }
    }
  }
  const handleChange = (event) => {
    setSelectedDate();
    setdueDate(event.target.value);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log(date);
  };

  function createRequest(event) {
    const body = {
      servicerequestid: props.update ? props.serviceRequestId : null,
      title: title,
      description: description,
      service: {
        serviceid: selectServiceId,
      },

      serviceabletime: selectedDate || null,
      attachments: attachments,
      receivingmethod: deliveryMode,
      providerid: props.providerid ? props.providerid : null,
    };
    console.log(`payload: ${JSON.stringify(body, null, 2)}`);
    fetch(`${Ip}/servicerequest`, {
      method: props.update ? 'put' : 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          history.push('/myservicerequests');
        }
      })
      .catch((err) => {
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ marginTop: 20 }}
    >
      <Typography
        align="center"
        style={{ color: '#8692a6', fontSize: 30, fontWeight: 600 }}
      >
        {props.update ? 'Update Service Request' : 'Create Service Request'}
      </Typography>
      <Box p={2} mb={1} style={{ width: '500px' }}>
        <Grid container justify="space-between" alignItems="center">
          <Typography>Title of the request</Typography>
          <TextField
            required
            name="Title"
            id="Title"
            type="text"
            variant="outlined"
            margin="dense"
            label={!props.title ? 'Title' : null}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            style={{ width: '229.6px' }}
          />
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography>Description</Typography>
          <TextField
            multiline={true}
            style={{ width: '229.6px' }}
            inputProps={{
              style: {
                height: '60px',
              },
            }}
            required
            name="Description"
            id="Description"
            type="text"
            variant="outlined"
            margin="dense"
            label={!props.title ? 'Description' : null}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Box mt={1}>
          <Grid container justify="space-between" alignItems="center">
            <Tooltip title="Please select a due date for the completion of service">
              <Typography>Due date</Typography>
            </Tooltip>
            <FormControl component="fieldset" style={{ width: '229.6px' }}>
              <RadioGroup value={dueDate} onChange={handleChange}>
                <Tooltip title="Please select a due date for the completion of service">
                  <FormControlLabel
                    value="ASAP"
                    control={<Radio />}
                    label="ASAP"
                  />
                </Tooltip>
                <Tooltip title="Please select a due date for the completion of service">
                  <FormControlLabel
                    value="Pick a date"
                    control={<Radio />}
                    label="Pick a date"
                  />
                </Tooltip>

                {dueDate === 'Pick a date' && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Select Date"
                      format="dd/MM/yyyy"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <KeyboardTimePicker
                      margin="normal"
                      id="time-picker"
                      label="Select Time"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      keyboardIcon={<AccessTimeIcon />}
                    />
                  </MuiPickersUtilsProvider>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Box>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography>Attachments</Typography>
          <Box style={{ width: '229.6px' }}>
            <Grid container direction="column">
              <Button
                variant="outlined"
                component="label"
                style={{ textTransform: 'none' }}
              >
                <Typography>Upload files</Typography>
                <input
                  id="file"
                  type="file"
                  onChange={handleUpload}
                  multiple
                  hidden
                />
              </Button>
              <Typography variant="caption">
                only .JPEG, .PNG, .PDF, .Text*
              </Typography>
            </Grid>
            {attachments ? (
              <Box mb={1} style={{ width: '229.6px' }}>
                {attachments.map((ats, i) => (
                  <Grid
                    key={i}
                    container
                    justify="space-between"
                    alignItems="center"
                    direction="row"
                  >
                    <Typography style={{ wordBreak: 'break-all' }}>
                      {ats.name}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        const remove = [...attachments];
                        remove.splice(i, 1);
                        setAttachments([...remove]);
                      }}
                    >
                      <CloseIcon style={{ width: '15px', height: '15px' }} />
                    </IconButton>
                  </Grid>
                ))}
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography>Service</Typography>
          <TextField
            required
            name="Service"
            id="Service"
            type="text"
            margin="dense"
            variant="outlined"
            label="Select service"
            value={selectServiceId}
            disabled={props.serviceid}
            onChange={(event) => setSelectServiceId(event.target.value)}
            select
            style={{ width: '229.6px' }}
          >
            {AvailableServices().map((services, i) => (
              <MenuItem value={String(services.serviceid)} key={i}>
                {services.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container justify="space-between" alignItems="center">
          <Typography>Delivery mode</Typography>
          <TextField
            required
            name="DeliveryMode"
            id="deliverymode"
            type="text"
            margin="dense"
            variant="outlined"
            label="Delivery Mode"
            value={deliveryMode}
            onChange={(event) => setDeliveryMode(event.target.value)}
            select
            style={{ width: '229.6px' }}
          >
            <MenuItem value="In-Person">In-Person Appearance</MenuItem>
            <MenuItem value="Virtual">Virtual</MenuItem>
          </TextField>
        </Grid>
      </Box>
      <Button
        style={{ textTransform: 'none', color: '#ffffff', width: 150 }}
        variant="contained"
        color="primary"
        size="large"
        onClick={() => {
          (dueDate === 'Pick a date' && selectedDate) || dueDate === 'ASAP'
            ? createRequest()
            : alert('Please pick date and time');
          localStorage.setItem('createServiceRequestBySearchingProviders', '');
        }}
      >
        {props.update ? 'Update' : 'Create'}
      </Button>
    </Grid>
  );
}
