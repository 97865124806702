import Home from '../pages/Home';
import Contact from '../pages/Contact';
import About from '../pages/About';
import Login from '../pages/Login';
import SearchResults from '../pages/SearchResults';
import Register1 from '../pages/Registration/Register1/Register1';
import EnterOTP from '../pages/Registration/Register1/EnterOTP';
import Register2 from '../pages/Registration/Register2/Register2';
import Register3 from '../pages/Registration/Register3/Register3';
import RegistrationCompleted from '../pages/Registration/RegistrationCompleted';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import CreateServiceRequest from '../pages/ServiceRequests/CreateServiceRequest';
import MyServiceRequests from '../pages/ServiceRequests/MyServiceRequests/MyServiceRequests';
import UpdateServiceRequest from '../pages/ServiceRequests/UpdateServiceRequest';
import WorkOrderQueue from '../pages/ServiceRequests/WorkOrderQueue/WorkOrderQueue';
import ShowAttachments from '../pages/ServiceRequests/ShowAttachments';
import OfferedBids from '../pages/Bids/OfferedBids/OfferedBids';
import MyBids from '../pages/Bids/MyBids/MyBids';
import DirectRequests from '../pages/ServiceRequests/DirectRequests/DirectRequests';
import Profile from '../pages/Profile/Profile';
import RequestProvider from '../pages/ServiceRequests/RequestProvider';
import ChatRoom from '../pages/ChatRoom/ChatRoom';
import Contracts from '../pages/Contracts/Contracts';
import CreateMilestones from '../pages/Contracts/Milestones/CreateMilestones';
import OpenContract from '../pages/Contracts/OpenContract';
import Subscription from '../pages/Subscription/Subscription';
import Payment from '../pages/Subscription/Payment';
import PaymentSuccess from '../pages/Subscription/PaymentSuccess';
import PaymentCancelled from '../pages/Subscription/PaymentCancelled';
import RegisterSubscription from '../pages/Subscription/RegisterSubscription';
import verificationPage from '../pages/Registration/Register1/verificationPage';

export default {
  home: {
    id: 'home',
    path: '/',
    exact: true,
    component: Home,
    name: 'Home',
  },
  contact: {
    id: 'contact',
    path: '/contact',
    exact: true,
    component: Contact,
    name: 'Contact Us',
  },
  about: {
    id: 'about',
    path: '/about',
    exact: true,
    component: About,
    name: 'Contact Us',
  },
  login: {
    id: 'login',
    path: '/login',
    exact: true,
    component: Login,
    name: 'Login',
  },
  searchresults: {
    id: 'searchresults',
    path: '/searchresults/:search',
    component: SearchResults,
    name: 'SearchResults',
  },
  Register1: {
    id: 'Register1',
    path: '/register1',
    exact: true,
    component: Register1,
    name: 'Register1',
  },
  EnterOTP: {
    id: 'EnterOTP',
    path: '/enterOTP',
    exact: true,
    component: EnterOTP,
    name: 'EnterOTP',
  },
  Register2: {
    id: 'Register2',
    path: '/register2',
    exact: true,
    component: Register2,
    name: 'Register2',
  },
  Register3: {
    id: 'Register3',
    path: '/register3',
    exact: true,
    component: Register3,
    name: 'Register3',
  },
  RegistrationCompleted: {
    id: 'RegistrationCompleted',
    path: '/registrationcompleted',
    exact: true,
    component: RegistrationCompleted,
    name: 'RegistrationCompleted',
  },
  ForgotPassword: {
    id: 'ForgotPassword',
    path: '/forgotpassword',
    exact: true,
    component: ForgotPassword,
    name: 'ForgotPassword',
  },
  ResetPassword: {
    id: 'ResetPassword',
    path: '/resetpassword',
    exact: true,
    component: ResetPassword,
    name: 'ResetPassword',
  },
  CreateServiceRequest: {
    id: 'CreateServiceRequest',
    path: '/createservicerequest',
    exact: true,
    component: CreateServiceRequest,
    name: 'CreateServiceRequest',
  },
  MyServiceRequests: {
    id: 'MyServiceRequests',
    path: '/myservicerequests',
    exact: true,
    component: MyServiceRequests,
    name: 'MyServiceRequests',
  },
  UpdateServiceRequest: {
    id: 'UpdateServiceRequest',
    path: '/updateservicerequest/:request',
    exact: true,
    component: UpdateServiceRequest,
    name: 'UpdateServiceRequest',
  },
  WorkOrderQueue: {
    id: 'WorkOrderQueue',
    path: '/workorderqueue',
    exact: true,
    component: WorkOrderQueue,
    name: 'WorkOrderQueue',
  },
  ShowAttachments: {
    id: 'ShowAttachments',
    path: '/showattachments/:attachment',
    exact: true,
    component: ShowAttachments,
    name: 'SshowAttachments',
  },
  OfferedBids: {
    id: 'OfferedBids',
    path: '/offeredbids/:filterid',
    exact: true,
    component: OfferedBids,
    name: 'OfferedBids',
  },
  MyBids: {
    id: 'MyBids',
    path: '/mybids',
    exact: true,
    component: MyBids,
    name: 'MyBids',
  },
  DirectRequests: {
    id: 'DirectRequests',
    path: '/directrequests',
    exact: true,
    component: DirectRequests,
    name: 'DirectRequests',
  },
  Profile: {
    id: 'Profile',
    path: '/profile',
    exact: true,
    component: Profile,
    name: 'Profile',
  },
  verificationPage: {
    id: 'verificationPage',
    path: '/verificationpage',
    exact: true,
    component: verificationPage,
    name: 'verificationPage',
  },
  RequestProvider: {
    id: 'RequestProvider',
    path: '/requestprovider/:ids',
    exact: true,
    component: RequestProvider,
    name: 'RequestProvider',
  },
  ChatRoom: {
    id: 'ChatRoom',
    path: '/chatroom/:id',
    exact: true,
    component: ChatRoom,
    name: 'ChatRoom',
  },
  Contracts: {
    id: 'Contracts',
    path: '/contracts/:filter',
    exact: true,
    component: Contracts,
    name: 'Contracts',
  },
  CreateMilestones: {
    id: 'CreateMilestones',
    path: '/createmilestones/:contractid',
    exact: true,
    component: CreateMilestones,
    name: 'CreateMilestones',
  },

  OpenContract: {
    id: 'OpenContract',
    path: '/opencontract/:contractid',
    exact: true,
    component: OpenContract,
    name: 'OpenContract',
  },

  Subscription: {
    id: 'Subscription',
    path: '/subscription',
    exact: true,
    component: Subscription,
    name: 'Subscription',
  },

  Payment: {
    id: 'Payment',
    path: '/payment',
    exact: false,
    component: Payment,
    name: 'Payment',
  },

  PaymentCancelled: {
    id: 'PaymentCancelled',
    path: '/paymentcancelled',
    exact: true,
    component: PaymentCancelled,
    name: 'PaymentCancelled',
  },

  PaymentSuccess: {
    id: 'PaymentSuccess',
    path: '/paymentsuccess/:contractid',
    exact: true,
    component: PaymentSuccess,
    name: 'PaymentSuccess',
  },

  RegisterSubscription: {
    id: 'RegisterSubscription',
    path: '/registersubscription',
    exact: true,
    component: RegisterSubscription,
    name: 'RegisterSubscription',
  },
};
