import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import tick from '../assets/Images/checkIcon.svg';

export default function GoHome(props) {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ width: '100%', height: '100vh' }}
    >
      <Typography align="center" style={{ fontSize: 30, fontWeight: 600 }}>
        {props.message}
        <img src={tick} style={{ width: 40, height: 40, marginLeft: 10 }} />
      </Typography>
      <Button
        variant="contained"
        style={{
          width: '200px',
          textTransform: 'none',
          position: 'relative',
          top: 20,
          backgroundColor: '#f5802c',
          color: '#FFFFFF',
          padding: '12px',
        }}
        href="/"
      >
        Goto Home
      </Button>
    </Grid>
  );
}
