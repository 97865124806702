import React, { useEffect, useState } from 'react';
import { Typography, Button, Menu, IconButton } from '@material-ui/core';
import profileIcon2 from '../../assets/Images/profile-icon-2.svg';

export default function ProfileButton(props) {
  let profilePic = localStorage.getItem('ProfilePic');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {localStorage.getItem('ResetToken') && (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="inherit"
          onClick={handleClick}
          style={{ padding: 0 }}
        >
          <img
            color="inherit"
            src={profilePic ? `data:;base64,${profilePic}` : profileIcon2}
            style={{
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              objectFit: 'cover',
              cursor: 'pointer',
            }}
          />
        </IconButton>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Button
          style={{ textTransform: 'none', width: '120px', height: '40px' }}
          href="/profile"
        >
          <Typography>Profile</Typography>
        </Button>
        <br />
        <Button
          fullWidth
          href="/login"
          style={{ textTransform: 'none', width: '120px', height: '40px' }}
          onClick={() => {
            localStorage.clear();
          }}
        >
          <Typography>Logout</Typography>
        </Button>
      </Menu>
      {/* <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus
      >
        <Button
          style={{ textTransform: 'none', width: '120px', height: '40px' }}
          href="/profile"
        >
          <Typography>Profile</Typography>
        </Button>
        <br />
        <Button
          fullWidth
          href="/login"
          style={{ textTransform: 'none', width: '120px', height: '40px' }}
          onClick={() => {
            localStorage.clear();
          }}
        >
          <Typography>Logout</Typography>
        </Button>
      </Popover> */}
    </div>
  );
}
