import React from 'react';
import {
  Typography,
  Grid,
  Button,
  Dialog,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function DeleteDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.close()}
      PaperProps={{
        style: { borderRadius: 0 },
      }}
      maxWidth={false}
      style={{ backgroundColor: 'rgba(119, 129, 146, 0.3)' }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{
          height: '100%',
          width: '500px',
        }}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{
            hieght: '50px',
            padding: '16px 12px 10px 20px',
            backgroundColor: '#f9fafb',
          }}
        >
          <Typography style={{ fontWeight: '600' }}>{props.title} ?</Typography>
          <IconButton size="small" onClick={() => props.close()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          direction="column"
          style={{ padding: '30px 12px 30px 20px' }}
        >
          <Grid container direction="row">
            <Typography style={{ width: '30%' }}>
              <b>{props.messageTitle1}</b>
            </Typography>
            <Typography style={{ width: '5%' }}>: </Typography>
            <Typography style={{ width: '65%' }}>
              {props.messageContent1}
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Typography style={{ width: '30%' }}>
              <b>{props.messageTitle2}</b>
            </Typography>
            <Typography style={{ width: '5%' }}>: </Typography>
            <Typography style={{ width: '65%' }}>
              {props.messageContent2}
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Typography style={{ width: '30%' }}>
              <b>{props.messageTitle3}</b>
            </Typography>
            <Typography style={{ width: '5%' }}>: </Typography>
            <Typography style={{ width: '65%' }}>
              {props.messageContent3}
            </Typography>
          </Grid>
          <Grid container direction="row">
            <Typography style={{ width: '30%' }}>
              <b>{props.messageTitle4}</b>
            </Typography>
            <Typography style={{ width: '5%' }}>: </Typography>
            <Typography style={{ width: '65%' }}>
              {props.messageContent4}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          style={{
            height: 60,
            position: 'relative',
            backgroundColor: '#f9fafb',
          }}
          justify="flex-end"
          alignItems="center"
        >
          <Button
            variant="contained"
            style={{
              textTransform: 'none',
              position: 'relative',
              backgroundColor: '#ffffff',
              color: '#f5802c',
              width: '100px',
              height: '36px',
            }}
            onClick={() => props.close()}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              textTransform: 'none',
              position: 'relative',
              backgroundColor: '#f5802c',
              color: '#FFFFFF',
              width: '100px',
              height: '36px',
              margin: '0px 32px 0px 20px',
            }}
            onClick={() => props.delete()}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
