import React, { useState, useEffect, useRef } from 'react';
import { Ip } from '../../data/ip';
import {
  Typography,
  Grid,
  Paper,
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import axios from 'axios';
import DisplayMessages from './DisplayMessages';
import TypeMessageField from './TypeMessageField';
import { CloseIcon } from '@material-ui/data-grid';
import MinimizeIcon from '@material-ui/icons/Minimize';
var stompClient = null;

export default function ChatRoom(props) {
  const [chatWith, setChatWith] = useState(localStorage.getItem('chatWith'));
  const chatWithName = localStorage.getItem('chatWithName');
  const userId = localStorage.getItem('userId');
  const userName = JSON.parse(localStorage.getItem('userName'));
  const [messages, setMessages] = useState([]);
  const [height, setHeight] = useState(
    localStorage.getItem('chatBoxHeight') || '450px'
  );
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  useEffect(() => {
    chatWith && scrollToBottom();
  }, [messages, height]);

  useEffect(() => {
    chatWith &&
      axios
        .get(`${Ip}/messages/${userId}/${chatWith}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setMessages(response.data);
          }
          console.log(response.data);
        })
        .catch((err) => {
          alert(`ERR: ${err}`);
          console.log(err);
        });

    chatWith && connect();
  }, []);

  const connect = () => {
    const Stomp = require('stompjs');
    var SockJS = require('sockjs-client');
    SockJS = new SockJS(`${Ip}/ws`);
    stompClient = Stomp.over(SockJS);
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    console.log('connected');

    stompClient.subscribe(
      '/user/' + userId + '/queue/messages',
      onMessageReceived,
      onError
    );
  };

  const onError = (err) => {
    console.log(err);
  };

  const onMessageReceived = (msg) => {
    const reply = JSON.parse(msg.body);
    console.log('msg is', typeof reply.senderId, typeof chatWith);
    if (reply.senderId == chatWith) setMessages((prev) => [...prev, reply]);
  };

  const sendMessage = (msg) => {
    if (msg.trim() !== '') {
      const message = {
        senderId: `${userId}`,
        recipientId: `${chatWith}`,
        senderName: userName.fname,
        content: msg,
        timestamp: new Date(),
      };
      stompClient.send('/app/chat', {}, JSON.stringify(message));
      setMessages([...messages, message]);
    }
  };

  return chatWith ? (
    <Paper
      style={{
        position: 'fixed',
        bottom: 0,
        right: '2%',
        height: height,
        width: '310px',
        bordertopLeftRadius: '7px',
        borderTopRightRadius: '7px',
        overflow: 'hidden',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        alignContent="center"
      >
        <Paper
          elevation="1"
          style={{ borderRadius: 0, position: 'sticky', width: '100%' }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ paddingLeft: 10, height: 40 }}
          >
            <Typography
              style={{ fontSize: 13, fontWeight: 'bold', width: '75%' }}
            >
              {chatWithName}
            </Typography>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ width: '25%' }}
            >
              <Typography
                style={{
                  fontSize: 30,
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  color: '#6c757d',
                }}
                onClick={() => {
                  setHeight(height === '450px' ? '40px' : '450px');
                  console.log(height);
                  localStorage.setItem(
                    'chatBoxHeight',
                    `${height === '450px' ? '40px' : '450px'}`
                  );
                }}
              >
                -
              </Typography>
              <IconButton
                onClick={() => {
                  stompClient.disconnect();
                  localStorage.removeItem('chatWith');
                  localStorage.removeItem('chatWithName');
                  localStorage.removeItem('chatBoxHeight');
                  setChatWith('');
                  props.onClose != null && props.onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
        {height === '450px' && (
          <DisplayMessages
            messages={messages}
            userId={userId}
            messagesEndRef={messagesEndRef}
          />
        )}
        {height === '450px' && (
          <TypeMessageField sendMessage={(event) => sendMessage(event)} />
        )}
      </Grid>
    </Paper>
  ) : (
    <Grid></Grid>
  );
}
