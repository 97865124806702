import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

export default function DisplayHeading(props) {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{
        height: 50,
        margin: '15px 0 30px 0',
        padding: '0px 25px 0 19px',
        borderLeft: '6px solid #cfd9e2',
      }}
    >
      <Grid
        container
        direction="column"
        justify="space-between"
        style={{ width: '50%', height: 50 }}
      >
        <Typography style={{ fontSize: 18, fontWeight: 500 }}>
          {props.DisplayHeadingName}
        </Typography>
        <Typography style={{ color: '#8692a6', fontSize: 14, fontWeight: 500 }}>
          {props.caption}
        </Typography>
      </Grid>
      {props.button && (
        <Button
          variant="contained"
          style={{
            textTransform: 'none',
            height: 36,
            backgroundColor: '#f5802c',
            color: '#ffffff',
            fontSize: 14,
          }}
          onClick={props.clicked}
        >
          {props.button}
        </Button>
      )}
    </Grid>
  );
}
