import React, { useState, useEffect } from 'react';
import { Ip } from './ip';
import axios from 'axios';

export default function AvailableServices(props) {
  const [result, setResult] = useState(
    JSON.parse(localStorage.getItem('AvailableServices')) || []
  );
  const [indexes, setIndexes] = useState([]);
  const [len, setLen] = useState();
  const [availableServices, setAvailableServices] = useState(
    JSON.parse(localStorage.getItem('AvailableServices')) || []
  );

  useEffect(() => {
    result.length === 0
      ? axios
          .get(`${Ip}/service`, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${
                localStorage.getItem('ResetToken') || ''
              }`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setResult(response.data);
              parentServicesIndex(response.data);
            } else alert('Something went wrong');
          })
      : servicesOffer();
  }, [len]);

  function parentServicesIndex(res) {
    if (res.length > 0) {
      for (var i = 0; i < res.length; i++) {
        for (var j = 0; j < res.length; j++) {
          if (res[i].serviceid === res[j].parentid) {
            setIndexes((prevIndexes) => [...prevIndexes, i]);
          }
        }
      }
      setIndexes((prevIndexes) => [...removeDuplicates(prevIndexes)]);
      function removeDuplicates(data) {
        return data.filter((value, index) => data.indexOf(value) === index);
      }
    }
    setLen(res.length);
  }

  function servicesOffer() {
    if (len === result.length) {
      let x = [
        ...result.filter(function (value, index) {
          return indexes.indexOf(index) == -1;
        }),
      ];
      setAvailableServices(x);
      localStorage.setItem('AvailableServices', JSON.stringify(x));
    }
  }
  return availableServices;
}
