import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Ip } from '../../data/ip';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function RegisterSubscription() {
  useEffect(() => {
    const SubscriptionDetails = localStorage.getItem('SubscriptionDetails')
      ? JSON.parse(localStorage.getItem('SubscriptionDetails'))
      : '';
    var moment = require('moment');
    var date = moment();
    var endDate = SubscriptionDetails
      ? moment().add(
          1,
          [
            'price_1Ix6m3GXGp4IxVFOTCkG6xJl',
            'price_1Ix8zvGXGp4IxVFOEoDHO6Nm',
            'price_1Ix916GXGp4IxVFOkoKQTfH7',
          ].includes(SubscriptionDetails.subscriptionplanid)
            ? 'M'
            : 'Y'
        )
      : null;
    const body = {
      subscriptionplanid: SubscriptionDetails.subscriptionplanid,
      status: SubscriptionDetails.status,
      startdate: date,
      enddate: endDate,
    };
    console.log(`payload: ${JSON.stringify(body, null, 2)}`);
    SubscriptionDetails &&
      fetch(`${Ip}/subscription`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            localStorage.setItem('SubscriptionDetails', '');
            window.location.href = '/registersubscription';
          }
        })
        .catch((err) => {
          alert(`ERR: ${err}`);
          console.log(err);
        });
  });
  return (
    <Grid container direction="column" justify="center" alignContent="center">
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
