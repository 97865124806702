import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CssBaseline, ThemeProvider, Grid } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import routes from '../data/routes';
import theme from '../data/theme';
import Navbar from './Navbar/Navbar';
import CircularProgressIndicator from './CircularProgressIndicator';
import ChatRoom from '../pages/ChatRoom/ChatRoom';

var hours = 8; // Reset when storage is more than 24hours
var now = new Date().getTime();
var loginTime = localStorage.getItem('loginTime');
loginTime && now - loginTime > hours * 60 * 60 * 1000
  ? (localStorage.clear(),
    (window.location.href = '/login'),
    alert('Your session has been timed out please login again!'))
  : null;

export default function App() {
  const history = createBrowserHistory();
  const currentPage = [
    '/login',
    '/register1',
    '/register2',
    '/register3',
    '/resetpassword',
    '/enterOTP',
    '/registrationcompleted',
    '/verificationPage',
  ].includes(history.location.pathname);

  const mobile = '/mobile' === history.location.pathname;

  return mobile ? (
    <CircularProgressIndicator open={true} />
  ) : (
    <CssBaseline>
      <ThemeProvider theme={theme}>
        {currentPage ? null : <Navbar />}
        <Grid
          container
          direction="row"
          style={{
            width: '100%',
            padding: currentPage ? 0 : '0 5.9% 0 5.9%',
            backgroundColor: '#f8f9fb',
          }}
        >
          <BrowserRouter>
            <Switch>
              {Object.keys(routes).map((id) => (
                <Route
                  key={routes[id].id}
                  path={routes[id].path}
                  exact={routes[id].exact}
                  component={routes[id].component}
                />
              ))}
            </Switch>
          </BrowserRouter>
          <ChatRoom />
        </Grid>
      </ThemeProvider>
    </CssBaseline>
  );
}
