import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Box,
  Card,
  Button,
  Select,
  Input,
  MenuItem,
  Dialog,
} from '@material-ui/core';
import { Ip } from '../../data/ip';
import axios from 'axios';
import CircularProgressIndicator from '../../components/CircularProgressIndicator';
import ViewMilestones from './Milestones/ViewMilestones';
import { useParams } from 'react-router';
import ChatRoom from '../ChatRoom/ChatRoom';

export default function OpenContract() {
  let { contractid } = useParams();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [chatWith, setChatWith] = useState('');
  const [openChatWindow, setOpenChatWindow] = useState(false);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setData([]);

    axios
      .get(`${Ip}/contract?contractid=${contractid}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLoad(false);
          console.log(response.data);
          response.data.contract.length === 0
            ? setError(true)
            : setData(response.data.contract);
        }
      })
      .catch((err) => {
        setLoad(false);
        alert(`ERR: ${err}`);
        console.log(err);
      });
  }, []);

  const createCheckoutSession = async () => {
    setLoad(true);
    let r;
    let body = {
      paymentType: 'contract',
      contractId: contractid,
      successUrl: `${window.location.origin}/paymentsuccess/${contractid}`,
      cancelUrl: `${window.location.origin}/paymentcancelled`,
    };
    console.log(JSON.stringify(body, null, 2));
    try {
      r = await axios.post(`${Ip}/payments/checkoutsession`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
      });
    } catch (e) {
      setLoad(false);
      console.log({ error: e });
    }
    setLoad(false);
    console.log({ sessionCreation: r.data });
    await stripe.redirectToCheckout({
      sessionId: r.data,
    });
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="row"
      key={data.length}
      style={{
        width: '100%',
        padding: '20px',
        marginTop: 20,
        backgroundColor: '#ffffff',
      }}
    >
      {data.length > 0 ? (
        <Typography align="center" style={{ fontSize: 40 }}>
          Contract details
        </Typography>
      ) : (
        error && (
          <Typography variant="h3">
            {`You are not allowed to view this contract`}
          </Typography>
        )
      )}
      <CircularProgressIndicator open={load} />
      {data.length > 0 && (
        <Grid
          container
          direction="column"
          key={data.length}
          style={{
            padding: '15px 0px 17px 0px',
          }}
        >
          {data.map((contract) => (
            <Grid
              key={contract.contractid}
              style={{ width: '100%', paddingBottom: 20 }}
            >
              <Typography
                style={{
                  color: '#8692a6',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                Title : {contract.title}
              </Typography>

              <Typography
                style={{
                  color: '#8692a6',
                  fontWeight: 500,
                  fontSize: '14px',
                  marginTop: '10px',
                }}
              >
                Description : {contract.description}
              </Typography>

              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{ marginTop: '10px' }}
              >
                <Typography
                  style={{ fontSize: '14px', fontWeight: 500, width: '25%' }}
                >
                  Contract Id :{' '}
                  <span style={{ color: '#f5802c', fontSize: '17px' }}>
                    {contract.contractid}
                  </span>
                </Typography>
                <Typography
                  style={{ fontSize: '14px', fontWeight: 500, width: '25%' }}
                >
                  Contract Type :{' '}
                  <span style={{ color: '#f5802c', fontSize: '17px' }}>
                    {' '}
                    {contract.contractype}
                  </span>
                </Typography>
                <Typography
                  style={{ fontSize: '14px', fontWeight: 500, width: '25%' }}
                >
                  Amount :{' '}
                  <span
                    style={{ color: '#f5802c', fontSize: '17px' }}
                  >{`$ ${contract.amount}`}</span>
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography
                  style={{ fontSize: '14px', fontWeight: 500, width: '25%' }}
                >
                  Due Date :{' '}
                  <span style={{ color: '#f5802c', fontSize: '17px' }}>
                    {contract.serviceabletime
                      ? new Date(contract.serviceabletime).toLocaleDateString(
                          'en-US'
                        )
                      : 'ASAP'}
                  </span>
                </Typography>
                <Typography
                  style={{ fontSize: '14px', fontWeight: 500, width: '25%' }}
                >
                  Status :{' '}
                  <span style={{ color: '#f5802c', fontSize: '17px' }}>
                    {contract.status}
                  </span>
                </Typography>
                <Typography
                  style={{ fontSize: '14px', fontWeight: 500, width: '25%' }}
                >
                  Funds :
                  <span style={{ color: '#f5802c', fontSize: '17px' }}>
                    {contract.funded === 'true'
                      ? ' Fully funded'
                      : ' Not funded'}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          ))}
          <ViewMilestones
            funded={data[0].funded}
            contractStatus={data[0].status}
            contractid={data[0].contractid}
            amount={data[0].amount}
            providerid={data[0].providerid}
          />
          <Grid container justify="center" alignItems="center">
            {String(data[0].providerid) === localStorage.getItem('userId') ? (
              <Button
                style={{
                  textTransform: 'none',
                  color: '#ffffff',
                }}
                variant="contained"
                color="primary"
                onClick={() => {
                  setChatWith(data[0].consumerid);
                  localStorage.setItem('chatWith', data[0].consumerid + '');
                  localStorage.setItem(
                    'chatWithName',
                    `${data[0].consumerfname} ${
                      data[0].consumermname != ''
                        ? data[0].consumermname + ' '
                        : ''
                    }${data[0].consumerlname}`
                  );
                }}
              >
                Chat with Consumer
              </Button>
            ) : (
              <Grid container direction="row" justify="space-around">
                <Button
                  style={{
                    textTransform: 'none',
                    color: '#ffffff',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setChatWith(data[0].providerid);
                    localStorage.setItem('chatWith', data[0].providerid + '');
                    localStorage.setItem(
                      'chatWithName',
                      `${data[0].providerfname} ${
                        data[0].providermname != ''
                          ? data[0].providermname + ' '
                          : ''
                      }${data[0].providerlname}`
                    );
                  }}
                >
                  Chat with service provider
                </Button>
                {data[0].status === 'Agreed' && data[0].funded == 'false' && (
                  <Button
                    style={{
                      textTransform: 'none',
                      color: '#ffffff',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={createCheckoutSession}
                  >
                    Fund Contract
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
          {chatWith && <ChatRoom onClose={() => setChatWith('')} />}
        </Grid>
      )}
    </Grid>
  );
}
