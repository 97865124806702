import React, { useEffect } from 'react';
import tixkcircle from '../../assets/Images/tixk-circle.svg';
import { useLocation } from 'react-router-dom';
import { Typography, Button, Grid } from '@material-ui/core';
import { useParams } from 'react-router';
import { Ip } from '../../data/ip';

const PaymentSuccess = () => {
  let { contractid } = useParams();
  const query = new URLSearchParams(useLocation().search);
  console.log({ query });
  const sess = query.get('session_id');
  console.log({ contractid });

  useEffect(() => {
    if (contractid != 'null') {
      const body = { contractid: contractid, funded: 'true' };
      console.log(JSON.stringify(body, null, 2));
      fetch(`${Ip}/contract/fund`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('ResetToken') || ''}`,
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => {
          alert(`ERR: ${err}`);
          console.log(err);
        });
    }
  }, []);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ width: '100%', height: '100%' }}
    >
      <Grid
        container
        direction="row"
        justify="center"
        style={{ marginTop: '5%' }}
      >
        <img src={tixkcircle} style={{ width: '200px' }} />
      </Grid>
      <Grid>
        <Typography align="center" variant="h3" style={{ marginTop: '20px' }}>
          Your Payment was Successful
        </Typography>
        <Typography
          align="center"
          variant="subtitle2"
          style={{ marginTop: '20px' }}
        >
          Caption Text goes this way...
        </Typography>
      </Grid>
      <Grid>
        <Button
          variant="contained"
          href="/"
          style={{
            marginTop: '20px',
            backgroundColor: '#f5802c',
            color: '#FFFFFF',
            paddingLeft: '50px',
            paddingRight: '50px',
          }}
        >
          <b>Done</b>
        </Button>
      </Grid>
    </Grid>
  );
};

export default PaymentSuccess;
